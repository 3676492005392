// * Import required libraies
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { v4 } from "uuid";
import moment from "moment";

// * Import 3rd party lib comp

// * Import Custom Component
import LayoutTopSideBottom from "../../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import Loader from "../../../components/Loader/Loader";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
import PivotCreate from "./PivotCreate";
// * Import actions

// * Import data/utils
import SIGVIEW_CONTANTS from "../../../constants/sigviewConstants";
import { config } from "../../../config/config";

// * Import contexts

// * Import API functions

// * Define required variables

// *  Import action creators
import {
  updateGlobalFiltersDimensionFilters,
  updateGlobalFiltersRollingDateType,
  updateGlobalFiltersTimeFilters,
  updateUserScreen,
} from "../../../redux/actions";
import {
  getBookmarkDetails,
  makeAttributeMissingErrorMessage,
} from "../../../utils/analyzeUtils";
import axios from "axios";
import { unwrapperChartObject } from "../../../utils/chartObjectUtils";
import { getBookmark } from "../../../services/api";
import { isSharePivotValidBasedOnUserAccess } from "../../../utils/pivotUtils";

function PivotCreateContainer(props = {}) {
  // * Destructure props
  const {
    user = {},
    allData,
    dispatch: ReduxDispatcher,
    globalFilters = {},
  } = props;
  // * Define hooks
  const history = useHistory();
  const [ws, setWs] = useState(SIGVIEW_CONTANTS.initialState);

  // * Define required states
  const [pageStatus, setPageStatus] = useState(
    SIGVIEW_CONTANTS.initialPageStatus
  );

  useEffect(() => {
    return () => {
      let id = v4();
      const newTitle = `Untitled Pivot - ${moment(Date.now()).format(
        config.hardCoded.defaultStandaloneChartTimestampFormat
      )}`;
      const defaultPivotSelections = {
        id: id,
        title: newTitle,
        dimensionsList: [],
        metricsList: [],
        advanceSort: [],
        timeFilters: globalFilters.timeFilters,
        elementType: "create",
        renderFlag: false,
        transpose: false,
        dimensionFilters: [],
        metricFilters: [],
        dataLimit: 10,
        progressiveDateFlag: false,
        feature: "pivotx",
        reloadRenderEpoch: Date.now(),
      };

      // Update activeChart in redux and screen so that user can be redirected to create pivot screen
      const value = {
        activeTab: "createPivot",
        activePivot: defaultPivotSelections,
      };
      var action = updateUserScreen(null, value);
      ReduxDispatcher(action);
      ReduxDispatcher(updateUserScreen("isPivotShared", false));
    };
  }, [user.reqMetadata.view]);

  useEffect(() => {
    // ! QUICK FIX
    const runFlag = user.screen.activePivot.feature !== undefined;
    const { isSharedUrlFlag, bookmarkId } = getBookmarkDetails(location);

    // * CORRECT BUT LENGHTY FIX
    // const runFlag = isActiveDatastoryValid(user.screen.activeDatastory)

    if (runFlag) {
      setPageStatus({
        status: "success",
        message: "",
      });
    } else {
      // history.push("/pivotx");
      if (!isSharedUrlFlag) {
        history.push("/pivotx");
      }
      // setPageStatus({
      //   status: "loading",
      //   message: "",
      // });
    }
  }, [user.screen.activePivot]);

  const location = useLocation();

  useEffect(() => {
    const { isSharedUrlFlag, bookmarkId } = getBookmarkDetails(location);
    const source = axios.CancelToken.source();
    const isPivotShared = isSharedUrlFlag;
    const flag =
      allData.dateRange.data &&
      allData.dateRange.data.startDate &&
      allData.dateRange.data.endDate;
    if (isPivotShared && flag) {
      const payload = {
        bookmarkId: bookmarkId,
        isShare: "true",
      };
      const fetchProps = {
        payload: payload,
        cancelToken: source.token,
      };

      const getBookmarkPromise = getBookmark(fetchProps);
      getBookmarkPromise
        .then((responseData) => {
          // * Validating workspace for missing attributes (access change)
          const isSharePivotValidBasedOnUserAccessProps = {
            backendWs: responseData.result.data.profile.pivot,
            user,
            allData,
          };
          const isSharePivotValidBasedOnUserAccessResponse =
            isSharePivotValidBasedOnUserAccess(
              isSharePivotValidBasedOnUserAccessProps
            );
          if (isSharePivotValidBasedOnUserAccessResponse.status !== "valid") {
            let message = makeAttributeMissingErrorMessage(
              isSharePivotValidBasedOnUserAccessResponse,
              "Pivot"
            );
            let newData = {
              status: "accessError",
              message,
              result: {},
            };
            setWs(newData);
          } else {
            let result = responseData.result.data.profile;
            let newData = { status: "success", message: "", result };
            setWs(newData);
            let unwrappedChartObj = unwrapperChartObject({
              variant: "share",
              allData,
              user,
              payload: {
                chartObject: { ...responseData.result.data.profile.pivot },
              },
            });
            let chartObjectUI = unwrappedChartObj;
            // Update activeChart in redux and screen so that user can be redirected to chart
            const userScreenValue = {
              activeTab: "createPivot",
              activePivot: {
                ...chartObjectUI,
                title: "Untitled Pivot",
                id: v4(),
                elementType: "create",
                renderFlag: true,
                transpose: false,
                dataLimit: 10,
                feature: "pivotx",
                reloadRenderEpoch: Date.now(),
              },
            };
            var action = updateUserScreen(null, userScreenValue);
            ReduxDispatcher(action);
            ReduxDispatcher(updateUserScreen("isPivotShared", true));
            var payloadDimFilter = {
              value: userScreenValue.activePivot.dimensionFilters,
            };
            var action_globalDimFilters =
              updateGlobalFiltersDimensionFilters(payloadDimFilter);
            ReduxDispatcher(action_globalDimFilters);
            var payloadTimeFilter = {
              value: userScreenValue.activePivot.timeFilters,
            };
            var action_globalTimeFilters =
              updateGlobalFiltersTimeFilters(payloadTimeFilter);
            ReduxDispatcher(action_globalTimeFilters);
            var payloadProgressiveDate = {
              value: userScreenValue.activePivot.progressiveDateFlag,
            };
            var action_rollingDateType = updateGlobalFiltersRollingDateType(
              payloadProgressiveDate
            );
            ReduxDispatcher(action_rollingDateType);
          }
        })
        .catch((json) => {
          if (json.error !== config.hardCoded.queryCancelled) {
            const message = json.error || config.messages.uiErrorMessage;
            let newData = {
              status: "error",
              message,
              result: {},
            };
            setWs(newData);

            console.groupCollapsed("UI ERROR");
            console.log("JSON", json);
            console.log("ERROR ->", message);
            console.groupEnd();
          }
        });
    }
  }, [allData.dateRange]);

  const handleAccessCorruptError = () => {
    history.push("/pivotx");
  };

  return (
    <>
      {ws.status === "accessError" && (
        <LayoutTopSideBottom>
          <ErrorHandler
            message={ws.message}
            reloadFlag={true}
            onReload={handleAccessCorruptError}
            reloadButtonProps={{
              flag: true,
              // variant: "contained",
              title: "Load Default Profile",
            }}
          />
        </LayoutTopSideBottom>
      )}
      {pageStatus.status === "loading" && ws.status !== "accessError" && (
        <LayoutTopSideBottom>
          <Loader />
        </LayoutTopSideBottom>
      )}
      {pageStatus.status === "error" && ws.status !== "accessError" && (
        <LayoutTopSideBottom>
          <ErrorHandler
            message={pageStatus.message}
            reloadFlag={true}
            onReload={() => {
              window.reload();
            }}
          />
        </LayoutTopSideBottom>
      )}
      {pageStatus.status === "success" && <PivotCreate />}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  globalFilters: state.globalFilters,
});

export default connect(mapStateToProps)(PivotCreateContainer);
