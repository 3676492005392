// * Import required libraries
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";

// * Import lib components
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

// * Import custom components
import AuthLoader from "../AuthLoader";

// * Import utils
import { config } from "../../config/config";

// * Import required contexts

// * Import hooks

// * Import APIs
import { sigviewSignInSso, ssoConfig } from "../../services/api";
import signInImage from "../../../assets/images/openxLoginFlowImg.svg";
import openxLogo from "../../../assets/images/openxLogo.svg";

// * Import action creators
import { updateAuthLoading, updateChangeFlag } from "../../redux/actions";
import { ThemeContext } from "../../contexts/ThemeContext";
import SigviewTypography from "../../components/Common/SigviewTypography";
import { store } from "../../redux/storeInitializer";
import { getBaseUrlEndpoints, redirectUrl } from "../../utils/utils";
import {
  encryptEmail,
  generateUUID,
  generateAppState,
  generateCodeChallengeS256,
} from "../../utils/authUtils";

// * Define required static variables
const initialIframeDetails = {
  status: "loading",
  result: { iframeUrl: "" },
  message: "",
};
const makeSigviewStyles = (...args) => {
  const [themeColors, customStyle] = args;
  const useStyles = makeStyles({
    root: {
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    leftPanel: {
      width: "50%",
      height: "100%",
      display: "inline-block",
    },
    rightPanel: {
      width: "50%",
      height: "100%",
      display: "inline-block",
    },
    signInSignUpImageContainer: {
      width: "100%",
      height: "calc(100vh - 1px)",
      background: "url(" + signInImage + ")",
      backgroundRepeat: "no-repeat",
    }, 
    mainSignInFormRow: {
      width: "calc(40vw - 50px)",
      height: "65vh",
      marginTop: "100px",
      marginLeft: "5vw",
      backgroundColor: "#f5f5f5",
    },
    mainSignInFormRowLeft: {
      marginLeft: "30px",
      padding: "10px",
      paddingTop: "0px",
    },
    mainSignInFormRowLeftBottom: {
      padding: "10px 10px 15px 10px",
      marginLeft: "20px",
    },
    emailInputStyles: {
      width: "250px",
      height: "25px",
      padding: "8px",
      fontSize: "12px",
      border: "1px solid #ddd",
      marginRight: "20px",
      outline: "none",
      color: "#46596A",
      borderRadius: "4px",
    },
    continueButtonStyles: {
      marginTop: "10px",
      padding: "10px 15px",
      backgroundColor: "#4CAF50",
      color: "white",
      border: "none",
      borderRadius: "4px",
      fontSize: "13px",
      cursor: "pointer",
      transition: "background-color 0.3s ease, box-shadow 0.2s ease",
      "&:hover": {
        backgroundColor: "#45a049",
      },
      "&:active": {
        backgroundColor: "#388e3c",
        backgroundColor: "#388e3c",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        transform: "scale(0.98)",
      },
    },
    emailErrorStyles: {
      color: "red",
      display: "block",
      marginTop: "8px",
      fontSize: "12px",
    },
  });
  return useStyles;
};
function SiginInOpenX(props = {}) {
  const {
    dispatch: ReduxDispatcher = () => {},
    authForm = {},
    user = {},
  } = props;
  const history = useHistory();
  const location = useLocation();
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const useSigviewStyles = makeSigviewStyles(themeColors);
  const classes = useSigviewStyles();
  // * Define required states
  const [iframeDetails, setIframeDetails] = useState(initialIframeDetails);
  const [isoldSSO, setIsOldSSO] = useState(false);
  const [userType, setUserType] = useState("");
  const [isApigeeLogin, setIsApigeeLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  // * Define required side effects
  // Function to set valid iframeUrl based on window.location.pathname
  useEffect(() => {
    // * Make iframeUrl based on realm and instance_uid
    var search = location.search;
    var iframeUrl = "";
    var pathnameId = "";
    const openxIframeBaseUrl = config.hardCoded.openxIframeBaseUrl;
    if (search.indexOf("realm") > -1) {
      pathnameId = search.split("realm=").pop();
      iframeUrl = `${openxIframeBaseUrl}?realm=${pathnameId}`;
    } else if (search.indexOf("instance_uid") > -1) {
      pathnameId = search.split("instance_uid=").pop();
      iframeUrl = `${openxIframeBaseUrl}?instance_uid=${pathnameId}`;
    } else {
      iframeUrl = `${openxIframeBaseUrl}`;
    }
    setIframeDetails({ status: "success", message: "", result: { iframeUrl } });
  }, []);

  // Function to add event listener to
  useEffect(() => {
    function handleSignIn(token) {
      var decodedJwt = jwt_decode(token);
      console.log("decodedJwt", decodedJwt);
      const email = decodedJwt.email.toLowerCase();
      const clientId = decodedJwt.clientId;
      const cryptoKey = decodedJwt.cryptokey;
      const userType = decodedJwt.userType;
      const name = decodedJwt.name;
      const instances = decodedJwt.instances;
      const payload = {
        email,
        clientId,
        cryptoKey,
        userType,
        token: token,
      };
      const headers = {
        Authorization: cryptoKey,
        clientid: decodedJwt.clientId,
        useremail: email,
        userType,
      };
      const fetchProps = {
        payload,
        headers,
      };
      const sigviewSignInSsoPromise = sigviewSignInSso(fetchProps);
      sigviewSignInSsoPromise
        .then((response) => {
          if (response.status === 401) throw new Error();
          return response.json();
        })
        .then((responseData) => {
          console.log("responseData1", responseData);
          if (responseData.statusCode === "200") {
            // ! Currently, embeddedInfo.isEmbeddedUser is true only for non-openx embedded users (like kayzen
            // Update embeddedInfo in redux
            // var data = {
            //   embeddedInfo: {
            //     isEmbeddedUser: true,
            //     email: params.get("email"),
            //     clientId: params.get("clientId"),
            //     cryptoKey: params.get("cryptoKey"),
            //     status: "success",
            //     message: "",
            //   },
            // };
            // handleUpdateUserInfo(data);

            // * For OpenX users, we do:
            // 1. Set values to local storage
            // 2. Re-trigger authForm
            // 3. Redirect to home page

            // 1. Set to values to local storage
            const satellizer_token = responseData.token;
            const openxUserDataInfo = [
              {
                token: decodedJwt.token,
                name: name,
                instances: instances[0],
                userType: userType,
              },
            ];
            localStorage.setItem(
              "openxUserData",
              JSON.stringify(openxUserDataInfo)
            );
            localStorage.setItem("satellizer_token", satellizer_token);
            localStorage.setItem("user_email", email);
            const payload = {
              status: "success",

              xAuthToken: {
                "X-Auth-Token": satellizer_token,
                instances: "",
              },
              isTokenPresent: true,
              isTokenValid: true,
            };
            const action = updateAuthLoading(payload);
            ReduxDispatcher(action);

            // // 2. Re-trigger authForm
            // setTimeout(() => {
            //   ReduxDispatcher(updateChangeFlag("authFormEpoch", Date.now()));
            // }, 0);

            // 3. Redirect to home page
            history.push(config.hardCoded.rootNonSigviewUrlOnLogin);
          } else if (responseData.statusCode === "401") {
            throw new Error();
          }
        })
        .catch((json) => {
          console.error("UI ERROR");
          console.groupCollapsed("DETAIL");
          console.log("Component ---> SignInOpenX.js");
          console.log("Function --> handleSignIn");
          console.log("json", json);
          console.groupEnd();

          // ! Currently, embeddedInfo.isEmbeddedUser is true only for non-openx embedded users (like kayzen)
          // Update embeddedInfo in redux
          //   var data = {
          //     embeddedInfo: {
          //       isEmbeddedUser: true,
          //       status: "error",
          //       message: "",
          //     },
          //   };
          //   handleUpdateUserInfo(data);

          // Redirect to sso error page
          history.push("/openxLoginErrorPage");
        });
    }

    function receiveMessageFromOpenxSSO(event) {
      const runFlag =
        event.data !== "" &&
        event.data !== undefined &&
        event.data.value !== undefined;
      if (runFlag) {
        if (event.data.value !== "Internal Server Error") {
          var token = event.data.value;
          handleSignIn(token);
        } else {
          history.push("/openxLoginErrorPage");
        }
      }
    }

    // Add event listener to capture message from OpenX SSO SignIn
    window.addEventListener("message", receiveMessageFromOpenxSSO, true);

    return () => {
      // Remove event listener on unmount
      window.removeEventListener("message", receiveMessageFromOpenxSSO, true);
    };
  }, []);

  // * Define required static variables
  const openxIframeContainerStyle = { width: "100vw", height: "100vh" };

  // * DEBUGGER
  // console.groupCollapsed("SignInOpenX.js");
  // console.log("iframeDetails", iframeDetails);
  // console.groupEnd();

  useEffect(() => {
    setUserType(localStorage.getItem("userType"));
  }, []);

  const handleApigeeLogin = () => {
    localStorage.setItem("Login Method", "Apigee");
    const apiEndpoints = getBaseUrlEndpoints();
    const encodedEmail = encodeURIComponent(email);
    window.location.href = `${apiEndpoints.baseUrlOld}/api/oauth2?email=${encodedEmail}`;
  };

  const handleMSIntraLogin = () => {
    localStorage.setItem("LoginMethod", "MsEntra");
    const encodedencEmail = encodeURIComponent(email);
    const encryptedMsCode = localStorage.getItem("ms_code");
    const decodedClientId = atob(encryptedMsCode);
    let issuer = localStorage.getItem("issuer");
    // Replace 'v2.0' with 'oauth2/v2.0' in the issuer
    issuer = issuer.replace("/v2.0", "/oauth2/v2.0");
    const codeChallengeS256 = generateCodeChallengeS256();
    const clientRequestId = generateUUID();
    const appState = generateAppState(125);
    const getBaseUrlEndpoint = getBaseUrlEndpoints();
    const url = redirectUrl();
    const urlEncoded = encodeURIComponent(url);
    const baseUrlMapping = [
      "https://auth2.sigmoid.io",
      "https://staging-auth.sigmoid.io",
      "https://devint-auth.sigmoid.io",
    ];

    if (baseUrlMapping.includes(getBaseUrlEndpoint.baseUrlOld)) {
      const MicrosoftUrl = `${issuer}/authorize?client_id=${decodedClientId}&redirect_uri=${urlEncoded}&scope=openid+email&state=${appState}&code_challenge=${codeChallengeS256}&code_challenge_method=S256&response_type=code&response_mode=query&login_hint=${encodedencEmail}&force_login=true`;
      window.location.href = MicrosoftUrl;
    } else {
      console.error("Invalid base URL");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const isapigeeLogin = localStorage.getItem("isapigeeLogin");
      if (isapigeeLogin) {
        setIsApigeeLogin(true);
      }
      setIsLoading(false);
    }, 1000);
  }, []);

  // Handler for email input change
  const handleEmailChange = (event) => {
    setEmail(event.target.value); // Update email value on each keystroke
    if (emailError) {
      setEmailError("");
    }
  };

  // Handler for form submission (Continue button click)
  const handleContinue = () => {
    // Basic email validation regex
    const emailRegex = /^[a-zA-Z0-9._-]+(\+[a-zA-Z0-9._-]+)?@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    // If the email doesn't match the regex, show error
    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError(""); // Clear error if valid
    }

    // If the email is valid, proceed with the API call
    if (emailRegex.test(email)) {
      const { email: encryptedEmail, iv } = encryptEmail(email.toLowerCase()); // Encrypt the email

      const payload = {
        email: encryptedEmail,
        iv: iv,
      };
      const sigviewSignInPromise = ssoConfig({ payload });
      sigviewSignInPromise
        .then((response) => response.json())
        .then((data) => {
          if (data.status.statusCode === "200") {
            if (data.result.data.issuer === "https://api.openx.com") {
              handleApigeeLogin();
            } else if (
              data.result.data.issuer.includes(
                "https://login.microsoftonline.com"
              )
            ) {
              const encryptedClientId = btoa(data.result.data.client_id);
              localStorage.setItem("ms_code", encryptedClientId);
              localStorage.setItem("grant_type", data.result.data.grant_type);
              localStorage.setItem("issuer", data.result.data.issuer);
              localStorage.setItem("MsUserEmail", email);
              handleMSIntraLogin();
            } else {
              setIsOldSSO(true);
            }
          }
        });
    }
  };
  return (
    <>
      {iframeDetails.status === "loading" && <AuthLoader />}
      {iframeDetails.status === "success" && (
        <>
          {isoldSSO && (
            <Box css={openxIframeContainerStyle}>
              <iframe
                id="iFrameLink"
                title="Login Openx"
                src={iframeDetails.result.iframeUrl}
                width="100%"
                height="100%"
              />
            </Box>
          )}
        </>
      )}
      {userType !== "OAA" && !isApigeeLogin && !isLoading && (
        <Box className={classes.root}>
          <Box className={classes.leftPanel}>
            <Box className={classes.mainSignInFormRow}>
              <Box marginLeft={3}>
                <img
                  src={openxLogo}
                  width="20%"
                  height="30%"
                  alt="OpenX Logo"
                />
              </Box>

              <Box className={classes.mainSignInFormRowLeft}>
                <SigviewTypography
                  variant="pLarge"
                  style={{ padding: "0px 0px 40px 0px", lineHeight: "1.5" }}
                >
                  Welcome to OpenX, where you can manage media buys to reach
                  <br />
                  your target audience and maximize your ROI.
                </SigviewTypography>
                <SigviewTypography
                  variant="pLargest"
                  style={{ padding: "80px 0px 0px 0px" }}
                >
                  Log in to your Account
                </SigviewTypography>
              </Box>
              <Box className={classes.mainSignInFormRowLeftBottom}>
                <SigviewTypography
                  variant="pSmallMedium"
                  style={{ padding: "0px 0px 0px 10px" }}
                >
                  Welcome back!
                </SigviewTypography>
              </Box>
              <Box style={{ padding: "5px", marginLeft: "35px" }}>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Enter your email"
                  className={classes.emailInputStyles}
                />
                {emailError && (
                  <small className={classes.emailErrorStyles}>
                    {emailError}
                  </small>
                )}
                <Box>
                  <button
                    onClick={handleContinue}
                    className={classes.continueButtonStyles}
                  >
                    Continue
                  </button>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.rightPanel}>
            <Box className={classes.signInSignUpImageContainer}></Box>
            {/* <img src={signInImage} width="100%" height="100%" alt="Sign In"  /> */}
          </Box>
        </Box>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  changeFlag: state.changeFlag,
  allData: state.data,
  authForm: state.auth,
  signInFormStatus: state.signInFormStatus,
});

export default connect(mapStateToProps)(SiginInOpenX);
