import { v4 } from "uuid";

import { config } from "../config/config";
import themeColors from "../../styles/theme/themeColors";

import { getDefaultWsForm } from "../utils/analyzeUtils";
import SIGVIEW_CONTANTS from "../constants/sigviewConstants";

export const initialTimeFilters = {
  ...SIGVIEW_CONTANTS.sampleValidTimeFilters,
};

export const initialDimensionFilters = [];

export const initialMetricFilters = [];

export const initialRollingDateType = {
  message: "",
  status: "valid",
  stepTag: "details",
  value: "yes",
};
export const initialMetricChartGranularity = {
  message: "",
  status: "valid",
  stepTag: "details",
  value: "day",
};

export const initialActiveWorkspace = {
  wsCategory: "initial",
  crudType: "update",
  payload: {},
};

export const initialFeatureAccessList = {
  status: "loading",
  message: "",
  data: {
    accessList: [],
    limitsList: [],
    paramsList: [],
  },
};

export const initialUiFeatureList = {
  datastory: true,
  pivot: true,
  plot: true,
  globalFiltersFileUpload: true,
  globalFiltersStringMatch: true,
  globalFiltersTimestamp: true,
  download: true,
  fullReport: true,
  metricFilters: true,
  reportManager: true,
  alertManager: true,
  multiCompareCalendar: true,
  hourGranularity: true,
  dayGranularity: true,
  monthGranularity: true,
  reportManagerAsTab: false,
  reportManagerAsSidenav: true,
  alertManagerAsTab: false,
  alertManagerAsSidenav: true,
  analyze: true,
  overlay: true,
  chartType: true,
  workspaceShare: true,
  chartType: true,
  overlayUpdates: true,
  forcedRefreshToast: true,
  customMetricInAdmin: true,
  i18n: true,
  bannerNotification: true,
  bannerLink: true,
};

export const initialUiLimitsList = {
  daysLimitCalendarDashboard:
    config.hardCoded.defaultLimitList.daysLimitCalendarDashboard,
  daysLimitCalendarReports:
    config.hardCoded.defaultLimitList.daysLimitCalendarReports,
  maxSavedReports: config.hardCoded.defaultLimitList.maxSavedReports,
  maxScheduledReports: config.hardCoded.defaultLimitList.maxScheduledReports,
  maxFileUploadRowCount:
    config.hardCoded.defaultLimitList.maxFileUploadRowCount,
  maxFileUploadSizeInMB:
    config.hardCoded.defaultLimitList.maxFileUploadSizeInMB,
  maxCompareCalendarInDashboard:
    config.hardCoded.defaultLimitList.maxCompareCalendarInDashboard,
  maxCompareCalendarInReports:
    config.hardCoded.defaultLimitList.maxCompareCalendarInReports,
  minHourGranDurationInDays:
    config.hardCoded.defaultLimitList.minHourGranDurationInDays,
  maxHourGranDurationInDays:
    config.hardCoded.defaultLimitList.maxHourGranDurationInDays,
  minDayGranDurationInDays:
    config.hardCoded.defaultLimitList.minDayGranDurationInDays,
  maxDayGranDurationInDays:
    config.hardCoded.defaultLimitList.maxDayGranDurationInDays,
  minMonthGranDurationInDays:
    config.hardCoded.defaultLimitList.minMonthGranDurationInDays,
  maxMonthGranDurationInDays:
    config.hardCoded.defaultLimitList.maxMonthGranDurationInDays,
  maxSavedAlerts: config.hardCoded.defaultLimitList.maxSavedAlerts,
};

export const initialGlobalFilters = {
  isOpen: false, //global filters dialog open close
  showTimeFilters: false,
  showMetricFilters: true,
  activeFilterType: "dimensions",
  isAdFiltersOpen: false, //advanced filters dialog open close
  activeDimensionFilter: {},
  timeFilters: {}, //for initializing filters
  dimensionFilters: {}, //for initializing filters
  metricFilters: {}, //for initializing filters
  handleApplyFilters: () => {},
  settings: {
    isAdvancedFiltersVisible: true,
    isExcludeAllowed: true,
    disabledItemsList: [], // ["D044"] --> This needs to be a array of ids
  },
};

export const initialUserReportManager = {
  isOpen: false,
  metadata: { variant: "saved", selections: {} },
};

export const initialUserScreen = {
  bucketScreen: { type: "", payload: {} },
  timeMenuBar: true,
  filtersMenuBar: true,
  activeNav: "analyze",
  activeTab: "home", //home or dashboard or createChart
  activeDsCategory: "sample", //home or dashboard or createChart
  activeDatastory: {},
  activeChart: {},
  activePivot: {}, // for createPivot and editPivot
  isPivotShared: false,
  newActiveAttribute: {
    payload: {},
    metadata: {},
  },
  activeAdminGroup: {}, // row full state
  activeAdminUser: {},
  activeWorkspace: initialActiveWorkspace,
  cachedWorkspace: initialActiveWorkspace, // This is used to cached the activeworkspace to cater when the AnalyzeDashboardContainer unmounts it set the activeworkspae to initial
  isDashboardLoading: true,
  reportManager: initialUserReportManager, //report manager dialog open close
  adminReport: false, //admin report manager dialog open close
  adminReportHardReload: false, //admin report hard reload from adminReportContainer used in specific conditions
  adminReportData: {}, //admin report manager dialog open close
  filtersMenuRowCount: "single",
  //Properies for global filters so that on initialization of GF, it takes it from here
  globalFilters: initialGlobalFilters,
  snackbar: {
    open: false,
    message: "",
    severity: "success",
    iconFlag: false,
    onClickText: "",
    onClick: () => {},
  },
  sigviewDialog: {
    open: false,
    children: null,
  },
  sigviewBigDialog: {
    open: false,
    props: { children: null, title: "", onClose: () => {} },
  },
  overlay: {
    isOpen: false,
    children: null,
  },
  workspaceAutoSaveStatus: config.hardCoded.defaultWorkspaceAutoSaveStatus,
};

export const initialSignInSelections = {
  email: {
    message: "",
    value: "",
    status: "",
  },

  password: {
    message: "",
    value: "",
    status: "",
  },

  tab: {
    message: "",
    value: "signIn",
    status: "",
  },

  signInStatus: {
    message: "",
    value: "initial", // loading/error/success/initial
    status: "",
  },

  resetStatus: {
    message: "",
    value: "initial", // loading/error/success/initial
    status: "",
  },

  postResetStatus: {
    message: "",
    value: "initial", // loading/error/success/initial
    status: "",
  },

  showSignInUiError: {
    message: "",
    value: false,
    status: "",
  },
};

export const initialUserData = {
  isAuth: true,
  auth: { status: "loading", message: "", data: {} }, // { "openx-token": "", "X-Auth-Token": "", "openx-userType": "" }
  type: "sigview", //sigview or nonSigview,
  embeddedInfo: {
    status: "initial",
    isEmbeddedUser: false,
    clientId: "",
    email: "",
    cryptoKey: "",
    message: "",
  },
  apiEndpoints: {
    baseUrl: "",
    baseUrlOld: "",
  },
  theme: "light",
  themeColors,
  screen: { ...initialUserScreen },
  dialogInfo: {
    message: { title: "", subtitle: "" },
    open: false,
    handleYes: () => {},
    handleNo: () => {},
  }, //Should be inside screen
  reqMetadata: null,
  uiFeatureList: initialUiFeatureList,
  uiLimitsList: initialUiLimitsList,
  timeFilters: initialTimeFilters,
  dimensionFilters: initialDimensionFilters,
  metricFilters: initialMetricFilters,
  metadata: { status: "loading", message: "", data: {} },
  version: {
    status: "loading",
    message: "",
    data: {},
  },
  bannerStatus: {
    status: true,
    originalMessage: "",
  },
};

export const initialAuthData = {
  status: "initial",
  message: "",
  isTokenPresent: false,
  isTokenValid: false,
  authData: {},
  sigviewUserType: "sigview",
  apiEndpoints: {},
  ssoType: "",
};

export const initialAllData = {
  dateRange: { status: "loading", message: "", data: {} },
  allDateRange: { status: "loading", message: "", data: {} },
  dateRangeLatest: { status: "loading", message: "", data: {} },
  metricList: { status: "loading", message: "", data: {} },
  dimensionList: { status: "loading", message: "", data: {} },
  customMetricList: { status: "loading", message: "", data: {} },
  featureAccessList: initialFeatureAccessList,
  datastoryShared: { status: "loading", message: "", data: [] },
  datastorySaved: { status: "loading", message: "", data: [] },
  datastorySample: { status: "loading", message: "", data: [] },
  views: { status: "loading", message: "", result: [] },
  viewGroupList: { status: "loading", message: "", result: [] },
  adminUser: { status: "loading", message: "", result: [] },
  plotlyMetrics: null,
  plotlyDimensions: null,
  banner: {
    status: false,
    message: "",
  },
};

export const initialChangeFlag = { view: true };

const defaultSteps = [
  {
    id: "details",
    name: "Details",
    visited: { flag: true, message: "" },
    valid: { flag: true, message: "" },
  },
  {
    id: "charts",
    name: "Charts",
    visited: { flag: false, message: "" },
    valid: { flag: true, message: "" },
  },
  {
    id: "kpis",
    name: "KPIs",
    visited: { flag: false, message: "" },
    valid: { flag: true, message: "" },
  },
];

export const initialStepperState = {
  steps: defaultSteps,
  showError: false,
  activeStep: "details",
};

export const initialStandaloneWs = getDefaultWsForm({
  initialUiFeatureList,
  initialUiLimitsList,
});

export const stateData = {
  user: initialUserData,
  data: initialAllData,
  changeFlag: initialChangeFlag,
  auth: initialAuthData,
  standaloneWs: initialStandaloneWs,
  signInFormStatus: initialSignInSelections,
  standaloneMsv: initialStandaloneWs, // Can work for now as MSV is not a direct route but a sub-feature inside analyze; To be changed into a VALID msvForm if MSV becomes a new route
  globalFilters: {
    dimensionFilters: initialDimensionFilters,
    timeFilters: initialTimeFilters,
    metricFilters: initialMetricFilters,
    rollingDateType: initialRollingDateType,
    metricChartGranularity: initialMetricChartGranularity,
    analyzeFiltersReadOnly: {
      dimensionFilters: initialDimensionFilters,
      timeFilters: initialTimeFilters,
      metricFilters: initialMetricFilters,
      rollingDateType: initialRollingDateType,
      metricChartGranularity: initialMetricChartGranularity,
    },
  },
};
