// * Import lib
import moment from "moment";

// * Import utils, config & static data
import { wrapperOrgViewReq } from "./chartObjectUtils";
import { config } from "../config/config";
import {
  getDefaultWorksheetUi,
  isWorkspaceCorrupt,
  isWorkspaceValidBasedOnUserAccess,
  makeAttributeMissingErrorMessage,
} from "./analyzeUtils";

// * Import redux utilities
// Action creators
import { updateUserScreen } from "../redux/actions";

// * Import API functions
import { getGroupDefaultWorksheet, saveWs } from "../services/api";

// * Define required API functions
const makeDefaultWorksheetFromUi = async (props = {}) => {
  const {
    user,
    allData,
    ReduxDispatcher,
    activeWorkbook,
    setIsAnyworkbookInRedux,
    setPageStatus,
    setReloadWorksheet,
    setReloadWorkbooks,
  } = props;
  const getDefaultWorksheetUiProps = {
    user,
    allData,
  };
  const defaultWorksheetUi = getDefaultWorksheetUi(getDefaultWorksheetUiProps);

  var fetchProps = {
    payload: {
      clickedsaveAs: false,
      orgViewReq: wrapperOrgViewReq(user),
      email: user?.reqMetadata?.email,
      workbookId: activeWorkbook._id,
      worksheet: defaultWorksheetUi,
    },
  };
  try {
    const saveWsResponse = await saveWs(fetchProps);
    if (saveWsResponse.status?.statusCode === "200") {
      // 1. Revert setIsAnyworkbookInRedux to unmount AnalyzeDashboard

      setIsAnyworkbookInRedux(false);

      // 2. Set new active workspace in redux

      // Set new active workspace in redux
      const activeWorkspacePayload = {
        workbook: activeWorkbook,
        worksheet: defaultWorksheetUi,
      };
      const actionValue = {
        activeWorkspace: {
          wsCategory: "saved",
          crudType: "update",
          payload: activeWorkspacePayload,
        },
      };
      var action = updateUserScreen(null, actionValue);
      ReduxDispatcher(action);

      // 3. Reload Worksheet to trigger useEffect

      setReloadWorksheet(Date.now());

      // 5. Reload Workbooks API to get the latest list

      setReloadWorkbooks(Date.now());
    } else {
      console.error("UI ERROR");
      console.groupCollapsed("DETAILS");
      console.log("Failed in makeNewDefaultWorksheet function");
      console.log("saveWsResponse", saveWsResponse);
      console.groupEnd();
      setPageStatus({
        status: "error",
        message: "Creating new workspace API failed",
      });
    }
  } catch (error) {
    console.error("UI ERROR");
    console.groupCollapsed("DETAILS");
    console.log("Failed in makeNewDefaultWorksheet function");
    console.log("Error", error);
    console.groupEnd();
    setPageStatus({
      status: "error",
      message: "Creating new workspace API failed",
    });
  }
};

//  Making default worksheet from UI when there is create case
const makeDefaultCreateWorksheetFromUi = async (props = {}) => {
  const {
    user,
    allData,
    ReduxDispatcher,
    activeWorkbook,
    setIsAnyworkbookInRedux,
    setPageStatus,
    setReloadWorksheet,
    setReloadWorkbooks,
    setCheckFlag, // ! have to seprate for create and saved
  } = props;
  const getDefaultWorksheetUiProps = {
    user,
    allData,
  };
  const defaultWorksheetUi = getDefaultWorksheetUi(getDefaultWorksheetUiProps);

  var fetchProps = {
    payload: {
      clickedsaveAs: false,
      orgViewReq: wrapperOrgViewReq(user),
      email: user?.reqMetadata?.email,
      workbookId: activeWorkbook._id,
      worksheet: defaultWorksheetUi,
    },
  };

  try {
    // ! we are commenting this addWorksheet api because this is creating the 2 worksheet of the same name
    // const saveWsResponse = await saveWs(fetchProps);
    // if (saveWsResponse.status?.statusCode === "200") {
    // 1. Revert setIsAnyworkbookInRedux to unmount AnalyzeDashboard

    setIsAnyworkbookInRedux(false);

    // 2. Set new active workspace in redux
    // Set new active workspace in redux
    const activeWorkspacePayload = {
      workbook: activeWorkbook,
      worksheet: defaultWorksheetUi,
    };
    const actionValue = {
      activeWorkspace: {
        wsCategory: "saved",
        crudType: "create",
        payload: activeWorkspacePayload,
      },
    };
    var action = updateUserScreen(null, actionValue);
    ReduxDispatcher(action);

    // 3. Reload Worksheet to trigger useEffect
    setReloadWorksheet(Date.now());

    // 5. Reload Workbooks API to get the latest list
    setReloadWorkbooks(Date.now());
    setCheckFlag(true);
    // } else {
    //   console.error("UI ERROR");
    //   console.groupCollapsed("DETAILS");
    //   console.log("Failed in makeNewDefaultWorksheet function");
    //   console.log("saveWsResponse", saveWsResponse);
    //   console.groupEnd();
    //   setPageStatus({
    //     status: "error",
    //     message: "Creating new workspace API failed",
    //   });
    // }
  } catch (error) {
    console.error("UI ERROR");
    console.groupCollapsed("DETAILS");
    console.log("Failed in makeNewDefaultWorksheet function");
    console.log("Error", error);
    console.groupEnd();
    setPageStatus({
      status: "error",
      message: "Creating new workspace API failed",
    });
  }
};

const makeNewDefaultWorksheet = async (props = {}) => {
  const {
    user,
    allData,
    activeWorkbook,
    setIsAnyworkbookInRedux,
    ReduxDispatcher,
    setPageStatus,
    setReloadWorksheet,
    setReloadWorkbooks,
  } = props;
  let payload = {
    orgViewReq: wrapperOrgViewReq(user),
    email: user?.reqMetadata?.email,
    workbookId: activeWorkbook._id,
    // worksheetName: `Untitled Workspace ${
    //   activeWorkbook.worksheetList.length + 1
    // }`,
    worksheetName: `Default Profile - ${moment(Date.now()).format(
      config.hardCoded.defaultProfileTimestampFormat
    )}`,
  };
  let fetchProps = {
    payload: payload,
  };

  // * HIERARCHY
  // 1. Make getGroupDefaultWorksheet
  //    PASS ->
  //    FAIL ->

  try {
    let loadFromUiFlag = false;
    let loadFromUiMessage = "";
    const defaultGroupTemplateResponse = await getGroupDefaultWorksheet(
      fetchProps
    );
    let newDefaultWorksheet = defaultGroupTemplateResponse?.result?.data[0];

    // * CHECKS
    // 1. Is it a corrupt object?
    // 2. Are all the metrics and dimensions in accessible list?

    // * Validating workspace for corrupt structure
    const isWorkspaceCorruptProps = { backendWs: newDefaultWorksheet };
    const isWorkspaceCorruptResponse = isWorkspaceCorrupt(
      isWorkspaceCorruptProps
    );

    if (isWorkspaceCorruptResponse.flag) {
      loadFromUiFlag = true;
      loadFromUiMessage = "Corrupt default template";
    } else {
      // * Validating workspace for missing attributes (access change)
      const isWorkspaceValidBasedOnUserAccessProps = {
        backendWs: newDefaultWorksheet,
        user,
        allData,
      };
      const isWorkspaceValidBasedOnUserAccessResponse =
        isWorkspaceValidBasedOnUserAccess(
          isWorkspaceValidBasedOnUserAccessProps
        );

      if (isWorkspaceValidBasedOnUserAccessResponse.status !== "valid") {
        let message = makeAttributeMissingErrorMessage(
          isWorkspaceValidBasedOnUserAccessResponse,
          "Workspace"
        );
        loadFromUiFlag = true;
        loadFromUiMessage = "Access Mismatch";
      } else {
        let fetchProps = {
          payload: {
            clickedsaveAs: false,
            orgViewReq: wrapperOrgViewReq(user),
            email: user?.reqMetadata?.email,
            workbookId: activeWorkbook._id,
            worksheet: newDefaultWorksheet,
          },
        };
        try {
          const saveWsResponse = await saveWs(fetchProps);
          if (saveWsResponse.status?.statusCode === "200") {
            // 1. Revert setIsAnyworkbookInRedux to unmount AnalyzeDashboard

            setIsAnyworkbookInRedux(false);

            // 2. Set new active workspace in redux

            // Set new active workspace in redux
            const activeWorkspacePayload = {
              workbook: activeWorkbook,
              worksheet: newDefaultWorksheet,
            };
            const actionValue = {
              activeWorkspace: {
                wsCategory: "saved",
                crudType: "update",
                payload: activeWorkspacePayload,
              },
            };
            let action = updateUserScreen(null, actionValue);
            ReduxDispatcher(action);

            // 3. Reload Worksheet to trigger useEffect

            setReloadWorksheet(Date.now());

            // 5. Reload Workbooks API to get the latest list

            setReloadWorkbooks(Date.now());
          } else {
            console.error("UI ERROR");
            console.groupCollapsed("DETAILS");
            console.log("Failed in makeNewDefaultWorksheet function");
            console.log("saveWsResponse", saveWsResponse);
            console.groupEnd();
            setPageStatus({
              status: "error",
              message: "Creating new workspace API failed",
            });
          }
        } catch (error) {
          console.error("UI ERROR");
          console.groupCollapsed("DETAILS");
          console.log("Failed in makeNewDefaultWorksheet function");
          console.log("Error", error);
          console.groupEnd();
          setPageStatus({
            status: "error",
            message: "Creating new workspace API failed",
          });
        }
      }
    }

    if (loadFromUiFlag) {
      const snackbarPayload = {
        ...user.screen.snackbar,
        open: true,
        message: `${loadFromUiMessage}. Loading default profile!`,
      };
      let action = updateUserScreen("snackbar", snackbarPayload);
      ReduxDispatcher(action);

      // ! Make default template from UI
      makeDefaultWorksheetFromUi(props);
    }
  } catch (json) {
    if (json.error !== config.hardCoded.queryCancelled) {
      // ! Make default template from UI
      makeDefaultWorksheetFromUi(props);

      console.error("UI ERROR - API FAILED");
      console.groupCollapsed("DETAILS");
      console.log("JSON", json);
      console.groupEnd();
    }
  }
};
//  Loading default worksheet form the API in create flow
const makeNewDefaultCreateWorksheet = async (props = {}) => {
  const {
    user,
    allData,
    activeWorkbook,
    setIsAnyworkbookInRedux,
    ReduxDispatcher,
    setPageStatus,
    setReloadWorksheet,
    setReloadWorkbooks,
    // ! TODO: Think of to adjust into the previous any state
    setCheckFlag,
  } = props;
  var payload = {
    orgViewReq: wrapperOrgViewReq(user),
    email: user?.reqMetadata?.email,
    workbookId: activeWorkbook._id,
    // worksheetName: `Untitled Workspace ${
    //   activeWorkbook.worksheetList.length + 1
    // }`,
    worksheetName: `Default Profile - ${moment(Date.now()).format(
      config.hardCoded.defaultProfileTimestampFormat
    )}`,
  };
  var fetchProps = {
    payload: payload,
  };

  // * HIERARCHY
  // 1. Make getGroupDefaultWorksheet
  //    PASS ->
  //    FAIL ->

  try {
    var loadFromUiFlag = false;
    var loadFromUiMessage = "";
    const defaultGroupTemplateResponse = await getGroupDefaultWorksheet(
      fetchProps
    );
    let newDefaultWorksheet = defaultGroupTemplateResponse?.result?.data[0];

    // * CHECKS
    // 1. Is it a corrupt object?
    // 2. Are all the metrics and dimensions in accessible list?

    // * Validating workspace for corrupt structure
    const isWorkspaceCorruptProps = { backendWs: newDefaultWorksheet };
    const isWorkspaceCorruptResponse = isWorkspaceCorrupt(
      isWorkspaceCorruptProps
    );

    if (isWorkspaceCorruptResponse.flag) {
      loadFromUiFlag = true;
      loadFromUiMessage = "Corrupt default template";
    } else {
      // * Validating workspace for missing attributes (access change)
      const isWorkspaceValidBasedOnUserAccessProps = {
        backendWs: newDefaultWorksheet,
        user,
        allData,
      };
      const isWorkspaceValidBasedOnUserAccessResponse =
        isWorkspaceValidBasedOnUserAccess(
          isWorkspaceValidBasedOnUserAccessProps
        );

      if (isWorkspaceValidBasedOnUserAccessResponse.status !== "valid") {
        let message = makeAttributeMissingErrorMessage(
          isWorkspaceValidBasedOnUserAccessResponse,
          "Workspace"
        );
        loadFromUiFlag = true;
        loadFromUiMessage = "Access Mismatch";
      } else {
        var fetchProps = {
          payload: {
            clickedsaveAs: false,
            orgViewReq: wrapperOrgViewReq(user),
            email: user?.reqMetadata?.email,
            workbookId: activeWorkbook._id,
            worksheet: newDefaultWorksheet,
          },
        };

        // 1. Revert setIsAnyworkbookInRedux to unmount AnalyzeDashboard
        // ! Have to revert this because
        setIsAnyworkbookInRedux(true);

        // 2. Set new active workspace in redux
        // Set new active workspace in redux
        const activeWorkspacePayload = {
          workbook: activeWorkbook,
          worksheet: newDefaultWorksheet,
        };
        const actionValue = {
          activeWorkspace: {
            wsCategory: "saved",
            crudType: "create",
            payload: activeWorkspacePayload,
          },
        };
        var action = updateUserScreen(null, actionValue);
        ReduxDispatcher(action);

        // 3. Reload Worksheet to trigger useEffect
        setReloadWorksheet(Date.now());

        // 5. Reload Workbooks API to get the latest list
        setReloadWorkbooks(Date.now());
        setCheckFlag(true);
      }
    }

    if (loadFromUiFlag) {
      const snackbarPayload = {
        ...user.screen.snackbar,
        open: true,
        message: `${loadFromUiMessage}. Loading default profile!`,
      };
      var action = updateUserScreen("snackbar", snackbarPayload);
      ReduxDispatcher(action);

      // ! Make default template from UI
      makeDefaultCreateWorksheetFromUi(props);
    }
  } catch (json) {
    if (json.error !== config.hardCoded.queryCancelled) {
      // ! Make default template from UI
      makeDefaultWorksheetFromUi(props);

      console.error("UI ERROR - API FAILED");
      console.groupCollapsed("DETAILS");
      console.log("JSON", json);
      console.groupEnd();
    }
  }
};

export { makeNewDefaultWorksheet, makeNewDefaultCreateWorksheet };
