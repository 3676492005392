// Import required libraies
import { useState, useEffect, useRef, useContext } from "react";
import groupBy from "lodash.groupby";
import { v4 } from "uuid";
import { useTranslation } from "react-i18next";

//import library components
import { makeStyles } from "@material-ui/core";
import { Box } from "@material-ui/core";

// Import custom components
import SigviewCheckboxGroup from "../../../components/Common/SigviewCheckboxGroup";
import SigviewTextFieldAsync from "../../../components/Common/SigviewTextFieldAsync";
import SigviewRadioGroup from "../../../components/Common/SigviewRadioGroup";
import TimeFilters from "../../../components/TimeFilters/TimeFilters";
import AppMenuBar from "../../../components/AppMenuBar";
import AppRow from "../../../components/AppRow";
import Loader from "../../../components/Loader/Loader";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
import SigviewTypography from "../../../components/Common/SigviewTypography";
import SelectedFilters from "../../../components/GlobalFilters/SelectedFilters";
import SigviewTooltip from "../../../components/Common/SigviewTooltip";
import SigviewIcon from "../../../components/Common/SigviewIcon";
import SigviewButton from "../../../components/Common/SigviewButton";
import ChartPopover from "../../../components/ChartPopover/ChartPopover";
import SigviewSearchField from "../../../components/Common/SigviewSearchField";

// Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

// Import utils/data
import {
  unwrapperAppRow,
  convertChartResToDsRes,
  convertToAppCardFriendlyDs,
} from "../../../utils/utils";
import { unwrapperChartObject } from "../../../utils/chartObjectUtils";
import { config } from "../../../config/config";
import staticChartJson from "../../../../assets/data/chartTitle.json";
import { validateChartName } from "../../../utils/chartObjectUtils";
import { getElementContentWidth } from "../../../utils/utils";
import { masterTrackGaEvent } from "../../../services/ga";
import { dsFormData as dataForCheckBox } from "../../../utils/dsUtils";

// Import config

// Import action creators
import {
  updateUserScreen,
  updateStandaloneChartForm,
  updateDialogInfo,
} from "../../../redux/actions";

// Import reducers
import standaloneChartReducer from "../../../redux/reducers/standaloneChart";

// Import API
import { dsNameCheck, saveChart } from "../../../services/api";
import { getMeasureObjByOriginalID } from "../../../utils/plotlyUtils";

// Defining required styling functions
const makeSigviewStyles = (theme) => {
  // Make styles
  const useStyles = makeStyles(() => ({
    formContainer: {
      width: "100%",
      // height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#f4f7ff",
      "& *": {
        boxSizing: "border-box",
      },
    },
    form: {
      width: "900px",
    },
    formRow: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      marginBottom: "30px",
      marginTop: "10px",
    },
    fieldset: {
      border: "none",
      padding: "0",
      margin: "0",
    },
    fieldsetLeft: {
      display: "flex",
      alignItems: "center",
      width: "40%",
      "& label": {
        width: "100%",
        display: "block",
        textAlign: "right",
        color: "rgb(38, 126, 230)",
        fontWeight: "700",
        fontSize: "14px",
      },
    },
    fieldsetRight: {
      width: "55%",
      // maxWidth: "max-content",
      marginLeft: "20px",
      // paddingLeft: "5px",
      color: "rgb(70, 89, 106)",
      overflow: "auto",
      borderRadius: "3px",
      // height: "42px",
      padding: "3px 8px",
      display: "flex",
      alignItems: "center",
      "& label": {
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    fullWidth: { width: "100%" },
    bgColor: {
      backgroundColor: "white",
    },
    zoom: { transform: "scale(1.1)", paddingLeft: "8px" },
  }));
  return useStyles;
};

// Defining supporting components
function FormRow(props) {
  // Destructuring props
  const {
    children,
    label,
    isBgColor,
    classes,
    zoomFlag = false,
    fullWidth = false,
    labelRenderProp = () => <></>,
    inputRenderProp = () => <></>,
  } = props;

  // Defining static variables
  let fieldsetLeftClassName = `${classes.fieldset} ${classes.fieldsetLeft}`;
  let fieldsetRightClassName = `${classes.fieldset} ${classes.fieldsetRight}`;
  let fieldsetRightClassChildName = "";
  if (isBgColor) fieldsetRightClassName += ` ${classes.bgColor}`;
  if (zoomFlag) fieldsetRightClassChildName += ` ${classes.zoom}`; //TODO: It's a hack! Need to change the component
  if (fullWidth) fieldsetRightClassChildName += ` ${classes.fullWidth}`; //TODO: It's a hack! Need to change the component

  return (
    <div className={classes.formRow}>
      <fieldset className={fieldsetLeftClassName}>
        <label htmlFor="">{label}</label>
        {labelRenderProp()}
      </fieldset>
      <fieldset className={fieldsetRightClassName}>
        <article className={fieldsetRightClassChildName}>{children}</article>
        {inputRenderProp()}
      </fieldset>
    </div>
  );
}

function Details(props) {
  const { t } = useTranslation();
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  // Destructing props
  const {
    ReduxDispatcher,
    allData,
    globalFilters,
    dsForm,
    handleDsFormChange,
    user,
    isEdit = false,
  } = props;
  const [dsName, setDsName] = useState({
    status: "success",
    value: dsForm.datastoryName.value,
    originalValue: dsForm.datastoryName.value,
    message: "",
  });

  // Defining event handlers
  const handleAddFilters = () => {
    //Open Global Filters
    const newGlobalFiltersProps = {
      isOpen: true, //global filters dialog open close
      showTimeFilters: false,
      showMetricFilters: false,
      activeFilterType: "dimensions",
      isAdFiltersOpen: false, //advanced filters dialog open close
      activeDimensionFilter: {},
      timeFilters: dsForm.timeFilters.value, //for initializing filters
      dimensionFilters: dsForm.dimensionFilters.value, //for initializing filters
      metricFilters: [], //for initializing filters
      handleApplyFilters: (payload, filterType) => {
        switch (filterType) {
          case "dimensions":
            handleDsFormChange("dimensionFilters", payload.newDimensionFilters);
            break;
        }
      }, //It will take 2 parameters, payload and filterType (dimensions or metric or time); We have 2 separate actions for both
    };
    ReduxDispatcher(updateUserScreen("globalFilters", newGlobalFiltersProps));
  };

  const handleDimFilterClick = (filterRow) => {
    //Open Global Filters
    const newGlobalFiltersProps = {
      isOpen: true, //global filters dialog open close
      showTimeFilters: false,
      showMetricFilters: false,
      activeFilterType: "dimensions",
      isAdFiltersOpen: false, //advanced filters dialog open close
      activeDimensionFilter: filterRow.metadata,
      timeFilters: dsForm.timeFilters.value, //for initializing filters
      dimensionFilters: dsForm.dimensionFilters.value, //for initializing filters
      metricFilters: [], //for initializing filters
      handleApplyFilters: (payload, filterType) => {
        switch (filterType) {
          case "dimensions":
            handleDsFormChange("dimensionFilters", payload.newDimensionFilters);
            break;
        }
      }, //It will take 2 parameters, payload and filterType (dimensions or metric or time); We have 2 separate actions for both
    };
    ReduxDispatcher(updateUserScreen("globalFilters", newGlobalFiltersProps));
  };

  const handleDimFilterDelete = (filterRow) => {
    const newDimFilters = dsForm.dimensionFilters.value.filter(
      (filter) => filter.id !== filterRow.id
    );
    handleDsFormChange("dimensionFilters", newDimFilters);
  };

  const handleClearAllFilters = () => {
    handleDsFormChange("dimensionFilters", []);
  };
  const handleSelectedFiltersClick = (row, type) => {
    switch (type) {
      case "dimension":
        handleDimFilterClick(row);
        break;
    }
  };
  const handleSelectedFiltersDelete = (row, type) => {
    switch (type) {
      case "dimension":
        handleDimFilterDelete(row);
        break;
    }
  };
  const handleDsNameChange = (event, value) =>
    setDsName((prevState) => ({ ...prevState, value: value }));

  const handleSnackbarMessageShow = (message) => {
    let snackbarPayload = {
      ...user.screen.snackbar,
      open: true,
      message: message,
    };
    const action = updateUserScreen("snackbar", snackbarPayload);
    ReduxDispatcher(action);
  };
  const handleClickAway = (event) => {
    // Making required active variables
    //If entered chartName is invalid
    const { status, message } = validateChartName(dsName.value);
    if (status === "invalid") {
      setDsName((prevState) => ({
        ...prevState,
        status: "success",
        value: prevState.originalValue,
      }));
      let snackbarPayload = {
        ...user.screen.snackbar,
        open: true,
        message: message,
      };
      const action = updateUserScreen("snackbar", snackbarPayload);
      ReduxDispatcher(action);
    } else {
      // Do this only when the names are different otherwise
      // it will run for every click on the screen
      if (dsName.originalValue !== dsName.value) {
        // Make an API call to check the name
        setDsName((prevState) => ({ ...prevState, status: "loading" }));
        const fetchPayload = {
          orgViewReq: {
            organization: user?.reqMetadata?.organization,
            view: user?.reqMetadata?.view,
          },
          title: dsName.value,
          emailId: user?.reqMetadata?.email,
        };
        const fetchProps = {
          payload: fetchPayload,
        };
        const dsNameCheckPromise = dsNameCheck(fetchProps);
        dsNameCheckPromise
          .then((responseData) => {
            if (!responseData.result.data.status) {
              setDsName((prevState) => ({
                ...prevState,
                status: "success",
                originalValue: prevState.value,
              }));
              handleDsFormChange("datastoryName", dsName.value);
              handleSnackbarMessageShow("Name updated successfully!");
            } else {
              setDsName((prevState) => ({
                ...prevState,
                status: "success",
                value: prevState.originalValue,
              }));
              handleSnackbarMessageShow("Datastory name already exists!");
            }
          })
          .catch((json) => {
            console.groupCollapsed("API FAILED");
            console.log("Error JSON -> ", json);
            console.groupEnd();
            setDsName((prevState) => ({
              ...prevState,
              status: "success",
              value: prevState.originalValue,
            }));
            let snackbarPayload = {
              ...user.screen.snackbar,
              open: true,
              message: json.error || "Rename Failed",
            };
            const action = updateUserScreen("snackbar", snackbarPayload);
            ReduxDispatcher(action);
          });
      }
    }
  };

  // Defining required static variables
  const timeFiltersSettings = {
    compareCalendarRowLimit: 1,
    isMultiCompareRowAllowed: false,
    isComparisonAvailable: false,
    isChangeTypeDropdownAvailable: false,
    isApplyButtonAvailable: false,
  };
  const {
    datastoryName,
    dimensionFilterType,
    timeFilterType,
    rollingDateType,
    timeFilters,
  } = dsForm;
  const calendarDaysLimits = user.uiLimitsList.daysLimitCalendarDashboard;
  const typoCustomStyle = {
    height: "max-content",
    padding: "0px 0px 0px 5px",
    cursor: "pointer",
    color: themeColors["secondaryColorLight"],
  };
  const isAnyDimFiltersSelected = dsForm.dimensionFilters.value.length > 0;
  const textFieldCustomStyle = {
    wrapperWidth: "100%",
    textFieldFontSize: "14px",
    fontWeightTextField: "400",
  };
  const isTimeFiltersAtWidget = dsForm.timeFilterType.value === "widget";
  const isDimFiltersAtWidget = dsForm.dimensionFilterType.value === "widget";
  const rollingDateslabelRenderProp = () => (
    <SigviewTooltip title={config.messages.rollingDates} placement="bottom">
      <span>
        <SigviewIcon
          className="material-icons"
          iconName="info"
          style={{ padding: "0px 10px" }}
        />
      </span>
    </SigviewTooltip>
  );

  const useSigviewStyles = makeSigviewStyles();
  const classes = useSigviewStyles();

  return (
    <>
      <section className={classes.formContainer}>
        <div className={classes.form} onSubmit={(e) => e.preventDefault()}>
          <FormRow
            label={t("Datastory Name:")}
            isBgColor={true}
            classes={classes}
            fullWidth={true}
          >
            {/* <SigviewTextField
              name="datastoryName"
              value={datastoryName.value}
              border="0"
              width="100%"
              height="100%"
              radius="3px"
              fontSize="17px"
              maxWidth="1000px"
              onChange={(newValue) =>
                handleDsFormChange("datastoryName", newValue)
              }
            /> */}
            <SigviewTextFieldAsync
              value={dsName.value}
              onChange={handleDsNameChange}
              status={dsName.status}
              onClickAway={handleClickAway}
              customStyle={textFieldCustomStyle}
              tooltipTitle="Type and press ENTER to check if name is valid"
            />
          </FormRow>

          <FormRow
            label={t("Apply Dimension Filters On:")}
            isBgColor={false}
            classes={classes}
          >
            <SigviewRadioGroup
              data={dataForCheckBox.dimensionData}
              value={dimensionFilterType.value}
              onChange={(newValue) =>
                handleDsFormChange("dimensionFilterType", newValue)
              }
            />
          </FormRow>

          <FormRow
            label={t("Apply Time Filters On:")}
            isBgColor={false}
            classes={classes}
          >
            <SigviewRadioGroup
              data={dataForCheckBox.dimensionData}
              value={timeFilterType.value}
              onChange={(newValue) =>
                handleDsFormChange("timeFilterType", newValue)
              }
            />
          </FormRow>

          {!isTimeFiltersAtWidget && (
            <FormRow
              label={t("Time Filters:")}
              isBgColor={true}
              classes={classes}
              zoomFlag={true}
            >
              <TimeFilters
                user={user}
                allData={allData}
                initialTimeFilters={timeFilters.value}
                onChange={(newValue) =>
                  handleDsFormChange("timeFilters", newValue)
                }
                calendarDaysLimits={calendarDaysLimits}
                settings={timeFiltersSettings}
              />
            </FormRow>
          )}

          {!isTimeFiltersAtWidget && (
            <FormRow
              label={t("Rolling Dates:")}
              // labelRenderProp={rollingDateslabelRenderProp}
              // inputRenderProp={rollingDateslabelRenderProp}
              isBgColor={false}
              classes={classes}
            >
              <SigviewRadioGroup
                data={dataForCheckBox.rollingData}
                value={rollingDateType.value}
                onChange={(newValue) =>
                  handleDsFormChange("rollingDateType", newValue)
                }
              />
            </FormRow>
          )}

          {!isDimFiltersAtWidget && (
            <FormRow
              label={t("Dimension Filters:")}
              isBgColor={isAnyDimFiltersSelected}
              classes={classes}
            >
              {!isAnyDimFiltersSelected && (
                // <SigviewTypography
                //   variant="pLarge"
                //   onClick={handleAddFilters}
                //   style={typoCustomStyle}
                // >

                // </SigviewTypography>
                <SigviewButton
                  variant="containedLighter"
                  onClick={handleAddFilters}
                  title={"Click to add filters"}
                  iconFlag={false}
                  customClassName={`NewDatastoryAddFilters-GA`}
                />
              )}
              {isAnyDimFiltersSelected && (
                <SelectedFilters
                  onClick={handleSelectedFiltersClick}
                  onDelete={handleSelectedFiltersDelete}
                  showClearAllButton={true}
                  onClearAll={handleClearAllFilters}
                  // rowHeader="Applied Filters:"
                  selectedDimensionFilters={dsForm.dimensionFilters.value}
                  selectedMetricFilters={[]}
                  style={{ maxHeight: "62px" }}
                  divId="filtersMenuBarRow"
                  allData={allData}
                  childrenPlacement="start"
                >
                  <div
                    className={`selected-filter-item-container child-container DatastoryAddFilters-GA`}
                    id="reportManagerInstance"
                    title="Add filters"
                  >
                    <i
                      className="material-icons add-filters-icon selected-filter-item-title child-element DatastoryAddFilters-GA"
                      onClick={handleAddFilters}
                    >
                      add
                    </i>
                  </div>
                </SelectedFilters>
              )}
            </FormRow>
          )}
        </div>
      </section>
    </>
  );
}

// function Charts(props) {
//   const {
//     reorderSelectionsFlag = false,
//     data: savedCharts = {},
//     onChange = () => {},
//     value = [],
//     allData,
//     user,
//     handleReload = () => {},
//     themeColors,
//     ReduxDispatcher,
//     dsForm,
//     crudType = "create",
//   } = props;
//   const [searchField, setSearchField] = useState({
//     status: "success",
//     value: "",
//     originalValue: "",
//     message: "",
//   });
//   const containerId = useRef(`sc_${v4()}`);
//   const [elHeight, setElHeight] = useState(0);

//   // Defining required variables
//   const isEdit = crudType !== "create";
//   const gaCategory = isEdit ? "EditDatastory" : "CreateDatastory";

//   // TODO: Make a custom hook
//   // Defining required side effects
//   useEffect(() => {
//     // Defining required function
//     const handleWindowSize = () => {
//       // Should come from config
//       const appCardWidth = 180;
//       const appRowGridGap = 35;

//       // Getting slice count based on width
//       const element = document.getElementById(containerId.current);
//       const elementContentWidth = getElementContentWidth(element);
//       const numberOfCardsThatCanFit = Math.floor(
//         elementContentWidth / (appCardWidth + appRowGridGap)
//       );
//       // console.log("numberOfCardsThatCanFit", numberOfCardsThatCanFit);
//       // console.log("150 * 2", 150 * 2);
//       if (dsForm.selectedCharts.value.length > numberOfCardsThatCanFit) {
//         // console.log("if");
//         setElHeight(150 * 2);
//       } else {
//         // console.log("else");
//         setElHeight(150);
//       }
//     };

//     // Call the function when component mounts
//     handleWindowSize();
//     // Add event listener to window resize
//     window.addEventListener("resize", handleWindowSize);

//     return () => {
//       // Remove event listener to window resize
//       window.removeEventListener("resize", handleWindowSize);
//     };
//   }, [dsForm]);

//   // Defining required change handlers
//   const handleClickAway = () => {
//     // Google Analytics Event - Master
//     masterTrackGaEvent({
//       category: gaCategory,
//       action: "Search",
//       label: "Charts",
//     });
//     if (searchField.originalValue !== searchField.value) {
//       setSearchField((prevState) => ({
//         ...prevState,
//         status: "success",
//         originalValue: prevState.value,
//       }));
//     }
//   };
//   const handleSearchFieldChange = (event, value) => {
//     // Need to change the value real-time
//     // Hence updating value and originalValue at the same time
//     setSearchField((prevState) => ({
//       ...prevState,
//       status: "success",
//       value,
//       originalValue: value,
//     }));
//     // setSearchField((prevState) => ({ ...prevState, value }));
//     // handleClickAway();
//   };
//   const handleSearchFieldClear = () => {
//     setSearchField((prevState) => ({
//       ...prevState,
//       status: "success",
//       originalValue: "",
//       value: "",
//     }));
//   };
//   const handleChartItemClick = (chartItem) => {
//     const chartsMaxLimitInDs = config.hardCoded.chartsMaxLimitInDs;

//     let newCharts = [...value];
//     const selectedChartsIds = value.map((row) => row._id);
//     const chartItemOrgObject = chartItem.actualPayload.chartItemPayload.payload;
//     const chartItemId = chartItemOrgObject._id;
//     const isChartItemAlreadySelected = selectedChartsIds.includes(chartItemId);
//     // If user removed a kpi which means checked = false, remove it from the value list
//     // Else add it
//     if (!isChartItemAlreadySelected) {
//       if (value.length === chartsMaxLimitInDs) {
//         //Update snackbar
//         let snackbarPayload = {
//           ...user.screen.snackbar,
//           open: true,
//           message: `You can select a maximum of ${chartsMaxLimitInDs} charts`,
//         };
//         ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
//       } else {
//         newCharts = [...newCharts, chartItemOrgObject];
//         onChange("selectedCharts", newCharts);
//       }
//     } else {
//       newCharts = newCharts.filter((row) => row._id !== chartItemId);
//       onChange("selectedCharts", newCharts);
//     }
//   };
//   const handleCreateChart = () => {
//     // Google Analytics Event - Master
//     masterTrackGaEvent({
//       category: "CreateDatastory",
//       action: "CreateNew",
//       label: "Chart",
//     });
//     // // Update global filters from the current DS (time & dimension)
//     // var newTimeFilters = dsForm.timeFilters.value;
//     // var action = replaceTimeFilters(newTimeFilters);
//     // ReduxDispatcher(action);
//     // var payload = {
//     //   newDimensionFilters: dsForm.dimensionFilters.value,
//     // };
//     // var action = replaceAllDimensionFilters(payload);
//     // ReduxDispatcher(action);

//     const handleClose = () => {
//       // Close the dialog
//       const value = {
//         open: false,
//         title: "",
//         onClose: () => {},
//         children: null,
//       };
//       var action = updateUserScreen("sigviewBigDialog", value);
//       ReduxDispatcher(action);

//       // // Empty global filters
//       // var newTimeFilters = dsForm.timeFilters.value;
//       // var action = replaceTimeFilters(newTimeFilters);
//       // ReduxDispatcher(action);
//       // var payload = {
//       //   newDimensionFilters: [],
//       // };
//       // var action = replaceAllDimensionFilters(payload);
//       // ReduxDispatcher(action);

//       // Re-fetch charts data
//       handleReload();
//     };

//     const handleCloseDialog = (type = "showDialog") => {
//       //Type will decide if we need to show the dialog or not
//       // justClose -> Don't show dialog
//       // showDialog -> Show dialog
//       if (type === "justClose") {
//         handleClose();
//       } else {
//         const message = {
//           title: "Are you sure you want to close?",
//           subtitle: "This action cannot be undone",
//         };
//         const handleNo = () => {
//           //Close the dialog box
//           ReduxDispatcher(
//             updateDialogInfo({
//               ...user.dialogInfo,
//               open: false,
//             })
//           );
//         };
//         const handleYes = () => {
//           //Close the dialog box
//           ReduxDispatcher(
//             updateDialogInfo({
//               ...user.dialogInfo,
//               open: false,
//               message: { title: "", subtitle: "" },
//             })
//           );
//           handleClose();
//         };
//         ReduxDispatcher(
//           updateDialogInfo({
//             ...user.dialogInfo,
//             open: true,
//             message,
//             handleYes,
//             handleNo,
//           })
//         );
//       }
//     };

//     const handleChartSave = (props = {}) => {
//       const { backendPayload = {} } = props;
//       // TODO
//       // trackCrudGA("Save");
//       const fetchProps = {
//         payload: { ...backendPayload },
//       };
//       ReduxDispatcher(updateUserScreen("isDashboardLoading", true));
//       const saveChartPromise = saveChart(fetchProps);
//       saveChartPromise
//         .then((responseData) => {
//           let snackbarPayload = {
//             ...user.screen.snackbar,
//             open: true,
//             message: "Chart saved successfully!",
//           };
//           // Update snackbar
//           var action = updateUserScreen("snackbar", snackbarPayload);
//           ReduxDispatcher(action);
//           // Remove dashboard loader
//           var action = updateUserScreen("isDashboardLoading", false);
//           ReduxDispatcher(action);

//           // Close the popover
//           handleClose();
//         })
//         .catch((json) => {
//           console.error("API FAILED");
//           console.groupCollapsed("Error Details");
//           console.log("Error JSON -> ", json);
//           console.groupEnd();
//           let snackbarPayload = {
//             ...user.screen.snackbar,
//             open: true,
//             message: json.error || "Saving chart failed",
//           };
//           var action = updateUserScreen("snackbar", snackbarPayload);
//           ReduxDispatcher(action);
//           var action = updateUserScreen("isDashboardLoading", false);
//           ReduxDispatcher(action);
//         });
//     };

//     //Remove charts which are pivotx
//     const filteredChartList = savedCharts.data.filter(
//       (row) => row.chartObject.metadata.chartType !== "pivotx"
//     );
//     const newTitle = `Untitled Chart - ${filteredChartList.length + 1}`;
//     // Update activeChart details with defaultState
//     let initialStandaloneChartState = {
//       id: `chart-object-${v4()}`,
//       title: newTitle,
//       dimensionsList: [],
//       metricsList: [],
//       orderById: "",
//       orderBy: "asc",
//       chartType: "bar",
//       metricFilters: [],
//       dimensionFilters: [],
//       timeFilters: user.timeFilters,
//       chartList: staticChartJson,
//       elementType: "create", // create or update to help differentiate in PlotCreate,
//       renderFlag: false, // to control rendering of the chart in the component
//     };
//     // Updating plot by calling the reducer
//     // Adding required business logic
//     var payload = { key: "chartType", value: "bar" };
//     var action = updateStandaloneChartForm(payload);
//     initialStandaloneChartState = standaloneChartReducer(
//       initialStandaloneChartState,
//       action
//     );

//     const chartPopoverProps = {
//       initialChartData: {
//         result: { dataFromQE: [], extraData: {} },
//         status: "loading",
//         message: "",
//       },
//       initialStandaloneChartData: initialStandaloneChartState,
//       eventHandlers: { onYes: handleChartSave, onNo: handleCloseDialog },
//       settings: { yesTitle: "Add & Continue", noTitle: "Discard" },
//     };

//     // Update activeChart in redux
//     const sigviewBigDialogVal = {
//       open: true,
//       props: {
//         title: "Add New Chart",
//         onClose: handleCloseDialog,
//         children: <ChartPopover {...chartPopoverProps} />,
//       },
//     };
//     var value = {
//       sigviewBigDialog: sigviewBigDialogVal,
//       activeChart: initialStandaloneChartState,
//     };
//     var action = updateUserScreen(null, value);
//     ReduxDispatcher(action);
//   };

//   // // DEBUGGER
//   // useEffect(() => {
//   //   console.groupCollapsed(
//   //     "%cRENDERED | Charts.js",
//   //     "color: yellow; font-size: 15px"
//   //   );
//   //   console.log("props -> ", props);
//   //   console.groupEnd();
//   // });

//   // Defining required variables
//   // Remove charts which are pivotx
//   const isAnyChartSelected = value.length > 0;
//   const isPageCreate = crudType !== "edit";
//   let filteredSavedCharts = savedCharts.data.filter(
//     (row) => row.chartObject.metadata.chartType !== "pivotx"
//   );
//   // Filter based on search value
//   filteredSavedCharts = filteredSavedCharts.filter((row) =>
//     row.chartObject.metadata.title
//       .toLowerCase()
//       .includes(searchField.originalValue.toLowerCase())
//   );
//   // Filter those charts which are already present in selected charts list (if page is edit)
//   if (isPageCreate) {
//     let selectedChartsIds = Object.keys(value);
//     filteredSavedCharts = filteredSavedCharts.filter(
//       (row) => !selectedChartsIds.includes(row._id)
//     );
//   }
//   // Conversion so that it can be dev to unwrapperChartObjec func
//   filteredSavedCharts = filteredSavedCharts.map((row) => ({
//     id: row._id,
//     name: row.chartObject.metadata.title,
//     payload: row,
//     chartType: row.chartObject.metadata.chartType,
//   }));
//   // Adding chartObjectUI using uwrapperChartObject to filter out corrupt objects
//   filteredSavedCharts = filteredSavedCharts.map((row) => ({
//     ...row,
//     chartObjectUI: unwrapperChartObject({
//       allData: allData,
//       user: user,
//       payload: { ...row.payload },
//     }),
//   }));
//   // Filter out corrupt objects
//   filteredSavedCharts = filteredSavedCharts.filter(
//     (row) => row.chartObjectUI.valid
//   );
//   // ! HARD CODED: Chart list ordering not present in the backend
//   // ! Hence, doing it here
//   filteredSavedCharts = [...filteredSavedCharts].reverse();
//   filteredSavedCharts = filteredSavedCharts.map(convertChartResToDsRes);
//   const savedChartsAppRowProps = {
//     // title: isAnyChartSelected ? "Remaining Charts" : "All Charts",
//     title: "Charts",
//     payload: {},
//     data: filteredSavedCharts,
//     noCardProps: {
//       title: "No Charts Available",
//       flag: true,
//     },
//     newCardProps: {
//       title: "Create New Chart",
//       iconsProps: {
//         flag: true,
//         className: "material-icons-outlined",
//         value: "add_box",
//       },
//       onClick: handleCreateChart,
//     },
//     createNewCardFlag: true,
//     singleRowFlag: false,
//     titleRowFlag: false,
//     buttonRowFlag: false,
//     appCardTag: "chartWithIcon",
//     onAppCardClick: (event, value) => {
//       handleChartItemClick(value);
//     },
//   };
//   const renderPropsSavedChartsLoading = {
//     flag: true,
//     component: () => <Loader />,
//   };
//   const renderPropsSavedChartsError = {
//     flag: true,
//     component: () => (
//       <ErrorHandler
//         message={savedCharts.message}
//         reloadFlag={true}
//         onReload={handleReload}
//       />
//     ),
//   };
//   const uiFriendlySavedCharts = unwrapperAppRow(savedChartsAppRowProps);
//   const searchFieldProps = {
//     searchField,
//     handleSearchFieldChange,
//     handleClickAway,
//     handleSearchFieldClear,
//   };
//   const buttonProps = {
//     handleClick: handleCreateChart,
//     title: "Create Chart",
//     flag: false,
//   };
//   // const appMenuBarStyle = { padding: "0px 25px 15px 25px" };
//   const appMenuBarProps = {
//     searchFieldProps,
//     buttonProps,
//     // style: appMenuBarStyle,
//   };
//   // ! Search field only for charts (not for selected charts)
//   // // Filter based on search value
//   // let filteredSavedChartsSelected = value.filter((row) =>
//   //   row.chartObject.metadata.title
//   //     .toLowerCase()
//   //     .includes(searchField.originalValue.toLowerCase())
//   // );
//   let filteredSavedChartsSelected = [...Object.keys(value)];
//   // Conversion so that it can be dev to unwrapperChartObjec func
//   filteredSavedChartsSelected = filteredSavedChartsSelected.map((row) => ({
//     id: row._id,
//     name: row.chartObject.metadata.title,
//     payload: row,
//     chartType: row.chartObject.metadata.chartType,
//   }));
//   // Adding chartObjectUI using uwrapperChartObject to filter out corrupt objects
//   filteredSavedChartsSelected = filteredSavedChartsSelected.map((row) => ({
//     ...row,
//     chartObjectUI: unwrapperChartObject({
//       allData: allData,
//       user: user,
//       payload: { ...row.payload },
//     }),
//   }));
//   // Filter out corrupt objects
//   filteredSavedChartsSelected = filteredSavedChartsSelected.filter(
//     (row) => row.chartObjectUI.valid
//   );
//   filteredSavedChartsSelected = filteredSavedChartsSelected.map(
//     convertChartResToDsRes
//   );
//   const savedChartsSelectedAppRowProps = {
//     title: "Selected Charts",
//     payload: {},
//     data: filteredSavedChartsSelected,
//     noCardProps: {
//       title: "Click on charts to select",
//       flag: true,
//     },
//     createNewCardFlag: false,
//     singleRowFlag: false,
//     titleRowFlag: false,
//     buttonRowFlag: false,
//     appCardTag: "chartWithIcon",
//     onAppCardClick: (event, value) => {
//       handleChartItemClick(value);
//     },
//     appCardSelected: true,
//   };
//   const uiFriendlySavedChartsSelected = unwrapperAppRow(
//     savedChartsSelectedAppRowProps
//   );
//   const textFieldCustomStyle = {
//     wrapperBgColor: themeColors["secondaryColorLightest"],
//     wrapperWidth: "200px",
//     wrapperHeight: "30px",
//     textFieldFontSize: "12px",
//   };
//   const postIconProps = {
//     className: "material-icons react-material-icons",
//     iconName: "clear",
//     onClick: handleSearchFieldClear,
//   };
//   const iconProps = {
//     className: "material-icons react-material-icons",
//     iconName: "search",
//   };
//   //TODO: REMOVE IT SOMEHOW (Make resuable layouts)
//   const TEMP_STYLE = {
//     root: {
//       // height: "calc(100% - 60px)",
//       height: "100%",
//     },
//     chartsWrapper: {
//       height: "50%",
//     },
//     chartsSavedWrapper: {
//       height: `calc(60px + ${elHeight}px)`,
//       // height: `calc(60px + 150px)`,
//     },
//     chartsAllWrapper: {
//       height: `calc(100% - 60px - ${elHeight}px)`,
//       // height: `calc(100% - 60px - 150px)`,
//     },
//     chartsWrapperAlone: { height: "calc(100% - 25px)" },
//     chartsHeading: {
//       height: "25px",
//       boxSizing: "content-box",
//     },
//     chartsHeadingWrapper: {
//       display: "flex",
//       justifyContent: "space-between",
//       alignItems: "center",
//       padding: "0px 25px 10px",
//       height: "30px",
//     },
//     chartsHeadingLeftPanel: {},
//     chartsHeadingRightPanel: {},
//     chartsList: { height: "calc(100% - 60px)", overflow: "auto" },
//     // divider: { padding: "0px 25px" },
//     noteWrapper: {
//       padding: "0px 35px",
//       textAlign: "center",
//     },
//   };

//   return (
//     <>
//       {/* <AppMenuBar {...appMenuBarProps} /> */}
//       <section style={TEMP_STYLE.root}>
//         {/* {!isAnyChartSelected && (
//           <section style={TEMP_STYLE.noteWrapper}>
//             <SigviewTypography variant="pMedium">
//               {config.messages.clickToAddChartsCreateDs}
//             </SigviewTypography>
//           </section>
//         )} */}

//         {/* SELECTED CHARTS */}
//         <section style={TEMP_STYLE.chartsSavedWrapper}>
//           <section style={TEMP_STYLE.chartsHeadingWrapper}>
//             <section style={TEMP_STYLE.chartsHeading}>
//               <SigviewTypography variant="pLarge">
//                 {savedChartsSelectedAppRowProps.title}
//               </SigviewTypography>
//             </section>
//           </section>
//           <section
//             style={TEMP_STYLE.chartsList}
//             className="sigview-styled-scroller"
//           >
//             <AppRow {...uiFriendlySavedChartsSelected} />
//           </section>
//         </section>

//         {/* CHARTS */}
//         <section style={TEMP_STYLE.chartsAllWrapper}>
//           <section style={TEMP_STYLE.chartsHeadingWrapper}>
//             <section style={TEMP_STYLE.chartsHeading}>
//               <SigviewTypography variant="pLarge">
//                 {savedChartsAppRowProps.title}
//               </SigviewTypography>
//             </section>
//             <section>
//               <SigviewTextFieldAsync
//                 customStyle={textFieldCustomStyle}
//                 iconFlag={true}
//                 iconProps={iconProps}
//                 value={searchField.value}
//                 onChange={handleSearchFieldChange}
//                 status={searchField.status}
//                 onClickAway={handleClickAway}
//                 postIconFlag={true}
//                 postIconProps={postIconProps}
//                 placeholder="Search"
//               />
//             </section>
//           </section>
//           <section
//             style={TEMP_STYLE.chartsList}
//             className="sigview-styled-scroller"
//             id={containerId.current}
//           >
//             {savedCharts.status === "success" && (
//               <AppRow {...uiFriendlySavedCharts} />
//             )}
//             {savedCharts.status === "loading" && (
//               <AppRow
//                 {...uiFriendlySavedCharts}
//                 renderProps={renderPropsSavedChartsLoading}
//               />
//             )}
//             {savedCharts.status === "error" && (
//               <AppRow
//                 {...uiFriendlySavedCharts}
//                 renderProps={renderPropsSavedChartsError}
//               />
//             )}
//           </section>
//         </section>
//       </section>
//     </>
//   );
// }

// function Kpis(props) {
//   const {
//     // REDUX PROPS
//     ReduxDispatcher,
//     allData,
//     user,
//     // PARENT PROPS
//     reorderSelectionsFlag = false,
//     data = [],
//     onChange = () => {},
//     value = [],
//     crudType = "create",
//   } = props;
//   const [searchField, setSearchField] = useState({
//     status: "success",
//     value: "",
//     originalValue: "",
//     message: "",
//   });

//   // Defining required variables
//   const isEdit = crudType !== "create";
//   const gaCategory = isEdit ? "EditDatastory" : "CreateDatastory";

//   // Defining required change handlers
//   const handleChange = (event, id, checked, item) => {
//     let newKpis = [...value];
//     // If user removed a kpi which means checked = false, remove it from the value list
//     // Else add it
//     if (checked) {
//       const kpisMaxLimitInDs = config.hardCoded.kpisMaxLimitInDs;
//       if (value.length < kpisMaxLimitInDs) {
//         newKpis = [...newKpis, id];
//       } else {
//         //Update snackbar
//         let snackbarPayload = {
//           ...user.screen.snackbar,
//           open: true,
//           message: `You can select a maximum of ${kpisMaxLimitInDs} KPIs`,
//         };
//         ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
//       }
//     } else {
//       newKpis = newKpis.filter((row) => row !== id);
//     }
//     onChange("selectedKpis", newKpis);
//   };
//   const handleClickAway = () => {
//     // Google Analytics Event - Master
//     masterTrackGaEvent({
//       category: gaCategory,
//       action: "Search",
//       label: "KPIs",
//     });
//     if (searchField.originalValue !== searchField.value) {
//       setSearchField((prevState) => ({
//         ...prevState,
//         status: "success",
//         originalValue: prevState.value,
//       }));
//     }
//   };
//   const handleSearchFieldChange = (event, value) => {
//     // Need to change the value real-time
//     // Hence updating value and originalValue at the same time
//     setSearchField((prevState) => ({
//       ...prevState,
//       status: "success",
//       value,
//       originalValue: value,
//     }));
//     // setSearchField((prevState) => ({ ...prevState, value }));
//     // handleClickAway();
//   };
//   const handleSearchFieldClear = () => {
//     setSearchField((prevState) => ({
//       ...prevState,
//       status: "success",
//       originalValue: "",
//       value: "",
//     }));
//   };

//   // // DEBUGGER
//   // useEffect(() => {
//   //   console.groupCollapsed(
//   //     "%cRENDERED | Kpis.js",
//   //     "color: yellow; font-size: 15px"
//   //   );
//   //   console.log("props -> ", props);
//   //   console.groupEnd();
//   // });

//   // Defining required styles
//   const customStyle = {
//     padding: "0px 25px",
//   };
//   // Re-order data based on flag
//   //Add checked flag based on user selections (dataMod)
//   const dataMod = data.map((row) => ({
//     ...row,
//     checked: value.includes(row.id),
//   }));
//   const groupedData = groupBy(dataMod, (el) => el.checked);
//   let checkboxData = [...dataMod]; // combination of
//   if (reorderSelectionsFlag)
//     checkboxData = [...(groupedData.true || []), ...(groupedData.false || [])];
//   // Filter data
//   let checkboxDataFiltered = checkboxData.filter((row) =>
//     row.name.toLowerCase().includes(searchField.value.toLowerCase())
//   );
//   const searchFieldProps = {
//     searchField,
//     handleSearchFieldChange,
//     handleClickAway,
//     handleSearchFieldClear,
//   };
//   const buttonProps = {
//     flag: false,
//   };
//   const appMenuBarProps = { searchFieldProps, buttonProps };

//   const infoIconComponent = (item) => {
//     const plotlyMetrics = allData.plotlyMetrics;
//     const metricId = item.id;
//     const itemObj = getMeasureObjByOriginalID(metricId, plotlyMetrics);
//     const showFlag = itemObj._id.startsWith("C");
//     const tooltipTitle = itemObj.displayFormula || "";
//     return (
//       <>
//         {showFlag && (
//           <SigviewTooltip title={tooltipTitle} placement="bottom">
//             <span>
//               <SigviewIcon
//                 className="material-icons"
//                 iconName="info"
//                 style={{ padding: "0px 10px" }}
//               />
//             </span>
//           </SigviewTooltip>
//         )}
//       </>
//     );
//   };

//   return (
//     <>
//       {/* <button
//         onClick={() => {
//           // passing only unchecked values
//           const initialData = [...(groupedData.false || [])];
//           handleAddNewKpis(initialData);
//         }}
//       >
//         Add New KPIs
//       </button> */}
//       <AppMenuBar {...appMenuBarProps} />
//       <section style={customStyle}>
//         {checkboxDataFiltered.length > 0 && (
//           <SigviewCheckboxGroup
//             data={checkboxDataFiltered}
//             variant="grid"
//             onChange={handleChange}
//             renderProp={{ flag: true, component: infoIconComponent }}
//           />
//         )}
//         {checkboxDataFiltered.length === 0 && (
//           <div className="no-data-container">
//             <div className="no-data-image"></div>
//             <p className="no-data">No KPIs available</p>
//           </div>
//         )}
//       </section>
//     </>
//   );
// }

function ChartsPopover(props) {
  const { t } = useTranslation();
  const {
    themeColors,
    dsForm,
    // REDUX PROPS
    user,
    allData,
    ReduxDispatcher,
    // PARENT PROPS
    initialSelections: selections = [],
    initialData: data = [],
    onChange = () => {},
    onSave = () => {},
    settings = {},
    crudType = "create",
    handleReload = () => {},
  } = props;
  const { reorderSelectionsFlag = false } = settings;
  const containerId = useRef(`sc_${v4()}`);
  const [searchField, setSearchField] = useState("");
  const [elHeight, setElHeight] = useState(0);

  // Defining required variables
  const isEdit = crudType !== "create";
  const gaCategory = isEdit ? "EditDatastory" : "CreateDatastory";

  // * Define required side effects
  // TODO: Make a custom hook
  useEffect(() => {
    // Defining required function
    const handleWindowSize = () => {
      // Should come from config
      const appCardWidth = 180;
      const appRowGridGap = 12;

      // Getting slice count based on width
      const element = document.getElementById(containerId.current);
      const elementContentWidth = getElementContentWidth(element);
      const numberOfCardsThatCanFit = Math.floor(
        elementContentWidth / (appCardWidth + appRowGridGap)
      );
      // console.log("numberOfCardsThatCanFit", numberOfCardsThatCanFit);
      // console.log("150 * 2", 150 * 2);
      const selectedChartsCount = Object.keys(
        dsForm.selectedCharts.value
      ).length;
      if (selectedChartsCount > numberOfCardsThatCanFit) {
        // console.log("if");
        setElHeight(150 * 2);
      } else {
        // console.log("else");
        setElHeight(150);
      }
    };

    // Call the function when component mounts
    handleWindowSize();
    // Add event listener to window resize
    window.addEventListener("resize", handleWindowSize);

    return () => {
      // Remove event listener to window resize
      window.removeEventListener("resize", handleWindowSize);
    };
  }, [dsForm]);

  // * Define required event handlers
  const handleChartItemClick = (chartItem) => {
    const chartsMaxLimitInDs = config.hardCoded.chartsMaxLimitInDs;

    let newChartsId = selections.map((row) => row.id);
    const selectedChartsIds = selections.map((row) => row.id);
    const chartItemId = chartItem.actualPayload.chartItemPayload.id;
    const isChartItemAlreadySelected = selectedChartsIds.includes(chartItemId);
    // If user removed a kpi which means checked = false, remove it from the selections list
    // Else add it
    if (!isChartItemAlreadySelected) {
      if (selections.length === chartsMaxLimitInDs) {
        //Update snackbar
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: `You can select a maximum of ${chartsMaxLimitInDs} charts`,
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
      } else {
        newChartsId = [...newChartsId, chartItemId];
        onChange(null, newChartsId);
      }
    } else {
      newChartsId = newChartsId.filter((chartId) => chartId !== chartItemId);
      onChange(null, newChartsId);
    }
  };
  const handleCreateChart = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "CreateDatastory",
      action: "CreateNew",
      label: "Chart",
    });

    const handleClose = () => {
      // Close the dialog
      const value = {
        open: false,
        title: "",
        onClose: () => {},
        children: null,
      };
      var action = updateUserScreen("sigviewBigDialog", value);
      ReduxDispatcher(action);

      // // Empty global filters
      // var newTimeFilters = dsForm.timeFilters.value;
      // var action = replaceTimeFilters(newTimeFilters);
      // ReduxDispatcher(action);
      // var payload = {
      //   newDimensionFilters: [],
      // };
      // var action = replaceAllDimensionFilters(payload);
      // ReduxDispatcher(action);

      // Re-fetch charts data
      handleReload();
    };

    const handleCloseDialog = (type = "showDialog") => {
      //Type will decide if we need to show the dialog or not
      // justClose -> Don't show dialog
      // showDialog -> Show dialog
      if (type === "justClose") {
        handleClose();
      } else {
        const message = {
          title: "Are you sure you want to cancel?",
          subtitle: "This action cannot be undone",
        };
        const handleNo = () => {
          //Close the dialog box
          ReduxDispatcher(
            updateDialogInfo({
              ...user.dialogInfo,
              open: false,
            })
          );
        };
        const handleYes = () => {
          //Close the dialog box
          ReduxDispatcher(
            updateDialogInfo({
              ...user.dialogInfo,
              open: false,
              message: { title: "", subtitle: "" },
            })
          );
          handleClose();
        };
        ReduxDispatcher(
          updateDialogInfo({
            ...user.dialogInfo,
            open: true,
            message,
            handleYes,
            handleNo,
          })
        );
      }
    };

    const handleChartSave = (props = {}) => {
      const { backendPayload = {} } = props;
      // TODO
      // trackCrudGA("Save");
      const fetchProps = {
        payload: { ...backendPayload },
      };
      ReduxDispatcher(updateUserScreen("isDashboardLoading", true));
      const saveChartPromise = saveChart(fetchProps);
      saveChartPromise
        .then((responseData) => {
          let snackbarPayload = {
            ...user.screen.snackbar,
            open: true,
            message: "Chart saved successfully!",
          };
          // Update snackbar
          var action = updateUserScreen("snackbar", snackbarPayload);
          ReduxDispatcher(action);
          // Remove dashboard loader
          var action = updateUserScreen("isDashboardLoading", false);
          ReduxDispatcher(action);

          // Close the popover
          handleClose();
        })
        .catch((json) => {
          console.error("API FAILED");
          console.groupCollapsed("Error Details");
          console.log("Error JSON -> ", json);
          console.groupEnd();
          let snackbarPayload = {
            ...user.screen.snackbar,
            open: true,
            message: json.error || "Saving chart failed",
          };
          var action = updateUserScreen("snackbar", snackbarPayload);
          ReduxDispatcher(action);
          var action = updateUserScreen("isDashboardLoading", false);
          ReduxDispatcher(action);
        });
    };

    //Remove charts which are pivotx
    const newTitle = `Untitled Chart - ${data.result.length + 1}`;
    // Update activeChart details with defaultState
    let initialStandaloneChartState = {
      id: `chart-object-${v4()}`,
      title: newTitle,
      dimensionsList: [],
      metricsList: [],
      orderById: "",
      orderBy: "desc",
      chartType: "bar",
      metricFilters: [],
      dimensionFilters: [],
      timeFilters: user.timeFilters,
      chartList: staticChartJson,
      elementType: "create", // create or update to help differentiate in PlotCreate,
      renderFlag: false, // to control rendering of the chart in the component
    };
    // Updating plot by calling the reducer
    // Adding required business logic
    var payload = { key: "chartType", value: "bar" };
    var action = updateStandaloneChartForm(payload);
    initialStandaloneChartState = standaloneChartReducer(
      initialStandaloneChartState,
      action
    );

    const chartPopoverProps = {
      initialChartData: {
        result: { dataFromQE: [], extraData: {} },
        status: "loading",
        message: "",
      },
      initialStandaloneChartData: initialStandaloneChartState,
      eventHandlers: { onYes: handleChartSave, onNo: handleCloseDialog },
      settings: { yesTitle: "Add & Continue", noTitle: "Discard" },
    };

    // Update activeChart in redux
    const sigviewBigDialogVal = {
      open: true,
      props: {
        title: "Add New Chart",
        onClose: handleCloseDialog,
        children: <ChartPopover {...chartPopoverProps} />,
      },
    };
    var value = {
      sigviewBigDialog: sigviewBigDialogVal,
      activeChart: initialStandaloneChartState,
    };
    var action = updateUserScreen(null, value);
    ReduxDispatcher(action);
  };
  const handleSearchFieldChange = (event, value) => setSearchField(value);

  // // DEBUGGER
  // useEffect(() => {
  //   console.groupCollapsed(
  //     "%cRENDERED | Charts.js",
  //     "color: yellow; font-size: 15px"
  //   );
  //   console.log("props -> ", props);
  //   console.groupEnd();
  // });

  // * Define required variables
  // * Make ui friendly data for display
  // A. Data
  // 1. Filter based on search string
  let finalData = data.result.filter((row) =>
    row.name.toLowerCase().includes(searchField.toLowerCase())
  );
  // 2. Convert to app row data structure
  finalData = finalData.map(convertToAppCardFriendlyDs);
  const savedChartsAppRowProps = {
    title: t("Charts"),
    payload: {},
    data: finalData,
    noCardProps: {
      title: t("No Charts Available"),
      flag: true,
    },
    newCardProps: {
      title: t("Create New Chart"),
      iconsProps: {
        flag: true,
        className: "material-icons-outlined",
        value: "add_box",
      },
      onClick: handleCreateChart,
    },
    createNewCardFlag: true,
    singleRowFlag: false,
    titleRowFlag: false,
    buttonRowFlag: false,
    appCardTag: "chartWithIcon",
    onAppCardClick: (event, value) => {
      handleChartItemClick(value);
    },
  };
  const renderPropsSavedChartsLoading = {
    flag: true,
    component: () => <Loader />,
  };
  const renderPropsSavedChartsError = {
    flag: true,
    component: () => (
      <ErrorHandler
        message={data.message}
        reloadFlag={true}
        onReload={handleReload}
      />
    ),
  };
  const uiFriendlySavedCharts = unwrapperAppRow(savedChartsAppRowProps);
  // B. Selections
  let finalSelections = selections.map(convertToAppCardFriendlyDs);
  const savedChartsSelectedTitle = `${t("Selected Charts")}  (${
    selections.length
  }/${config.hardCoded.chartsMaxLimitInDs})`;
  const savedChartsSelectedAppRowProps = {
    title: savedChartsSelectedTitle,
    payload: {},
    data: finalSelections,
    noCardProps: {
      title: t("Click on charts to select"),
      flag: true,
    },
    createNewCardFlag: false,
    singleRowFlag: false,
    titleRowFlag: false,
    buttonRowFlag: false,
    appCardTag: "chartWithIcon",
    onAppCardClick: (event, value) => {
      handleChartItemClick(value);
    },
    appCardSelected: true,
  };
  const uiFriendlySavedChartsSelected = unwrapperAppRow(
    savedChartsSelectedAppRowProps
  );
  //TODO: REMOVE IT SOMEHOW (Make resuable layouts)
  const TEMP_STYLE = {
    root: {
      // height: "calc(100% - 60px)",
      height: "100%",
    },
    chartsWrapper: {
      height: "50%",
    },
    chartsSavedWrapper: {
      height: `calc(60px + ${elHeight}px)`,
      // height: `calc(60px + 150px)`,
    },
    chartsAllWrapper: {
      height: `calc(100% - 60px - ${elHeight}px)`,
      // height: `calc(100% - 60px - 150px)`,
    },
    chartsWrapperAlone: { height: "calc(100% - 25px)" },
    chartsHeading: {
      height: "25px",
      boxSizing: "content-box",
    },
    chartsHeadingWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0px 25px 10px",
      height: "30px",
    },
    chartsHeadingLeftPanel: {},
    chartsHeadingRightPanel: {},
    chartsList: { height: "calc(100% - 60px)", overflow: "auto" },
    // divider: { padding: "0px 25px" },
    noteWrapper: {
      padding: "0px 35px",
      textAlign: "center",
    },
  };

  return (
    <>
      {/* <AppMenuBar {...appMenuBarProps} /> */}
      <section style={TEMP_STYLE.root}>
        {/* {!isAnyChartSelected && (
          <section style={TEMP_STYLE.noteWrapper}>
            <SigviewTypography variant="pMedium">
              {config.messages.clickToAddChartsCreateDs}
            </SigviewTypography>
          </section>
        )} */}

        {/* SELECTED CHARTS */}
        <section style={TEMP_STYLE.chartsSavedWrapper}>
          <section style={TEMP_STYLE.chartsHeadingWrapper}>
            <section style={TEMP_STYLE.chartsHeading}>
              <SigviewTypography variant="pLarge">
                {savedChartsSelectedAppRowProps.title}
              </SigviewTypography>
            </section>
          </section>
          <section
            style={TEMP_STYLE.chartsList}
            className="sigview-styled-scroller"
          >
            <AppRow {...uiFriendlySavedChartsSelected} />
          </section>
        </section>

        {/* CHARTS */}
        <section style={TEMP_STYLE.chartsAllWrapper}>
          <section style={TEMP_STYLE.chartsHeadingWrapper}>
            <section style={TEMP_STYLE.chartsHeading}>
              <SigviewTypography variant="pLarge">
                {savedChartsAppRowProps.title}
              </SigviewTypography>
            </section>
            <section>
              <SigviewSearchField
                customStyle={{
                  wrapperBgColor: "#fff",
                  wrapperBorderHover: "#fff",
                  wrapperBorderFocus: "#fff",
                }}
                onChange={handleSearchFieldChange}
              />
            </section>
          </section>
          <section
            style={TEMP_STYLE.chartsList}
            className="sigview-styled-scroller"
            id={containerId.current}
          >
            {data.status === "success" && <AppRow {...uiFriendlySavedCharts} />}
            {data.status === "loading" && (
              <AppRow
                {...uiFriendlySavedCharts}
                renderProps={renderPropsSavedChartsLoading}
              />
            )}
            {data.status === "error" && (
              <AppRow
                {...uiFriendlySavedCharts}
                renderProps={renderPropsSavedChartsError}
              />
            )}
          </section>
        </section>
      </section>
    </>
  );
}

function DsSettings(props) {
  // * Destructure props
  const { dsForm, handleDsFormChange } = props;

  // Defining event handlers

  // Defining required static variables
  const { dimensionFilterType, timeFilterType, rollingDateType } = dsForm;

  const useSigviewStyles = makeSigviewStyles();
  const classes = useSigviewStyles();

  return (
    <>
      <section className={classes.formContainer}>
        <div className={classes.form} onSubmit={(e) => e.preventDefault()}>
          <FormRow
            label="Apply Dimension Filters On:"
            isBgColor={false}
            classes={classes}
          >
            <SigviewRadioGroup
              data={dataForCheckBox.dimensionData}
              value={dimensionFilterType.value}
              onChange={(newValue) =>
                handleDsFormChange("dimensionFilterType", newValue)
              }
            />
          </FormRow>

          <FormRow
            label="Apply Time Filters On:"
            isBgColor={false}
            classes={classes}
          >
            <SigviewRadioGroup
              data={dataForCheckBox.dimensionData}
              value={timeFilterType.value}
              onChange={(newValue) =>
                handleDsFormChange("timeFilterType", newValue)
              }
            />
          </FormRow>

          <FormRow
            label="Rolling Dates:"
            // labelRenderProp={rollingDateslabelRenderProp}
            // inputRenderProp={rollingDateslabelRenderProp}
            isBgColor={false}
            classes={classes}
          >
            <SigviewRadioGroup
              data={dataForCheckBox.rollingData}
              value={rollingDateType.value}
              onChange={(newValue) =>
                handleDsFormChange("rollingDateType", newValue)
              }
            />
          </FormRow>
        </div>
      </section>
    </>
  );
}

// * Define required components
function KpisPopover(props) {
  const { t } = useTranslation();
  // * Destructure props
  const {
    // REDUX PROPS
    user,
    allData,
    ReduxDispatcher,
    // PARENT PROPS
    initialSelections = [],
    initialData = [],
    onChange = () => {},
    onSave = () => {},
    settings = {},
  } = props;
  const { maxLimit = Number.MAX_SAFE_INTEGER, isOnSaveVisible = false } =
    settings;

  // * Define required states
  const [searchField, setSearchField] = useState("");
  const [selections, setSelections] = useState(initialSelections);
  const [data, setData] = useState(initialData);

  // * Define required event handlers
  const handleChange = (event, id, checked, item) => {
    let newKpis = [...selections];
    // If user removed a kpi which means checked = false, remove it from the value list
    // Else add it
    if (checked) {
      if (selections.length < maxLimit) {
        newKpis = [...selections, id];
      } else {
        //Update snackbar
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: `You can select a maximum of ${maxLimit} KPIs`,
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
      }
    } else {
      newKpis = newKpis.filter((row) => row !== id);
    }
    setSelections(newKpis);
  };
  const handleSearchFieldChange = (event, value) => setSearchField(value);
  const handleSave = () => onSave(selections);

  // * Define required side effects
  useEffect(() => {
    onChange(null, selections);
  }, [selections]);

  // * Define requried static variables
  const infoIconComponent = (item) => {
    const plotlyMetrics = allData.plotlyMetrics;
    const metricId = item.id;
    const itemObj = getMeasureObjByOriginalID(metricId, plotlyMetrics);
    const showFlag = itemObj._id.startsWith("C");
    const tooltipTitle = itemObj.displayFormula || "";
    let infoTooltipStatus = false;
    if (
      user.reqMetadata.organization === "Kayzen" &&
      user.reqMetadata.view === "Percentile"
    ) {
      infoTooltipStatus =
        (tooltipTitle !== undefined &&
          tooltipTitle.startsWith("(Price Digest")) ||
        (tooltipTitle !== undefined &&
          tooltipTitle.startsWith("(price digest"));
    }

    return (
      <>
        {showFlag && !infoTooltipStatus && (
          <SigviewTooltip title={tooltipTitle} placement="bottom">
            <span>
              <SigviewIcon
                className="material-icons"
                iconName="info"
                style={{ padding: "0px 10px" }}
              />
            </span>
          </SigviewTooltip>
        )}
      </>
    );
  };
  const dataMod = data.map((row) => ({
    ...row,
    checked: selections.includes(row.id),
  }));
  let checkboxData = [...dataMod]; // combination of
  // const groupedData = groupBy(dataMod, (el) => el.checked);
  // if (reorderSelectionsFlag)
  //   checkboxData = [
  //     ...(groupedData.true || []),
  //     ...(groupedData.false || []),
  //   ];
  // Filter data
  let checkboxDataFiltered = checkboxData.filter((row) =>
    row.name.toLowerCase().includes(searchField.toLowerCase())
  );
  const customButtonStyle = {
    wrapperHeight: "100%",
    buttonHeight: "30px",
    margin: { left: "10px" },
  };
  const headerCss = {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 25px 10px 25px",
    alignItems: "center",
    height: "50px",
    width: "100%",
    boxSizing: "border-box",
  };
  const rightPanelCss = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };
  const kpisContainerCss = {
    padding: "10px 25px",
    width: "100%",
    boxSizing: "border-box",
  };
  const title = `${t("Selected KPIs")} (${selections.length}/${
    config.hardCoded.kpisMaxLimitInDs
  })`;

  return (
    <>
      {/* <AppMenuBar {...appMenuBarProps} /> */}
      <Box css={headerCss}>
        <SigviewTypography variant="pLarge">{title}</SigviewTypography>
        <Box css={rightPanelCss}>
          <Box css={{ width: "210px" }}>
            <SigviewSearchField
              customStyle={{
                wrapperBgColor: "#fff",
                wrapperBorderHover: "#fff",
                wrapperBorderFocus: "#fff",
              }}
              onChange={handleSearchFieldChange}
            />
          </Box>
          {isOnSaveVisible && (
            <SigviewButton
              variant="contained"
              onClick={handleSave}
              title="Save"
              iconFlag={false}
              iconProps={{
                className: "material-icons-outlined",
                iconName: "add_box",
              }}
              style={customButtonStyle}
              customClassName={`DatastorySaveKPIs-GA`}
            />
          )}
        </Box>
      </Box>
      <Box css={kpisContainerCss}>
        {checkboxDataFiltered.length > 0 && (
          <SigviewCheckboxGroup
            data={checkboxDataFiltered}
            customID="DatastoryCheckboxDataFiltered-GA"
            variant="grid"
            onChange={handleChange}
            renderProp={{ flag: true, component: infoIconComponent }}
          />
        )}
        {checkboxDataFiltered.length === 0 && (
          <div className="no-data-container">
            <div className="no-data-image"></div>
            <p className="no-data">{t("No new KPIs available to add")}</p>
          </div>
        )}
      </Box>
    </>
  );
}

export { Details, DsSettings, KpisPopover, ChartsPopover };
