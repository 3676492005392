// * Import required libraies
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";

// * Import 3rd party lib comp

// * Import Custom Component
import LayoutTopSideBottom from "../../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import Loader from "../../../components/Loader/Loader";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
import DatastoryDashboard from "./DatastoryDashboard";

// * Import actions

// * Import data/utils
import { config } from "../../../config/config";
import SIGVIEW_CONTANTS from "../../../constants/sigviewConstants";
import { updateUserScreen } from "../../../redux/actions";
import { getBookmarkDetails } from "../../../utils/analyzeUtils";
import axios from "axios";

// * Import contexts

// * Import API functions

// * Define required variables

function DatastoryDashboardContainer(props = {}) {
  // * Destructure props
  const { user = {}, dispatch: ReduxDispatcher } = props;

  // * Define hooks
  const history = useHistory();

  // * Define required states
  const [pageStatus, setPageStatus] = useState(
    SIGVIEW_CONTANTS.initialPageStatus
  );

  const location = useLocation();

  useEffect(() => {
    const { isSharedUrlFlag, bookmarkId } = getBookmarkDetails(location);
    const source = axios.CancelToken.source();

    const isPivotShared = isSharedUrlFlag;

    if (isPivotShared) {
      const userScreenValue = {
        activeNav: "datastory",
        activeTab: "dashboard",
        activeDsCategory: "shared",
        activeDatastory: {
          dsCategory: "Shared",
          elementType: "create",
          title: "Untitled Datastory",
        },
      };

      var action = updateUserScreen(null, userScreenValue);
      ReduxDispatcher(action);
    }
  }, []);

  useEffect(() => {
    // ! QUICK FIX
    const runFlag = user.screen.activeDatastory.dsCategory !== undefined;
    const { isSharedUrlFlag, bookmarkId } = getBookmarkDetails(location);
    // * CORRECT BUT LENGHTY FIX
    // const runFlag = isActiveDatastoryValid(user.screen.activeDatastory)

    if (runFlag) {
      setPageStatus({
        status: "success",
        message: "",
      });
    } else {
      // history.push("/datastory");
      if (!isSharedUrlFlag) {
        history.push("/datastory");
      }
      // setPageStatus({
      //   status: "loading",
      //   message: "",
      // });
    }
  }, [user.screen.activeDatastory]);

  return (
    <>
      {pageStatus.status === "loading" && (
        <LayoutTopSideBottom>
          <Loader />
        </LayoutTopSideBottom>
      )}
      {pageStatus.status === "error" && (
        <LayoutTopSideBottom>
          <ErrorHandler
            message={pageStatus.message}
            reloadFlag={true}
            onReload={() => {
              window.reload();
            }}
          />
        </LayoutTopSideBottom>
      )}
      {pageStatus.status === "success" && <DatastoryDashboard />}
    </>
  );
}

DatastoryDashboard.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(DatastoryDashboardContainer);
