// * Import required libraries
import { useContext, useState, useEffect } from "react";
import axios from "axios";

// * Import lib component
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

// * Import custom components
import Loader from "../../../components/Loader/Loader";
import SigviewButton from "../../../components/Common/SigviewButton";
import SigviewTypography from "../../../components/Common/SigviewTypography";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";

// * Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

// * Import utils
import { updateUserScreen } from "../../../redux/actions";
import { config } from "../../../config/config";
import {
  getChartSharedUrl,
  getDatastorySharedUrl,
  getPivotSharedUrl,
  getWorkspaceSharedUrl,
  getReportSharedUrl,
  transformBookmarkPayload,
} from "../../../utils/utils";
import { masterTrackGaEvent } from "../../../services/ga";

//  * Import APIs
import { saveBookmark } from "../../../services/api";

// * Define style functions
const makeSigviewStyles = (...args) => {
  const [themeColors, customStyle] = args;
  const useStyles = makeStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      padding: "10px 0px 15px 0px",
      alignItems: "center",
      width: "450px",
      height: "170px",
      boxSizing: "border-box",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0px 20px 10px 20px",
      width: "100%",
      boxSizing: "border-box",
      flexShrink: 0,
      borderBottom: `1px solid ${themeColors["secondaryColorLighter"]}`,
    },
    body: {
      padding: "10px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      flexGrow: 1,
      flexBasis: 0,
      boxSizing: "border-box",
      padding: "0px 20px",
    },
    footer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      width: "100%",
      flexShrink: 0,
      boxSizing: "border-box",
      padding: "0px 20px",
    },
  });
  return useStyles;
};

// * Define required static variables
const initialData = {
  status: "loading",
  message: "",
  result: { tokenId: "" },
};

const AnalyzeShareDialog = (props = {}) => {
  // * Destructure props
  const { ReduxDispatcher, user, payload = {}, type = "" } = props;
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  // * Define required states
  const [savedBookmark, setSavedBookmark] = useState(initialData);
  const [reloadSaveBookmark, setReloadSaveBookmark] = useState(Date.now());

  // * Define required event handlers
  const handleCopyToClipboard = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Share",
      action: "ShareURLCopy",
      label: "CreateURL",
    });

    // * NEW IMPLEMENTATION
    // ! Navigator is available only on a secure connection
    // ! It won't work locally
    // ! To test locally, use ngrok which creates a secure tunnel between local and internet

    let sharedUrl;
    if (type === "worksheet")
      sharedUrl = getWorkspaceSharedUrl(savedBookmark.result.tokenId);
    else if (type === "pivot")
      sharedUrl = getPivotSharedUrl(savedBookmark.result.tokenId);
    else if (type === "chart")
      sharedUrl = getChartSharedUrl(savedBookmark.result.tokenId);
    else if (type === "datastory")
      sharedUrl = getDatastorySharedUrl(savedBookmark.result.tokenId);
    else if (type === "report")
      sharedUrl = getReportSharedUrl(savedBookmark.result.tokenId);
    if (window.navigator.clipboard) {
      navigator.clipboard.writeText(sharedUrl);
    } else {
      console.error("UI ERROR");
      console.error("window.navigator.clipboard not available");
    }

    // ! OLD IMPLEMENTATION
    // var dummy = document.createElement("textarea");
    // document.body.appendChild(dummy);
    // const sharedUrl = getWorkspaceSharedUrl(savedBookmark.result.tokenId);
    // dummy.value = sharedUrl;
    // dummy.select();
    // document.execCommand("copy");
    // document.body.removeChild(dummy);

    //Update snackbar
    let snackbarPayload = {
      ...user.screen.snackbar,
      open: true,
      message: "Copied to clipboard!",
    };
    ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
  };
  const handleOpenInANewTab = (value) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Share",
      action: "ShareURLOpenInNewTab",
      label: "OpenInNewTab",
    });

    var a = document.createElement("a");
    let sharedUrl;
    if (type === "worksheet")
      sharedUrl = getWorkspaceSharedUrl(savedBookmark.result.tokenId);
    else if (type === "pivot")
      sharedUrl = getPivotSharedUrl(savedBookmark.result.tokenId);
    else if (type === "chart")
      sharedUrl = getChartSharedUrl(savedBookmark.result.tokenId);
    else if (type === "datastory")
      sharedUrl = getDatastorySharedUrl(savedBookmark.result.tokenId);
    else if (type === "report")
      sharedUrl = getReportSharedUrl(savedBookmark.result.tokenId);
    a.href = sharedUrl;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const handleClose = () => {
    const payload = { open: false, children: null };
    const action = updateUserScreen("sigviewDialog", payload);
    ReduxDispatcher(action);
  };

  const handleReload = () => setReloadSaveBookmark(new Date().valueOf());

  // * Define required side effects
  useEffect(() => {
    //Make fetch call using axios
    const source = axios.CancelToken.source();
    const runFlag = !user.screen.isDashboardLoading;
    if (runFlag) {
      const fetchProps = {
        payload: transformBookmarkPayload(payload, type, user),
        cancelToken: source.token,
      };
      const saveBookmarkPromise = saveBookmark(fetchProps);
      saveBookmarkPromise
        .then((responseData) => {
          const tokenId = responseData?.result?.data?._id || "";
          const newData = {
            status: "success",
            message: "",
            result: { tokenId },
          };
          setSavedBookmark(newData);
        })
        .catch((json) => {
          if (json.error !== config.hardCoded.queryCancelled) {
            console.groupCollapsed("UI ERROR");
            console.log("ERROR -> ", json.error);
            console.groupEnd();
            const newData = {
              status: "error",
              message: json.error || "Creating shared URL failed!",
              result: {},
            };
            setSavedBookmark(newData);
          }
        });
    }
    //Clean-up function to cancel all pending fetch calls
    return () => {
      //Cancel all previous fetch calls
      if (source) source.cancel();
      setSavedBookmark(initialData);
    };
  }, [reloadSaveBookmark]);

  // * Define required static variables
  const useSigviewStyles = makeSigviewStyles(themeColors);
  const classes = useSigviewStyles();
  let sharedUrl;
  if (type === "worksheet")
    sharedUrl = getWorkspaceSharedUrl(savedBookmark.result.tokenId);
  else if (type === "pivot")
    sharedUrl = getPivotSharedUrl(savedBookmark.result.tokenId);
  else if (type === "chart")
    sharedUrl = getChartSharedUrl(savedBookmark.result.tokenId);
  else if (type === "datastory")
    sharedUrl = getDatastorySharedUrl(savedBookmark.result.tokenId);
  else if (type === "report")
    sharedUrl = getReportSharedUrl(savedBookmark.result.tokenId);
  const isButtonDisabled = savedBookmark.status !== "success";

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <SigviewTypography variant="pLarge" style={{ height: "max-content" }}>
          Copy To Share This URL
        </SigviewTypography>
        <i
          className="material-icons-round sigview-close-icon"
          onClick={handleClose}
        >
          close
        </i>
      </Box>
      <Box className={classes.body}>
        {savedBookmark.status === "success" && (
          <SigviewTypography
            variant="pLarge"
            style={{ height: "max-content", lineHeight: 1.5 }}
          >
            {sharedUrl}
          </SigviewTypography>
        )}
        {savedBookmark.status === "loading" && <Loader />}
        {savedBookmark.status === "error" && (
          <ErrorHandler
            message={savedBookmark.message}
            reloadFlag={true}
            onReload={handleReload}
          />
        )}
      </Box>
      <Box className={classes.footer}>
        <SigviewButton
          customClassName="ShareURLOpenInNewTab-GA"
          variant="contained"
          title="Open in a New Tab"
          onClick={handleOpenInANewTab}
          disabled={isButtonDisabled}
        />
        <SigviewButton
          customClassName="ShareURLCopy-GA"
          variant="contained"
          onClick={handleCopyToClipboard}
          title="Copy to Clipboard"
          style={{ margin: { left: "10px" } }}
          disabled={isButtonDisabled}
        />
      </Box>
    </Box>
  );
};

export default AnalyzeShareDialog;
