// * Import required libraies
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";

// * Import 3rd party lib comp

// * Import Custom Component
import LayoutTopSideBottom from "../../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import Loader from "../../../components/Loader/Loader";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
import ChartsCreate from "./ChartsCreate";

// * Import actions

// * Import data/utils
import { config } from "../../../config/config";
import SIGVIEW_CONTANTS from "../../../constants/sigviewConstants";
import {
  getBookmarkDetails,
  makeAttributeMissingErrorMessage,
} from "../../../utils/analyzeUtils";
import { updateUserScreen } from "../../../redux/actions";
import { getBookmark } from "../../../services/api";
import axios from "axios";
import { unwrapperChartObject } from "../../../utils/chartObjectUtils";
import { v4 } from "uuid";
import { isSharePivotValidBasedOnUserAccess } from "../../../utils/pivotUtils";

// * Import contexts

// * Import API functions

// * Define required variables

function ChartsCreateContainer(props = {}) {
  // * Destructure props
  const { user = {}, allData, dispatch: ReduxDispatcher } = props;

  // * Define hooks
  const history = useHistory();

  // * Define required states
  const [pageStatus, setPageStatus] = useState(
    SIGVIEW_CONTANTS.initialPageStatus
  );
  const [ws, setWs] = useState(SIGVIEW_CONTANTS.initialState);

  useEffect(() => {
    // ! QUICK FIX
    const { isSharedUrlFlag, bookmarkId } = getBookmarkDetails(location);
    const runFlag = user.screen.activeChart.chartList !== undefined;

    // * CORRECT BUT LENGHTY FIX
    // const runFlag = isActiveDatastoryValid(user.screen.activeDatastory)

    if (runFlag) {
      setPageStatus({
        status: "success",
        message: "",
      });
    } else {
      // history.push("/datastory/chart");
      if (!isSharedUrlFlag) {
        history.push("/datastory/chart");
      }
      // setPageStatus({
      //   status: "loading",
      //   message: "",
      // });
    }
  }, [user.screen.activeChart]);

  const location = useLocation();

  useEffect(() => {
    const { isSharedUrlFlag, bookmarkId } = getBookmarkDetails(location);
    const source = axios.CancelToken.source();

    const isChartShared = isSharedUrlFlag;
    const flag =
      allData.dateRange.data &&
      allData.dateRange.data.startDate &&
      allData.dateRange.data.endDate;

    if (isChartShared && flag) {
      const payload = {
        bookmarkId: bookmarkId,
        isShare: "true",
      };
      const fetchProps = {
        payload: payload,
        cancelToken: source.token,
      };

      const getBookmarkPromise = getBookmark(fetchProps);
      getBookmarkPromise
        .then((responseData) => {
          // * Validating workspace for missing attributes (access change)
          const isSharePivotValidBasedOnUserAccessProps = {
            backendWs: responseData.result.data.profile.pivot,
            user,
            allData,
          };
          const isSharePivotValidBasedOnUserAccessResponse =
            isSharePivotValidBasedOnUserAccess(
              isSharePivotValidBasedOnUserAccessProps
            );
          if (isSharePivotValidBasedOnUserAccessResponse.status !== "valid") {
            let message = makeAttributeMissingErrorMessage(
              isSharePivotValidBasedOnUserAccessResponse,
              "Chart"
            );
            let newData = {
              status: "accessError",
              message,
              result: {},
            };
            setWs(newData);
          } else {
            let result = responseData.result.data.profile;
            let newData = { status: "success", message: "", result };
            setWs(newData);
            let unwrappedChartObj = unwrapperChartObject({
              variant: "share",
              allData,
              user,
              payload: {
                chartObject: { ...responseData.result.data.profile.pivot },
              },
            });
            let chartObjectUI = unwrappedChartObj;
            // Update activeChart in redux and screen so that user can be redirected to chart
            const userScreenValue = {
              activeTab: "createChart",
              activeChart: {
                ...chartObjectUI,
                title: "Untitled Chart",
                id: v4(),
                elementType: "create",
                renderFlag: true,
              },
            };
            var action = updateUserScreen(null, userScreenValue);
            ReduxDispatcher(action);
            ReduxDispatcher(updateUserScreen("isPivotShared", true));
          }
        })
        .catch((json) => {
          if (json.error !== config.hardCoded.queryCancelled) {
            const message = json.error || config.messages.uiErrorMessage;
            let newData = {
              status: "error",
              message,
              result: {},
            };
            setWs(newData);

            console.groupCollapsed("UI ERROR");
            console.log("JSON", json);
            console.log("ERROR ->", message);
            console.groupEnd();
          }
        });
    }
  }, [allData.dateRange]);

  const handleAccessCorruptError = () => {
    history.push("/datastory/chart");
  };
  return (
    <>
      {ws.status === "accessError" && (
        <LayoutTopSideBottom>
          <ErrorHandler
            message={ws.message}
            reloadFlag={true}
            onReload={handleAccessCorruptError}
            reloadButtonProps={{
              flag: true,
              // variant: "contained",
              title: "Redirect to Home",
            }}
          />
        </LayoutTopSideBottom>
      )}
      {pageStatus.status === "loading" && ws.status !== "accessError" && (
        <LayoutTopSideBottom>
          <Loader />
        </LayoutTopSideBottom>
      )}
      {pageStatus.status === "error" && ws.status !== "accessError" && (
        <LayoutTopSideBottom>
          <ErrorHandler
            message={pageStatus.message}
            reloadFlag={true}
            onReload={() => {
              window.reload();
            }}
          />
        </LayoutTopSideBottom>
      )}
      {pageStatus.status === "success" && <ChartsCreate />}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(ChartsCreateContainer);
