// * Import required libraies
import React, { useState } from "react";
import { v4 } from "uuid";

// * Import lib comp
import { Box } from "@material-ui/core";

// * Import Custom Component
import SigviewButton from "../Common/SigviewButton";
import SigviewHoverPopup from "../Common/SigviewHoverPopup";
import SigviewIcon from "../Common/SigviewIcon";
import SigviewSimpleText from "../Common/SigviewSimpleText";
import SigviewTextField from "../Common/SigviewTextField";
import Loader from "../Loader/Loader";

// * Import action creators
import { updateUserScreen } from "../../redux/actions";

// * Import utils/data
import { config } from "../../config/config";

// * Import APIs
import {
  ReportNameCheck,
  addSavedReport,
  addScheduledReport,
} from "../../services/api";
import {
  validateReportName,
  wrapperSavedReport,
  wrapperScheduledReport,
} from "../../utils/reportUtils";

//Defining required variables
const reportMessages = config.messages.reports;

const ReportClone = (props = {}) => {
  // * Destructure props
  const {
    user = {},
    ReduxDispatcher,
    initialName = "",
    reportFormData = {},
    reportType = "",
    globalFilters = {},
    allData = {},
    activeReport = {},
    setActiveReport,
    reloadData,
  } = props;

  // * Define requried state
  const [name, setName] = useState({
    value: initialName,
    status: "success", // success/error/loading
    message: "",
  });

  // * Define required event handlers
  const handleNameChange = (newValue) => {
    setName(() => {
      const { flag, message } = validateReportName({ reportName: newValue });
      let finalStatus = "success";
      if (flag) finalStatus = "error";
      const newState = { status: finalStatus, message, value: newValue };
      return newState;
    });
  };

  const handleCancel = () => {
    const payload = { open: false, children: null };
    const action = updateUserScreen("sigviewDialog", payload);
    ReduxDispatcher(action);
  };
  const handleError = (message) => {
    setName((prevState) => ({
      ...prevState,
      status: "error",
      message: message,
    }));
  };
  const handleClone = (type = "clone") => {
    if (reportType === "scheduled") {
      const fetchPayload = {
        orgViewReq: {
          organization: user?.reqMetadata?.organization,
          view: user?.reqMetadata?.view,
        },
        email: user?.reqMetadata?.email,
        reportName: name.value,
        reportType: "scheduled",
      };
      const fetchProps = {
        payload: fetchPayload,
      };
      const dsNameCheckPromise = ReportNameCheck(fetchProps);
      dsNameCheckPromise
        .then((responseData) => {
          if (!responseData.result.data.status) {
            //Update snackbar
            let snackbarPayload = {
              ...user.screen.snackbar,
              open: true,
              message: reportMessages.scheduledRequest,
            };
            ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

            //Making fetch call
            //hard coded
            //If the saved report had five_thoudsand_records selected, API will fail, change it to fifty_thousand_records
            const reportRowCount = [
              "fifty_thousand_records",
              "all_records",
            ].includes(reportFormData.reportRowCount)
              ? reportFormData.reportRowCount
              : "fifty_thousand_records";
            const wrapperProps = {
              activeReportFormData: {
                reportFormData: {
                  ...reportFormData,
                  reportId: `report-${v4()}`,
                  reportName: name.value,
                  reportRowCount,
                }, //Updating Id
                reportPayload: {}, //Adding download/saved report to scheduled reports
              },
              user: user,
              allData: allData,
              activeReport,
              globalFilters: globalFilters,
            };
            const fetchPayload = wrapperScheduledReport(wrapperProps);
            const fetchProps = {
              payload: fetchPayload,
            };
            const addScheduledReportPromise = addScheduledReport(fetchProps);
            addScheduledReportPromise
              .then((responseDate) => {
                //Update snackbar
                let snackbarPayload = {
                  ...user.screen.snackbar,
                  open: true,
                  message: reportMessages.scheduledSuccess,
                };
                ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
                //Close the active report
                setActiveReport({ type: "none", id: "" });

                //Reload data
                reloadData("scheduled");
                const payload = { open: false, children: null };
                const action = updateUserScreen("sigviewDialog", payload);
                ReduxDispatcher(action);
              })
              .catch((json) => {
                console.log("API FAILED", json);
                // setIsLoading(false);
                let snackbarPayload = {
                  ...user.screen.snackbar,
                  open: true,
                  message: json.error || reportMessages.scheduledFailure,
                };
                ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
              });
          } else {
            handleError("Report name already exists!");
          }
        })
        .catch((json) => {
          console.groupCollapsed("API FAILED");
          console.log("Error JSON -> ", json);
          console.groupEnd();
          // ! INCORRECT RESPONSE STRUCTURE (HENCE COMMENTING)
          handleError("Report name check failed");
        });
    }

    if (reportType === "saved") {
      const fetchPayload = {
        orgViewReq: {
          organization: user?.reqMetadata?.organization,
          view: user?.reqMetadata?.view,
        },
        email: user?.reqMetadata?.email,
        reportName: name.value,
        reportType: "saved",
      };
      const fetchProps = {
        payload: fetchPayload,
      };
      const dsNameCheckPromise = ReportNameCheck(fetchProps);
      dsNameCheckPromise
        .then((responseData) => {
          if (!responseData.result.data.status) {
            //Update snackbar
            let snackbarPayload = {
              ...user.screen.snackbar,
              open: true,
              message: reportMessages.savedRequest,
            };
            ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
            const addSavedReportFlag = true;

            //Making fetch call
            const wrapperProps = {
              activeReportFormData: {
                reportFormData: {
                  ...reportFormData,
                  reportId: `report-${v4()}`,
                  reportName: name.value,
                }, //Updating Id
                reportPayload: {}, //Adding download/scheduled report to saved reports
              },
              user: user,
              allData: allData,
              activeReport,
              globalFilters: globalFilters,
              // variant: "adminReport",
              addSavedReportFlag,
            };
            const fetchPayload = wrapperSavedReport(wrapperProps);
            const fetchProps = {
              payload: fetchPayload,
            };
            const addSavedReportPromise = addSavedReport(fetchProps);
            addSavedReportPromise
              .then((responseDate) => {
                //Update snackbar
                let snackbarPayload = {
                  ...user.screen.snackbar,
                  open: true,
                  message: reportMessages.savedSuccess,
                };
                ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
                //Close the active report
                setActiveReport({ type: "none", id: "" });

                //Reload data
                reloadData("saved");
                const payload = { open: false, children: null };
                const action = updateUserScreen("sigviewDialog", payload);
                ReduxDispatcher(action);

                //   ReduxDispatcher(updateUserScreen("adminReport", false));
              })
              .catch((json) => {
                console.log("API FAILED", json);

                let snackbarPayload = {
                  ...user.screen.snackbar,
                  open: true,
                  message: json.error || reportMessages.savedFailure,
                };
                ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
              });
          } else {
            handleError("Report name already exists!");
          }
        })
        .catch((json) => {
          console.groupCollapsed("API FAILED");
          console.log("Error JSON -> ", json);
          console.groupEnd();
          // ! INCORRECT RESPONSE STRUCTURE (HENCE COMMENTING)
          handleError("Report name check failed");
          // handleError(json.error || "Workspace name check failed");
        });
    }
  };

  // * Define required variables
  const errorFlag = name.status === "error";
  const helperText = name.message;
  const cloneDisabled = ["error", "loading"].includes(name.status);
  const cloneTitle = name.status === "loading" ? <Loader /> : "Clone";
  const box1Style = {
    display: "flex",
    margin: "0px 0px 8px 0px",
    alignItems: "center",
  };

  return (
    <section className="sigview-dialog-chart-clone">
      <Box css={box1Style}>
        <SigviewSimpleText
          value="Report Name"
          style={{
            border: "none",
            fontSize: "12px",
            padding: "0px",
            margin: "0px 5px 0px 0px",
          }}
        />
        <SigviewHoverPopup
          title="Report Name Rules"
          data={config.hardCoded.reportNameRules}
        >
          <SigviewIcon className="material-icons-round" iconName="info" />
        </SigviewHoverPopup>
      </Box>

      <SigviewTextField
        placeholder="Please enter a valid report name..."
        value={name.value}
        onChange={(newValue) => handleNameChange(newValue)}
        error={errorFlag}
        helperText={helperText}
        minWidth="400px"
        maxWidth="400px"
        height="40px"
        margin={{ right: "5px", bottom: "10px" }}
      />

      <article className="sigview-dialog-chart-clone-menu-bar">
        <SigviewButton
          variant="outlined"
          onClick={handleCancel}
          title="Cancel"
          style={{
            width: "max-content",
            margin: { left: "3px", right: "3px" },
          }}
        />
        <SigviewButton
          variant="contained"
          onClick={() => handleClone("clone")}
          disabled={cloneDisabled}
          title={cloneTitle}
          style={{
            width: "max-content",
            margin: { left: "3px", right: "3px" },
          }}
          customClassName="adminConfirmCloneReport-GA"
        />
      </article>
    </section>
  );
};

export { ReportClone };
