// * Import required libraries
import React, {
  useState,
  useEffect,
  useContext,
  useReducer,
  useRef,
} from "react";
import { connect } from "react-redux";
import isEqual from "lodash.isequal";
import { useHistory } from "react-router";

// * Import 3rd party lib
import { Box } from "@material-ui/core";

// * Import custom components
import LayoutTopSideBottom from "../../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import SigviewBreadcrumb from "../../../components/Common/SigviewBreadcrumb";
import { SigviewDialogPivotClone } from "./PivotCreateHelpers";
import { PlotMenuBar } from "./PivotCreateHelpers";
import DimensionFilters from "../../../components/DimensionFilters/DimensionFilters";
import StandalonePivot from "./StandalonePivot";

// Import utils/data
import { config } from "../../../config/config";
import { masterTrackGaEvent } from "../../../services/ga";
import {
  isEqualWithoutCertainKeys,
  masterMakeChartObject,
  getBreadcrumbsDataFromRoute,
} from "../../../utils/utils";
import { initialUserSelection } from "../../../utils/pivotUtils";
import useReducerLogger from "../../../utils/useReducerLogger";
import {
  wrapperChartObject,
  wrapperPivotXAdvancedSort,
} from "../../../utils/chartObjectUtils";

// Import reducer
import pivotFormReducer from "../../../redux/reducers/pivotForm";

// Import action creators
import {
  updateUserScreen,
  updateDialogInfo,
  replaceStandaloneChartForm,
  updateStandaloneChartForm,
  updatePivotForm,
  replacePivotForm,
  replaceAllDimensionFilters,
  updateGlobalFiltersDimensionFilters,
  updateGlobalFiltersTimeFilters,
  updateGlobalFiltersFormAnalyzeFiltersReadOnly,
} from "../../../redux/actions";
import dimensionFiltersReducer from "../../../redux/reducers/dimensionFilters";

// Import API functions
import { deleteChart, saveChart, updateChart } from "../../../services/api";
import AnalyzeShareDialog from "../../Analyze/AnalyzeDashboard/AnalyzeShareDialog";

// Defining required variables
const defaultState = { ...initialUserSelection };

function PivotCreate(props) {
  // * Destructure props
  const {
    dispatch: ReduxDispatcher,
    user,
    allData,
    globalFilters,
    initialState = defaultState,
  } = props;

  // * Define required hooks
  const history = useHistory();

  const [pivotstate, dispatchPivotState] = useReducer(
    useReducerLogger(pivotFormReducer),
    user.screen.activePivot
  );
  const originalPivot = useRef(user.screen.activePivot);
  const [chartName, setChartName] = useState({
    status: "success",
    value: user.screen.activePivot.title,
    originalValue: user.screen.activePivot.title,
    message: "",
  });

  // * Define required variables
  const isEdit = pivotstate.elementType === "update";

  useEffect(() => {
    return () => {
      // Revert to inital state in redux when component unmounts
      // This is to cater to case when user switches between tabs
      ReduxDispatcher(updateGlobalFiltersFormAnalyzeFiltersReadOnly());
      ReduxDispatcher(updateUserScreen("isPivotShared", false));
    };
  }, []);

  // * Define required side effects
  //Update chartTitle in the object everytime chartName (parent state) changes
  //Do this only when chartName.originalValue changes because chartName.value is
  //real-time state and chartName.originalValue refers to the renamed state
  useEffect(() => {
    // Update state only when they are different
    if (pivotstate.title !== chartName.originalValue) {
      const payload = { key: "title", value: chartName.originalValue };
      // console.log("payload of use Effect", payload);
      var action = updatePivotForm(payload);
      dispatchPivotState(action);
      // Rename chart is a separate API
      originalPivot.current = {
        ...originalPivot.current,
        title: chartName.originalValue,
      };
    }
  }, [chartName.originalValue]);

  //Update local state of Pivot if user.screen.activePivot changes
  //Update local PivotName if user.screen.activePivot changes
  useEffect(() => {
    //Perform it only when they are not equal to avoid infinite loop
    if (!isEqual(user.screen.activePivot, pivotstate)) {
      const value = user.screen.activePivot;
      var payload = { key: "replacePivotForm", value };
      var action = updatePivotForm(payload);
      dispatchPivotState(action);
      // Can come here (Not needed)
      // standaloneChartOriginal.current = { ...user.screen.activeChart };
      setChartName(() => ({
        status: "success",
        value: user.screen.activePivot.title,
        originalValue: user.screen.activePivot.title,
        message: "",
      }));
    }
  }, [user.screen.activePivot]);

  // Defining required event handlers
  const handlePivotUpdate = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Pivot",
      action: "UpdatePivot",
      label: pivotstate.id,
    });
    const newSelections = {
      ...pivotstate,
      dataLimit: pivotstate.dataLimit,
      orderBy: "desc",
      orderById: pivotstate.metricsList[0]._id,
      granularity: globalFilters?.metricChartGranularity?.value,
    };

    const props = { selections: newSelections, user };
    const getDataPayload = wrapperChartObject(props);
    const newPayload = {
      ...getDataPayload,
      _id: pivotstate.id,
      chartObject: {
        ...getDataPayload.chartObject,
        metadata: {
          ...getDataPayload.chartObject.metadata,
          chartType: "pivotx",
        },
        requestParam: {
          ...getDataPayload.chartObject.requestParam,
          pivotXAdvancedSort: wrapperPivotXAdvancedSort({
            pivotXAdvancedSort: pivotstate.advanceSort,
            allData,
          }),
        },
      },
    };
    // * DEBUGGER
    // console.groupCollapsed("API CALLING");
    // console.log("getDataPayload", getDataPayload);
    // console.log("id", pivotstate.id);
    // console.log("newPayload", newPayload);
    // console.log("pivotstate", pivotstate);
    // console.log("pivotstate", pivotstate.dimensionsList);
    // console.groupEnd();

    const fetchProps = {
      payload: { ...newPayload },
    };
    ReduxDispatcher(updateUserScreen("isDashboardLoading", true));
    const updateChartPromise = updateChart(fetchProps);
    updateChartPromise
      .then((responseData) => {
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: "Pivot updated successfully!",
        };
        // update originalPivotRef as well
        originalPivot.current = pivotstate;
        // Update snackbar
        var action = updateUserScreen("snackbar", snackbarPayload);
        ReduxDispatcher(action);
        // Remove dashboard loader
        var action = updateUserScreen("isDashboardLoading", false);
        ReduxDispatcher(action);
      })
      .catch((json) => {
        console.groupCollapsed("API FAILED");
        console.log("Error JSON -> ", json);
        console.groupEnd();
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: json.error || "Updating Pivot failed",
        };
        var action = updateUserScreen("snackbar", snackbarPayload);
        ReduxDispatcher(action);
        var action = updateUserScreen("isDashboardLoading", false);
        ReduxDispatcher(action);
      });
  };

  const handleCloneDialog = () => {
    // trackCrudGA("Clone");
    const initialName = `${config.hardCoded.cloneOfPreText} ${pivotstate.title}`;
    const payload = {
      open: true,
      children: (
        <SigviewDialogPivotClone
          ReduxDispatcher={ReduxDispatcher}
          initialName={initialName}
          user={user}
          pivotstate={pivotstate}
          isEdit={isEdit}
          allData={allData}
        />
      ),
    };
    const action = updateUserScreen("sigviewDialog", payload);
    ReduxDispatcher(action);
  };

  const handlePivotDelete = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Pivot",
      action: "DeletePivot",
      label: pivotstate.id,
    });

    ReduxDispatcher(updateUserScreen("isDashboardLoading", true));
    const fetchProps = {
      url: `${user.apiEndpoints.baseUrl}${config.apiEndpoints.deleteChart}?id=${pivotstate.id}`,
    };
    const deleteChartPromise = deleteChart(fetchProps);
    deleteChartPromise
      .then((responseData) => {
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: "Pivot deleted successfully!",
        };
        // Update snackbar
        var action = updateUserScreen("snackbar", snackbarPayload);
        ReduxDispatcher(action);
        // Remove dashboard loader
        var action = updateUserScreen("isDashboardLoading", false);
        ReduxDispatcher(action);
        // Change tab to home page
        const value = {
          activeNav: "pivot",
          activeTab: "home",
          activeChart: {},
        };
        var action = updateUserScreen(null, value);
        ReduxDispatcher(action);
        history.push("/pivotx");
      })
      .catch((json) => {
        console.groupCollapsed("API FAILED");
        console.log("Error JSON -> ", json);
        console.groupEnd();
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: json.error || "Deleting chart failed",
        };
        var action = updateUserScreen("snackbar", snackbarPayload);
        ReduxDispatcher(action);
        var action = updateUserScreen("isDashboardLoading", false);
        ReduxDispatcher(action);
      });
  };

  const handleDeleteDialog = () => {
    // trackCrudGA("Delete");
    const message = {
      title: "Are you sure you want to delete?",
      subtitle: "This action cannot be undone",
    };
    const handleNo = () => {
      //Close the dialog box
      ReduxDispatcher(
        updateDialogInfo({
          ...user.dialogInfo,
          open: false,
        })
      );
      // trackCrudGA("DeleteNo");
    };
    const handleYes = () => {
      //Close the dialog box
      ReduxDispatcher(
        updateDialogInfo({
          ...user.dialogInfo,
          open: false,
          message: { title: "", subtitle: "" },
        })
      );
      handlePivotDelete();
      // trackCrudGA("DeleteYes");
    };
    ReduxDispatcher(
      updateDialogInfo({
        ...user.dialogInfo,
        open: true,
        message,
        handleYes,
        handleNo,
      })
    );
  };

  const handlePivotSave = () => {
    // * DEBUGGER
    // console.groupCollapsed("HANDLE SAVE");
    // console.log("pivotstate", pivotstate);
    // console.groupEnd();

    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Pivot",
      action: "SavePivot",
      label: pivotstate.id,
    });
    const chartObject = masterMakeChartObject({
      metadataParams: {
        title: pivotstate.title,
        chartType: "pivotx",
        comparisonMode: pivotstate.timeFilters.isComparisonOn,
      },
      filters: {
        timeFilters: pivotstate.timeFilters,
        metricFilters: pivotstate.metricFilters,
        dimensionFilters: pivotstate.dimensionFilters,
      },
      orderByDetails: {
        orderBy: "desc",
        orderById: pivotstate.metricsList[0]._id,
        orderByType: "id_only",
      },
      dimensionsList: pivotstate.dimensionsList,
      metricsList: pivotstate.metricsList,
      settings: {
        compareFlag: pivotstate.timeFilters.isComparisonOn,
        progressiveDateFlag: pivotstate.progressiveDateFlag,
      },
      granularity: globalFilters?.metricChartGranularity?.value,
    });
    const payload = {
      _id: pivotstate.id,
      emailId: user?.reqMetadata?.email,
      orgViewReq: {
        organization: user?.reqMetadata?.organization,
        view: user?.reqMetadata?.view,
      },
      feature: "pivotx",
      chartObject,
    };
    const newPayload = {
      ...payload,
      chartObject: {
        ...payload.chartObject,
        metadata: {
          ...payload.chartObject.metadata,
          dataLimit: pivotstate.dataLimit,
        },
        requestParam: {
          ...payload.chartObject.requestParam,
          pivotXAdvancedSort: wrapperPivotXAdvancedSort({
            pivotXAdvancedSort: pivotstate.advanceSort,
            allData,
          }),
        },
      },
    };
    const fetchProps = {
      payload: { ...newPayload },
    };
    ReduxDispatcher(updateUserScreen("isDashboardLoading", true));
    const savePivotPromise = saveChart(fetchProps);
    savePivotPromise
      .then((responseData) => {
        // update standaloneRef as well
        originalPivot.current = {
          ...pivotstate,
          elementType: "update",
        };

        // To be done at the last (callback queue)

        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: "Pivot saved successfully!",
        };
        // Update snackbar
        var action = updateUserScreen("snackbar", snackbarPayload);
        ReduxDispatcher(action);
        // Remove dashboard loader
        var action = updateUserScreen("isDashboardLoading", false);
        ReduxDispatcher(action);

        // TODO: Find a better way for breadcrumb to avoid this dispatch
        // Update user.screen.activeChart.elementType to "update"
        var action = updateUserScreen("activePivot", {
          ...pivotstate,
          elementType: "update",
        });
        ReduxDispatcher(action);
        //  To update the save button to update button
        var payload = { key: "elementType", value: "update" };
        var action = updatePivotForm(payload);
        dispatchPivotState(action);
      })
      .catch((json) => {
        console.groupCollapsed("API FAILED");
        console.log("Error JSON -> ", json);
        console.groupEnd();
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: json.error || "Saving chart failed",
        };
        var action = updateUserScreen("snackbar", snackbarPayload);
        ReduxDispatcher(action);
        var action = updateUserScreen("isDashboardLoading", false);
        ReduxDispatcher(action);
      });
  };

  const handleStandalonePivotChange = (value) => {
    // const action = replaceStandaloneChartForm(value);
    // dispatchPivotState(action);
  };
  const handleDimensionFiltersChange = (value) => {
    var payload = { key: "dimensionFilters", value };
    var action = updatePivotForm(payload);
    dispatchPivotState(action);
    var actionDimensionFilters = updateGlobalFiltersDimensionFilters(payload);
    ReduxDispatcher(actionDimensionFilters);
  };
  const handleTimeFiltersChange = (value) => {
    var payload = { key: "timeFilters", value };
    var action = updatePivotForm(payload);
    dispatchPivotState(action);
    var actionTimeFilters = updateGlobalFiltersTimeFilters(payload);
    ReduxDispatcher(actionTimeFilters);
  };
  // Func to check if tere is dateTime in dimensionsList
  const isDayTimeDimension = (pivotState) => {
    let newDimensionList = pivotState.dimensionsList;
    let validDimension = true;
    newDimensionList.find((el) => {
      if (el.dataType === "dateTime") {
        validDimension = false;
      }
    });
    return validDimension;
  };
  const handleBreadcrumbChange = (event, value) => {
    const data = { activeNav: "pivot", activeTab: value };
    const action = updateUserScreen(null, data);
    ReduxDispatcher(action);
    history.push(value.path);
  };
  const TEMP_CSS = { height: "40px" };
  const standaloneChartWrapperCss = { height: "calc(100% - 100px)" };
  const breadcrumbData = getBreadcrumbsDataFromRoute(user, history);
  // Hide the compare if there is dayTime in dimList
  const isComparisonAvailable = isDayTimeDimension(pivotstate);
  // console.log("isComparisonAvailable", isComparisonAvailable);

  const areSelectionsSameAsOriginal = isEqualWithoutCertainKeys(
    originalPivot.current,
    pivotstate,
    "renderFlag"
  ); //Only for update
  const isSaveCloneButtonDisabled =
    pivotstate.dimensionsList.length === 0 ||
    pivotstate.metricsList.length === 0;
  const isUpdateDisabled =
    isSaveCloneButtonDisabled || areSelectionsSameAsOriginal;
  let menuBarSplitButtonOptions = [];

  const isSharedUrlFlag = user.screen.isPivotShared;
  if (pivotstate.elementType !== "update" && isSharedUrlFlag)
    menuBarSplitButtonOptions = [
      {
        id: "clone",
        label: "Clone",
        onClick: handleCloneDialog,
        disabled: isSaveCloneButtonDisabled,
      },
    ];

  if (pivotstate.elementType !== "update" && !isSharedUrlFlag)
    menuBarSplitButtonOptions = [
      {
        id: "save",
        label: "Save",
        onClick: handlePivotSave,
        disabled: isSaveCloneButtonDisabled,
      },
    ];

  if (pivotstate.elementType === "update")
    menuBarSplitButtonOptions = [
      {
        id: "update",
        label: "Update",
        onClick: handlePivotUpdate,
        disabled: isUpdateDisabled,
      },
      {
        id: "clone",
        label: "Clone",
        onClick: handleCloneDialog,
        disabled: isSaveCloneButtonDisabled,
      },
      {
        id: "delete",
        label: "Delete",
        onClick: handleDeleteDialog,
        disabled: false,
      },
    ];
  const renderMenuBarProps = {
    pivotstate,
    dispatchPivotState,
    ReduxDispatcher,
    globalFilters,
    user,
    allData,
    chartName,
    setChartName,
    menuBarSplitButtonOptions,
    //isEdit, // for the edit or update
    onTimeFiltersChange: handleTimeFiltersChange,
    isComparisonAvailable: isComparisonAvailable,
  };
  // console.log("pivotstate", pivotstate);

  const handleAddFilters = () => {
    const handleApplyFilters = (payload, filterType) => {
      switch (filterType) {
        case "dimensions":
          var action = replaceAllDimensionFilters(payload);
          var newDimensionFilters = dimensionFiltersReducer(
            pivotstate.dimensionFilters,
            action
          );
          handleDimensionFiltersChange(newDimensionFilters);
          break;
        // case "metrics":
        //   var action = replaceAllMetricFilters(payload);
        //   dispatchMetricFilters(action);
        //   break;
        // case "time":
        //   onTimeFiltersChange(payload);
        //   break;
      }
    };

    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "GlobalFilters",
      action: "Open Filters",
      label: "Sidenav",
    });
    const commonGlobalFilterProps = {
      isOpen: true, //global filters dialog open close
      showTimeFilters: false,
      showMetricFilters: false,
      activeFilterType: "dimensions",
      isAdFiltersOpen: false, //advanced filters dialog open close
      timeFilters: pivotstate.timeFilters, //for initializing filters
      dimensionFilters: pivotstate.dimensionFilters, //for initializing filters
      metricFilters: [], //for initializing filters
      // settings: dimensionFiltersSettings,
      filtersDimData: allData.plotlyDimensions,
      filtersMetricsData: allData.plotlyMetrics,
      selections: {},
    };
    //Open Global Filters
    const newGlobalFiltersProps = {
      ...commonGlobalFilterProps,
      activeDimensionFilter: {},
      handleApplyFilters, //It will take 2 parameters, payload and filterType (dimensions or metric or time); We have 2 separate actions for both
    };

    const action = updateUserScreen("globalFilters", newGlobalFiltersProps);
    ReduxDispatcher(action);
  };
  const handleGoHome = (user) => {
    history.push("/pivotx");
  };

  const handleShare = () => {
    // Google Analytics Event - Master
    // masterTrackGaEvent({
    //   category: "Share",
    //   action: "ShareURLOpenInSidenav",
    //   label: "OpenShare",
    // });
    const newSelections = {
      ...pivotstate,
      dataLimit: pivotstate.dataLimit,
      orderBy: "desc",
      orderById: pivotstate.metricsList[0]?._id,
    };
    const props = { selections: newSelections, user };
    const getDataPayload = wrapperChartObject(props);

    const newPayload = {
      ...getDataPayload,
      _id: pivotstate.id,
      chartObject: {
        ...getDataPayload.chartObject,
        metadata: {
          ...getDataPayload.chartObject.metadata,
          chartType: "pivotx",
        },
        requestParam: {
          ...getDataPayload.chartObject.requestParam,
          pivotXAdvancedSort: wrapperPivotXAdvancedSort({
            pivotXAdvancedSort: pivotstate.advanceSort,
            allData,
          }),
        },
      },
    };

    // console.log("newPayload", newPayload);
    const actionPayload = {
      open: true,
      children: (
        <AnalyzeShareDialog
          user={user}
          ReduxDispatcher={ReduxDispatcher}
          payload={newPayload.chartObject}
          type="pivot"
        />
      ),
    };
    const action = updateUserScreen("sigviewDialog", actionPayload);
    ReduxDispatcher(action);
  };
  const isShare =
    pivotstate.dimensionsList.length > 0 && pivotstate.metricsList.length > 0
      ? true
      : false;
  const additionalSidenavItems = [
    {
      id: "home",
      name: "Home",
      tooltip: "Go back to home",
      elementId: "dsBackToHome",
      iconId: "custom-home-icon",
      customClass: "sidenav-home",
      onClick: (user) => handleGoHome(user),
      isVisible: true,
    },
    {
      id: "filters",
      name: "Filters",
      tooltip: "Open filters",
      elementId: "filtersInSidenav",
      iconId: "custom-filter-icon",
      customClass: "sidenav-filters",
      onClick: (user) => handleAddFilters(),
      isVisible: true,
    },
    {
      id: "share",
      name: "Share",
      tooltip: "Share",
      // elementId: "wsShareInSidenav",
      iconId: "custom-share-icon",
      customClass: "sidenav-share",
      onClick: () => {
        handleShare();
      },
      isVisible: isShare,
    },
  ];
  const sidenavItemsOrderInfo = {
    home: 1,
    filters: 2,
    reports: 3,
    alerts: 4,
  };
  const tabName = "pivot-create";
  const sidenavProps = {
    additionalSidenavItems,
    sidenavItemsOrderInfo,
    tabName,
  };

  const isAnyDimFiltersApplied = pivotstate.dimensionFilters.length > 0;

  return (
    <LayoutTopSideBottom sidenavProps={sidenavProps}>
      <SigviewBreadcrumb
        data={breadcrumbData}
        onClick={handleBreadcrumbChange}
      />
      <Box css={TEMP_CSS}>
        <PlotMenuBar {...renderMenuBarProps} />
      </Box>
      {/* OLD IMPLEMENTATION OF DIMENSION FILTER */}
      {/* <Box css={TEMP_CSS}>
        <DimensionFilters
          initialDimensionFilters={pivotstate.dimensionFilters}
          onDimensionFiltersChange={handleDimensionFiltersChange}
          initialTimeFilters={pivotstate.timeFilters}
        />
      </Box> */}
      <Box css={{ padding: "0px 22px" }}>
        {isAnyDimFiltersApplied ? (
          <DimensionFilters
            initialDimensionFilters={pivotstate.dimensionFilters}
            initialTimeFilters={pivotstate.timeFilters}
            onDimensionFiltersChange={handleDimensionFiltersChange}
            // settings={dimensionFiltersSettings}
          />
        ) : (
          <></> // ADDING THIS BECAUSE LAYOUT NEEDS SOMETHING IN THAT INDEX
        )}
      </Box>
      <Box css={standaloneChartWrapperCss}>
        <StandalonePivot
          pivotState={pivotstate}
          dispatchPivotState={dispatchPivotState}
          onChange={handleStandalonePivotChange}
          updateParentState={true}
        />
      </Box>
    </LayoutTopSideBottom>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  globalFilters: state.globalFilters,
});

export default connect(mapStateToProps)(PivotCreate);
