// Import required libraies
import React, { useState, useContext } from "react";
import { v4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";

// * Import 3rd party lib comp
import { Box } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";

// Import Custom Component
import Loader from "../../../components/Loader/Loader";
import TimeFilters from "../../../components/TimeFilters/TimeFilters";
import SigviewButtonSplit from "../../../components/Common/SigviewButtonSplit";
import SigviewTextFieldAsync from "../../../components/Common/SigviewTextFieldAsync";
import SigviewTextField from "../../../components/Common/SigviewTextField";
import SigviewButton from "../../../components/Common/SigviewButton";
import SigviewSimpleText from "../../../components/Common/SigviewSimpleText";
import SigviewIcon from "../../../components/Common/SigviewIcon";
import SigviewTypography from "../../../components/Common/SigviewTypography";
import SigviewRadioGroup from "../../../components/Common/SigviewRadioGroup";
import SigviewSingleSelect from "../../../components/Common/SigviewSingleSelect";
import SigviewTooltip from "../../../components/Common/SigviewTooltip";

// * Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

// Import action creators
import {
  updateUserScreen,
  updatePivotMultipleFields,
} from "../../../redux/actions";

// Import utils/data
import { masterMakeChartObject } from "../../../utils/utils";
import {
  validateChartName,
  wrapperPivotXAdvancedSort,
} from "../../../utils/chartObjectUtils";
import { masterTrackGaEvent } from "../../../services/ga";

// Import API functions
import { renameChart, saveChart, chartNameCheck } from "../../../services/api";
import { useHistory } from "react-router";

const PlotMenuBar = (props) => {
  const {
    pivotstate,
    dispatchPivotState,
    ReduxDispatcher,
    user,
    allData,
    chartName,
    setChartName,
    menuBarSplitButtonOptions,
    isEdit = false,
    onTimeFiltersChange = () => {},
    isComparisonAvailable = true,
  } = props;

  // * Define required hooks
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  // Making required variables
  const gaCategory = isEdit ? "EditChart" : "CreateChart";

  //Making requried event handlers
  const handleChartNameChange = (event, value) => {
    // console.log("handleChartNameChange", value);
    setChartName((prevState) => ({ ...prevState, value }));
  };

  const handleClickAway = () => {
    // Google Analytics Event - Master
    // masterTrackGaEvent({
    //   category: gaCategory,
    //   action: "Rename",
    //   label: "Chart",
    // });

    const activeChart = { ...pivotstate };
    // Making required active variables
    //If entered chartName is invalid
    const { status, message } = validateChartName(chartName.value);
    if (status === "invalid") {
      setChartName((prevState) => ({
        ...prevState,
        status: "success",
        value: prevState.originalValue,
      }));
      let snackbarPayload = {
        ...user.screen.snackbar,
        open: true,
        message: message,
      };
      const action = updateUserScreen("snackbar", snackbarPayload);
      ReduxDispatcher(action);
    } else {
      // Do this only when the names are different otherwise
      // it will run for every click on the screen
      if (chartName.originalValue !== chartName.value) {
        // Make an API call only when the element type is update
        if (activeChart.elementType === "update") {
          setChartName((prevState) => ({ ...prevState, status: "loading" }));
          const fetchPayload = {
            orgViewReq: {
              organization: user?.reqMetadata?.organization,
              view: user?.reqMetadata?.view,
            },
            id: activeChart.id,
            title: chartName.value,
          };
          const fetchProps = {
            payload: fetchPayload,
          };
          const renameChartPromise = renameChart(fetchProps);
          renameChartPromise
            .then((responseData) => {
              setChartName((prevState) => ({
                ...prevState,
                status: "success",
                originalValue: prevState.value,
              }));
              // Commenting it out as it's distracting
              // let snackbarPayload = {
              //   ...user.screen.snackbar,
              //   open: true,
              //   message: "Chart renamed successfully!",
              // };
              // const action = updateUserScreen("snackbar", snackbarPayload);
              // ReduxDispatcher(action);
            })
            .catch((json) => {
              console.groupCollapsed("API FAILED");
              console.log("Error JSON -> ", json);
              console.groupEnd();
              setChartName((prevState) => ({
                ...prevState,
                status: "success",
                value: prevState.originalValue,
              }));
              let snackbarPayload = {
                ...user.screen.snackbar,
                open: true,
                message: json.error || "Rename Failed",
              };
              const action = updateUserScreen("snackbar", snackbarPayload);
              ReduxDispatcher(action);
            });
        } else if (activeChart.elementType === "create") {
          // Call name-check API if the element type is create
          setChartName((prevState) => ({ ...prevState, status: "loading" }));
          const fetchPayload = {
            orgViewReq: {
              organization: user?.reqMetadata?.organization,
              view: user?.reqMetadata?.view,
            },
            name: chartName.value,
            emailId: user?.reqMetadata?.email,
          };
          const fetchProps = {
            payload: fetchPayload,
          };
          const chartNameCheckPromise = chartNameCheck(fetchProps);
          chartNameCheckPromise
            .then((responseData) => {
              const isChartTitleExist = responseData.result.data;
              if (isChartTitleExist) {
                throw new Error();
              } else {
                setChartName((prevState) => ({
                  ...prevState,
                  status: "success",
                  originalValue: prevState.value,
                }));
              }
            })
            .catch((json) => {
              console.groupCollapsed("API FAILED");
              console.log("Error JSON -> ", json);
              console.groupEnd();
              setChartName((prevState) => ({
                ...prevState,
                status: "success",
                value: prevState.originalValue,
              }));
              let snackbarPayload = {
                ...user.screen.snackbar,
                open: true,
                message: json.error || "Chart name already exists",
              };
              const action = updateUserScreen("snackbar", snackbarPayload);
              ReduxDispatcher(action);
            });
        } else {
          // Don't make the API call if the element type is not update
          setChartName((prevState) => ({
            ...prevState,
            status: "success",
            originalValue: prevState.value,
          }));
        }
      }
    }
  };

  const DsSettingsIcon = (props = {}) => {
    // * Define required states
    const initialSelections = {
      dataLimit: pivotstate.dataLimit,
      progressiveDateFlag: pivotstate.progressiveDateFlag ? "yes" : "no",
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const [settingsSelections, setSettingsSelections] =
      useState(initialSelections);

    // * Define required event handlers
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
      const initialSelections = {
        dataLimit: pivotstate.dataLimit,
        progressiveDateFlag: pivotstate.progressiveDateFlag ? "yes" : "no",
      };
      setSettingsSelections(initialSelections);
    };
    const handleDsSettingsChange = (key, value) => {
      setSettingsSelections((prevState) => ({ ...prevState, [key]: value }));
    };
    const handleCancel = () => handleClose();
    const handleSave = () => {
      const payload = {
        value: {
          dataLimit: settingsSelections.dataLimit,
          progressiveDateFlag: settingsSelections.progressiveDateFlag === "yes",
        },
      };
      const action = updatePivotMultipleFields(payload);
      dispatchPivotState(action);
    };

    // * Define required variables
    const open = Boolean(anchorEl);
    const id = open ? "dsSettingsPopover" : undefined;
    const useStyles = makeStyles({
      root: {
        "& .MuiPaper-root ": {
          backgroundColor: "transparent !important",
        },
      },
    });
    const classes = useStyles();
    const containerCss = {
      backgroundColor: themeColors["mainContentBgColor"],
      padding: "10px 15px",
    };
    const footerCss = {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    };
    const formRowCss = {
      marginBottom: "5px",
      display: "flex",
      alignItems: "center",
    };
    const dataLimitData = [
      {
        id: 10,
        name: 10,
        disabled: false,
      },
      {
        id: 25,
        name: 25,
        disabled: false,
      },
      {
        id: 50,
        name: 50,
        disabled: false,
      },
      {
        id: 100,
        name: 100,
        disabled: false,
      },
    ];
    const rollingDatesData = [
      {
        id: "yes",
        name: "Yes",
        disabled: false,
      },
      {
        id: "no",
        name: "No",
        disabled: false,
      },
    ];

    return (
      <>
        <SigviewTooltip title="Edit Settings" placement="bottom">
          <Box>
            <SigviewIcon
              className="material-icons-round"
              iconName="settings"
              style={{
                fontSize: "22px !important",
                padding: "0px 10px 0px 0px",
                color: themeColors["secondaryColorLight"],
                hoverColor: themeColors["primaryColor"],
                cursor: "pointer",
              }}
              onClick={handleClick}
            />
          </Box>
        </SigviewTooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          className={classes.root}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box css={containerCss}>
            <Box>
              <SigviewTypography
                variant="pLarge"
                style={{ color: themeColors["primaryColor"] }}
              >
                Settings
              </SigviewTypography>
            </Box>
            <Box>
              <Box css={formRowCss}>
                <SigviewTypography
                  variant="pSmallMedium"
                  style={{ height: "max-content", width: "100px" }}
                >
                  Row Count:
                </SigviewTypography>
                <SigviewSingleSelect
                  minWidth={"50px"}
                  maxWidth={"50px"}
                  value={settingsSelections.dataLimit}
                  data={dataLimitData}
                  onChange={(newValue) =>
                    handleDsSettingsChange("dataLimit", newValue)
                  }
                  margin={{ left: "5px" }}
                  customClassName="PivotTableRowCount-GA"
                />
              </Box>
              <Box css={formRowCss}>
                <SigviewTypography
                  variant="pSmallMedium"
                  style={{ height: "max-content", width: "100px" }}
                >
                  Rolling Dates:
                </SigviewTypography>
                <SigviewRadioGroup
                  data={rollingDatesData}
                  customClassName={`PivotRollingDateStatus${settingsSelections.progressiveDateFlag}-GA`}
                  value={settingsSelections.progressiveDateFlag}
                  onChange={(newValue) =>
                    handleDsSettingsChange("progressiveDateFlag", newValue)
                  }
                />
              </Box>
            </Box>
            <Box css={footerCss}>
              <SigviewButton
                variant="outlined"
                onClick={handleCancel}
                title="Discard"
                style={{ width: "80px" }}
                customClassName="PivotCancel-GA"
              />
              <SigviewButton
                variant="contained"
                onClick={handleSave}
                title="Save"
                style={{ width: "80px", margin: { left: "10px" } }}
                customClassName="PivotSave-GA"
              />
            </Box>
          </Box>
        </Popover>
      </>
    );
  };

  //Making required variables
  const calendarDaysLimits = user.uiLimitsList.daysLimitCalendarDashboard;
  const timeFiltersSettings = {
    compareCalendarRowLimit: 1, //hard coded; it will come from /getUserAccessList
    isMultiCompareRowAllowed: false, //hard coded; it will come from /getUserAccessList
    isComparisonAvailable: isComparisonAvailable, //hard coded; it should come from where this component is being called
    isChangeTypeDropdownAvailable: false,
    isApplyButtonAvailable: false,
  };
  const textFieldCustomStyle = {
    wrapperWidth: "100%",
  };

  return (
    <div className={`menu-bar-container`}>
      <div className={`menu-bar-left-panel`}>
        {/* Wrapping in a div only for tour */}
        <div className="menu-bar-basics">
          {/* <SigviewTooltip title={chartTitle} top="-12px">
              <span className={`menu-bar-title`}>{chartTitle}</span>
            </SigviewTooltip> */}
          <SigviewTextFieldAsync
            value={chartName.value}
            onChange={handleChartNameChange}
            status={chartName.status}
            onClickAway={handleClickAway}
            customStyle={textFieldCustomStyle}
            tooltipTitle="Type and press ENTER to rename"
          />
        </div>
      </div>
      <div className={`menu-bar-center-panel`}>
        <div id="dsMenuBar">
          {/* {pivotstate?.timeFilters?.isLoading ? (
            <Loader />
          ) : ( */}
          <div id="menuBarTimeFiltersContainer">
            <TimeFilters
              user={user}
              allData={allData}
              initialTimeFilters={pivotstate?.timeFilters}
              onChange={onTimeFiltersChange}
              // googleAnalytics={{ category: gaCategory }}
              calendarDaysLimits={calendarDaysLimits}
              settings={timeFiltersSettings}
            />
          </div>
          {/* // )} */}
        </div>
      </div>
      <div className={`menu-bar-right-panel`}>
        <div className="ds-menu-button-container">
          <DsSettingsIcon />
          {menuBarSplitButtonOptions.length > 1 && (
            <SigviewButtonSplit
              options={menuBarSplitButtonOptions}
              customClassName="Pivot-GA"
            />
          )}
          {menuBarSplitButtonOptions.length === 1 && (
            <SigviewButton
              id={menuBarSplitButtonOptions[0]["id"]}
              title={menuBarSplitButtonOptions[0]["label"]}
              disabled={menuBarSplitButtonOptions[0]["disabled"]}
              onClick={menuBarSplitButtonOptions[0]["onClick"]}
              customClassName="Pivot-GA"
            />
          )}
        </div>
      </div>
    </div>
  );
};

const SigviewDialogPivotClone = (props) => {
  const {
    user,
    ReduxDispatcher,
    initialName,
    pivotstate,
    isEdit = false,
    allData = {},
  } = props;
  // Defining required state
  const [name, setName] = useState({
    value: initialName,
    status: "success", // success/error/loading
    message: "",
  });
  const history = useHistory();

  // Making required variables
  // const gaCategory = isEdit ? "EditChart" : "CreateChart";

  // Defining required event handlers
  const handleNameChange = (newValue) => {
    setName(() => {
      const { status, message } = validateChartName(newValue);
      let finalStatus = "success";
      if (status === "invalid") finalStatus = "error";
      const newState = { status: finalStatus, message, value: newValue };
      return newState;
    });
  };

  const handleCancel = () => {
    const payload = { open: false, children: null };
    const action = updateUserScreen("sigviewDialog", payload);
    ReduxDispatcher(action);
  };

  const handleError = (message) => {
    setName((prevState) => ({
      ...prevState,
      status: "error",
      message: message,
    }));
  };
  const handleClone = (type = "clone") => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Pivot",
      action: "ClonePivot",
      label: type,
    });

    const fetchPayload = {
      orgViewReq: {
        organization: user?.reqMetadata?.organization,
        view: user?.reqMetadata?.view,
      },
      name: name.value,
      emailId: user?.reqMetadata?.email,
    };
    const fetchProps = {
      payload: fetchPayload,
    };
    const chartNameCheckPromise = chartNameCheck(fetchProps);
    chartNameCheckPromise
      .then((responseData) => {
        const isChartTitleExist = responseData.result.data;
        if (!isChartTitleExist) {
          const newChartId = `pivot-object-${v4()}`;
          const activeChart = { ...pivotstate };
          const chartObject = masterMakeChartObject({
            metadataParams: {
              title: name.value,
              chartType: "pivotx",
              comparisonMode: pivotstate.timeFilters.isComparisonOn,
            },
            filters: {
              timeFilters: pivotstate.timeFilters,
              metricFilters: pivotstate.metricFilters,
              dimensionFilters: pivotstate.dimensionFilters,
            },
            orderByDetails: {
              // orderById: pivotstate.orderById,
              // orderBy: pivotstate.orderBy,
              orderBy: "desc",
              orderById: pivotstate.metricsList[0]._id,
              orderByType: "id_only",
            },
            dimensionsList: pivotstate.dimensionsList,
            metricsList: pivotstate.metricsList,
            settings: {
              compareFlag: pivotstate.timeFilters.isComparisonOn,
              progressiveDateFlag: pivotstate.progressiveDateFlag,
            },
          });

          const payload = {
            _id: newChartId,
            emailId: user?.reqMetadata?.email,
            orgViewReq: {
              organization: user?.reqMetadata?.organization,
              view: user?.reqMetadata?.view,
            },
            chartObject,
          };
          const newPayload = {
            ...payload,
            chartObject: {
              ...payload.chartObject,
              requestParam: {
                ...payload.chartObject.requestParam,

                pivotXAdvancedSort: wrapperPivotXAdvancedSort({
                  pivotXAdvancedSort: pivotstate.advanceSort,
                  allData,
                }),
              },
            },
          };
          const fetchProps = {
            payload: { ...newPayload },
          };
          // console.log("finalPayload", newPayload);
          setName((prevState) => ({ ...prevState, status: "loading" }));
          const savePivotPromise = saveChart(fetchProps);
          savePivotPromise
            .then((responseData) => {
              // Update local status to success
              setName((prevState) => ({ ...prevState, status: "success" }));
              // Close the dialog
              handleCancel();
              // Update snackbar
              const snackbarPayload = {
                ...user.screen.snackbar,
                open: true,
                message: "Pivot cloned successfully!",
              };
              var action = updateUserScreen("snackbar", snackbarPayload);
              ReduxDispatcher(action);
              // If type is cloneLaunch, update activeChart
              if (type === "cloneLaunch") {
                const newActivePivot = {
                  ...activeChart,
                  id: newChartId,
                  title: name.value,
                };
                // var action = updateUserScreen("activePivot", newActivePivot);

                const value = {
                  activeTab: "createPivot",
                  activePivot: newActivePivot,
                };
                var action = updateUserScreen(null, value);
                ReduxDispatcher(action);
              }
            })
            .catch((json) => {
              console.groupCollapsed("API FAILED");
              console.log("Error JSON -> ", json);
              console.groupEnd();
              const message = json.error || "Cloning pivot failed";
              // Update local status to error
              setName((prevState) => ({
                ...prevState,
                status: "error",
                message,
              }));
            });
        } else {
          handleError("Pivot name already exists");
        }
      })
      .catch((json) => {
        console.groupCollapsed("API FAILED");
        console.log("Error JSON -> ", json);
        console.groupEnd();
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: json.error || "Pivot name already exists",
        };
        const action = updateUserScreen("snackbar", snackbarPayload);
        ReduxDispatcher(action);
      });
  };

  // Defining required variables
  const errorFlag = name.status === "error";
  const helperText = name.message;
  const cloneDisabled = ["error", "loading"].includes(name.status);
  const isLoading = name.status === "loading";
  const cloneTitle = isLoading ? <Loader /> : "Clone";
  const cloneLaunchTitle = isLoading ? <Loader /> : "Clone & Launch";

  return (
    <section className="sigview-dialog-chart-clone">
      <SigviewSimpleText
        value="Enter pivot name"
        style={{
          border: "none",
          fontSize: "16px",
          margin: "0px 0px 15px 0px",
          padding: "0px",
        }}
      />
      <SigviewTextField
        placeholder="Please enter a valid chart name..."
        value={name.value}
        onChange={(newValue) => handleNameChange(newValue)}
        error={errorFlag}
        helperText={helperText}
        minWidth="400px"
        maxWidth="400px"
        height="40px"
        margin={{ right: "5px", bottom: "10px" }}
      />
      <article className="sigview-dialog-chart-clone-menu-bar">
        <SigviewButton
          variant="outlined"
          onClick={handleCancel}
          title="Cancel"
          style={{ width: "auto", margin: { left: "3px", right: "3px" } }}
        />
        <SigviewButton
          variant="contained"
          onClick={() => handleClone("clone")}
          disabled={cloneDisabled}
          customClassName="PivotClone-GA"
          title={cloneTitle}
          style={{ width: "auto", margin: { left: "3px", right: "3px" } }}
        />
        {!isLoading && (
          <SigviewButton
            variant="contained"
            onClick={() => handleClone("cloneLaunch")}
            disabled={cloneDisabled}
            customClassName="PivotCloneLaunch-GA"
            title={cloneLaunchTitle}
            style={{
              width: "max-content",
              margin: { left: "3px", right: "3px" },
            }}
          />
        )}
      </article>
    </section>
  );
};

export { PlotMenuBar, SigviewDialogPivotClone };
