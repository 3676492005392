// Import required libraries
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import axios from "axios";
import { v4 } from "uuid";
import orderBy from "lodash/orderBy";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";

// Import custom components
import ReportManager from "./ReportManager";
import SigviewButton from "../Common/SigviewButton";
import Loader from "../Loader/Loader";
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import SigviewSwitch from "../Common/SigviewSwitch";
import SigviewProgressBar from "../Common/SigviewProgressBar";
import SigviewNav from "../Common/SigviewNav";
import SigviewNavType2 from "../Common/SigviewNavType2";
import SigviewTooltip from "../Common/SigviewTooltip";

// Import styles
import "./ReportManager.scss";

// Import config
import { config } from "../../config/config";

// Import action creators and reducers
import { updateUserScreen, updateDialogInfo } from "../../redux/actions";

// Import utils
import {
  timeSinceCreation,
  capitalize,
  getSigviewUserType,
  isShareReportValidBasedOnUserAccess,
} from "../../utils/utils";
import {
  makeSubtitleForScheduledReport,
  getSelectedReportPayload,
  unwrapperBucketListInReport,
  unwrapperSavedReport,
  unwrapperScheduledReport,
} from "../../utils/reportUtils";
import { masterTrackGaEvent } from "../../services/ga";
import { initialUserReportManager } from "../../redux/stateData";

// Import data
import triggerFrequencyArr from "../../../assets/data/triggerFrequencyArr.json";

// Import api functions
import {
  getAllScheduledReports,
  getAllSavedReports,
  getReportsDownloadHistory,
  updateScheduledReport,
  fetchBucketList,
  getBookmark,
} from "../../services/api";
import { makeAttributeMissingErrorMessage } from "../../utils/analyzeUtils";
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";

const reportTypesArr = [
  { id: "saved", name: "Saved Reports", disabled: false },
  { id: "scheduled", name: "Scheduled Reports", disabled: false },
  // { id: "download", name: "Download History" },
];

const downloadsNavArr = [
  { id: "downloads", name: "Download History", disabled: false },
];

const initialData = { status: "loading", message: "", data: [] };

function ReportManagerContainer(props) {
  const { t } = useTranslation();
  const {
    dispatch: ReduxDispatcher,
    user,
    allData,
    globalFilters = {},
  } = props;

  const sigviewUserType = getSigviewUserType();

  const history = useHistory();
  //Defining required states
  const [scheduledReports, setScheduledReports] = useState(initialData);
  const [savedReports, setSavedReports] = useState(initialData);
  const [reportsDownloadHistory, setReportsDownloadHistory] =
    useState(initialData);
  const [reloadSavedReports, setReloadSavedReports] = useState(false);
  const [reloadScheduledReports, setReloadScheduledReports] = useState(false);
  const [reloadReportsDownloadHistory, setReloadReportsDownloadHistory] =
    useState(false);
  const [tourRun, setTourRun] = useState(false);
  const [activeReportType, setActiveReportType] = useState("saved");
  const [activeReport, setActiveReport] = useState({
    type: "none",
    id: "",
    variant: "normal",
    selections: {},
  });

  const [bucketList, setBucketList] = useState({
    result: [],
    status: "initial",
    message: "",
  });

  const [isSharedReport, setIsSharedReport] = useState(false);
  const [sharedReportData, setSharedReportData] = useState({});
  const [reportSheet, setReportSheet] = useState(initialData);
  const [showReportManager, setShowReportManager] = useState(false); //mount and unmount report manager everytime user changes active report
  // To get the maxSavedReport
  const maxSavedReports = user.uiLimitsList.maxSavedReports;
  // To get the maxScheduledReports
  const maxScheduledReports = user.uiLimitsList.maxScheduledReports;

  //Defining required variables
  const reportsDownloadHistoryTransformed = {
    status: reportsDownloadHistory.status,
    message: reportsDownloadHistory.message,
    //1) We are first removing the invalid reports
    //2) We are then transforming them for sidenav
    //3) We are ordering by created data desc
    data: orderBy(
      reportsDownloadHistory.data
        .filter(
          (row) =>
            getSelectedReportPayload({
              activeReport: {
                type: "download",
                id: row.id,
              },
              allReports: {
                scheduledReports: scheduledReports.data,
                savedReports: savedReports.data,
                reportsDownloadHistory: reportsDownloadHistory.data,
              },
              allData,
              user,
              globalFilters,
            }).valid
        )
        .map((row) => ({
          id: row.id,
          title: row.name,
          subtitle: `${t("Downloaded")} ${timeSinceCreation(row.createdOn)}`,
          payload: row,
          isCurrentlySelected: activeReport.id === row.id,
          createdOn: parseInt(row.createdOn),
        })),
      (row) => row.createdOn,
      ["desc"]
    ),
  };
  const savedReportsTransformed = {
    status: savedReports.status,
    message: savedReports.message,
    data: orderBy(
      savedReports.data
        .filter(
          (row) =>
            getSelectedReportPayload({
              activeReport: {
                type: "saved",
                id: row.recentDownloadRecord.id,
              },
              allReports: {
                scheduledReports: scheduledReports.data,
                savedReports: savedReports.data,
                reportsDownloadHistory: reportsDownloadHistory.data,
              },
              allData,
              user,
              globalFilters,
            }).valid
        )
        .map((row) => ({
          id: row.recentDownloadRecord.id,
          title: row.recentDownloadRecord.name,
          subtitle: `${t("Created")} ${timeSinceCreation(
            row.recentDownloadRecord.createdOn
          )}`,
          payload: row,
          isCurrentlySelected: activeReport.id === row.recentDownloadRecord.id,
          createdOn: parseInt(row.recentDownloadRecord.createdOn),
        })),
      (row) => row.createdOn,
      ["desc"]
    ),
  };
  const scheduledReportsTransformed = {
    status: scheduledReports.status,
    message: scheduledReports.message,
    data: orderBy(
      scheduledReports.data
        .filter(
          (row) =>
            getSelectedReportPayload({
              activeReport: {
                type: "scheduled",
                id: row._id,
              },
              allReports: {
                scheduledReports: scheduledReports.data,
                savedReports: savedReports.data,
                reportsDownloadHistory: reportsDownloadHistory.data,
              },
              allData,
              user,
              globalFilters,
            }).valid
        )
        .map((row) => ({
          id: row._id,
          title: row.reportName,
          subtitle: makeSubtitleForScheduledReport(row, triggerFrequencyArr),
          isActive: row.reportStatus === "active",
          payload: row,
          isCurrentlySelected: activeReport.id === row._id,
          createdOn: parseInt(row.createdOn),
        })),
      (row) => row.createdOn,
      ["desc"]
    ),
  };
  const reportsSidenavData =
    activeReportType === "saved"
      ? { ...savedReportsTransformed }
      : activeReportType === "scheduled"
      ? { ...scheduledReportsTransformed }
      : { ...reportsDownloadHistoryTransformed };
  const activeReportFormData = getSelectedReportPayload({
    activeReport,
    allReports: {
      scheduledReports: scheduledReports.data,
      savedReports: savedReports.data,
      reportsDownloadHistory: reportsDownloadHistory.data,
    },
    allData,
    user,
    globalFilters,
  });
  const isDownloadAvailableClassName = user.uiFeatureList.download
    ? ""
    : "download-unavailable";

  const reportOutsideClickClose = (event) => {
    if (
      event?.target?.className?.includes("report-manager-container-overlay")
    ) {
      //Close the report manager
      ReduxDispatcher(
        updateUserScreen("reportManager", initialUserReportManager)
      );
    }
  };

  //Defining utility functions
  const handleReportManagerClose = () => {
    //Check if there's a change in report manager values
    //If yes, show the dialog, else close report manager
    //TODO
    // const flag = !isEqual(
    //   globalFiltersStoreFiltered,
    //   globalFiltersOriginal.current
    // );
    const flag = activeReport.type !== "none";
    if (flag) {
      const message = {
        title: t(config.dialogInfo.filtersClose.title),
        subtitle: t(config.dialogInfo.filtersClose.subtitle),
      };
      const handleNo = () => {
        //Close the dialog box
        ReduxDispatcher(
          updateDialogInfo({
            ...user.dialogInfo,
            open: false,
          })
        );
      };
      const handleYes = () => {
        //Close the dialog box
        ReduxDispatcher(
          updateDialogInfo({
            ...user.dialogInfo,
            open: false,
            message: { title: "", subtitle: "" },
          })
        );
        //Close the report manager
        ReduxDispatcher(
          updateUserScreen("reportManager", initialUserReportManager)
        );
      };
      ReduxDispatcher(
        updateDialogInfo({
          ...user.dialogInfo,
          open: true,
          message,
          handleYes,
          handleNo,
        })
      );
    } else {
      //Close the report manager
      ReduxDispatcher(
        updateUserScreen("reportManager", initialUserReportManager)
      );
    }
  };

  const updateParentScheduledReportsOnToggle = (id, payload) => {
    setScheduledReports((prevState) => ({
      ...prevState,
      data: scheduledReports.data.map((row) =>
        row._id !== id ? row : payload
      ),
    }));
  };

  const handleReportRowClick = ({ type, id }) => {
    setIsSharedReport(false);
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "ReportManager",
      action: "OpenReport",
      label: capitalize(type),
    });
    //Update active report only when a different report is clicked otherwise the form will reset
    if (id !== activeReport.id) {
      setActiveReport({ type, id });
    }
  };

  const handleAddNewReport = (gaLabel) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "ReportManager",
      action: "AddNewReport",
      label: gaLabel,
    });
    setIsSharedReport(false);
    setActiveReport({ type: activeReportType, id: `report-${v4()}` });
  };

  const handleReportNavClick = (type) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "ReportManager",
      action: "SwitchTab",
      label: capitalize(type),
    });
    setActiveReportType(type);
  };

  const reloadData = (reportType) => {
    if (reportType === "saved") {
      setReloadSavedReports((prevState) => !prevState);
      setActiveReportType("saved");
    } else if (reportType === "scheduled") {
      setReloadScheduledReports((prevState) => !prevState);
      setActiveReportType("scheduled");
    } else if (reportType === "download") {
      setReloadReportsDownloadHistory((prevState) => !prevState);
    }
  };

  //Defining required useEffects
  //Scheduled Reports
  useEffect(() => {
    //Make fetch call using axios
    const source = axios.CancelToken.source();
    const fetchProps = {
      cancelToken: source.token,
    };
    const getAllScheduledReportsPromise = getAllScheduledReports(fetchProps);
    getAllScheduledReportsPromise
      .then((responseData) => {
        let newData = {
          status: "success",
          message:
            responseData?.result?.data?.length || [].length !== 0
              ? ""
              : "No scheduled reports to display",
          data: responseData?.result?.data || [],
        };
        setScheduledReports(newData);
      })
      .catch((json) => {
        if (json.error !== config.hardCoded.queryCancelled) {
          let newData = { status: "error", message: json.error, data: [] };
          setScheduledReports(newData);
          console.groupCollapsed("UI ERROR");
          console.log("ERROR ->", json.error);
          console.groupEnd();
        }
      });

    if (sigviewUserType === "nonSigview") {
      const fetchBucketListPromise = fetchBucketList({});
      fetchBucketListPromise
        .then((data) => {
          let newData = {
            result: data.result.data,
            status: "success",
            messsage: "",
          };
          setBucketList(newData);
        })
        .catch((json) => {
          let newData = {
            result: [],
            status: "error",
            messsage: "Fetch bucketList API failed",
          };
          setBucketList(newData);
        });
    }

    return () => {
      setScheduledReports(initialData);
      //Cancel all previous fetch calls
      if (source) source.cancel();
    };
  }, [reloadScheduledReports]);

  //Saved Reports
  useEffect(() => {
    //Make fetch call using axios
    const source = axios.CancelToken.source();
    const fetchProps = {
      cancelToken: source.token,
    };
    const getAllSavedReportsPromise = getAllSavedReports(fetchProps);
    getAllSavedReportsPromise
      .then((responseData) => {
        let newData = {
          status: "success",
          message:
            responseData?.result?.data?.length || [].length !== 0
              ? ""
              : "No saved reports to display",
          data: responseData?.result?.data || [],
        };
        setSavedReports(newData);
      })
      .catch((json) => {
        if (json.error !== config.hardCoded.queryCancelled) {
          let newData = { status: "error", message: json.error, data: [] };
          setSavedReports(newData);
          console.groupCollapsed("UI ERROR");
          console.log("ERROR ->", json.error);
          console.groupEnd();
        }
      });

    return () => {
      setSavedReports(initialData);
      //Cancel all previous fetch calls
      if (source) source.cancel();
    };
  }, [reloadSavedReports]);

  //Reports Download History
  useEffect(() => {
    //Make fetch call using axios
    const source = axios.CancelToken.source();
    const fetchProps = {
      cancelToken: source.token,
    };
    const getReportsDownloadHistoryPromise =
      getReportsDownloadHistory(fetchProps);
    getReportsDownloadHistoryPromise
      .then((responseData) => {
        var dataFinal;
        if (sigviewUserType === "sigview") {
          dataFinal = responseData?.result?.data || [];
        } else {
          dataFinal = responseData?.result?.data || [];
        }
        let newData = {
          status: "success",
          message: dataFinal.length !== 0 ? "" : "No downloads",
          data: dataFinal || [],
        };
        setReportsDownloadHistory(newData);
      })
      .catch((json) => {
        if (json.error !== config.hardCoded.queryCancelled) {
          let newData = { status: "error", message: json.error, data: [] };
          setReportsDownloadHistory(newData);
          console.groupCollapsed("UI ERROR");
          console.log("ERROR ->", json.error);
          console.groupEnd();
        }
      });

    return () => {
      setReportsDownloadHistory(initialData);
      //Cancel all previous fetch calls
      if (source) source.cancel();
    };
  }, [reloadReportsDownloadHistory]);

  // If the report is goto reports
  useEffect(() => {
    if (user.screen.reportManager.metadata.variant === "gotoReport") {
      const selections = user.screen.reportManager.metadata.selections;
      setActiveReport({
        type: "saved",
        id: `report-${v4()}`,
        variant: "gotoReport",
        selections,
      });
    }
  }, []);

  //Change showReportManager state so that the component can mount and unmount for resetting formData
  useEffect(() => {
    setShowReportManager(false);
  }, [activeReport]);

  //Change showReportManager state so that the component can mount and unmount for resetting formData
  useEffect(() => {
    setShowReportManager(true);
  }, [showReportManager]);

  //DEBUGGING
  // console.group("REPORT MANAGER CONTAINER STATE");
  // console.log("scheduledReports", scheduledReports);
  // console.log("activeReport", activeReport);
  // console.log("savedReports", savedReports);
  // console.log("reportsDownloadHistory", reportsDownloadHistory);
  // console.log("activeReportFormData", activeReportFormData);
  // console.log(
  //   "reportsDownloadHistoryTransformed",
  //   reportsDownloadHistoryTransformed
  // );
  // console.log("scheduledReportsTransformed", scheduledReportsTransformed);
  // console.log("savedReportsTransformed", savedReportsTransformed);
  // console.groupEnd();

  let disabledAddNewReport = false;
  let disabledAddNewReportButtonMessage;
  if (activeReportType === "saved") {
    if (savedReports.status === "loading") {
      disabledAddNewReport = true;
      disabledAddNewReportButtonMessage = "loading";
    } else {
      if (savedReports.data.length >= maxSavedReports) {
        disabledAddNewReport = true;
        disabledAddNewReportButtonMessage =
          "Max Limit Reached For Saved Reports";
      } else {
        disabledAddNewReport = false;
        disabledAddNewReportButtonMessage = "";
      }
    }
  } else {
    if (scheduledReports.status === "loading") {
      disabledAddNewReport = true;
      disabledAddNewReportButtonMessage = "loading";
    } else {
      if (scheduledReports.data.length >= maxScheduledReports) {
        disabledAddNewReport = true;
        disabledAddNewReportButtonMessage =
          "Max Limit Reached For Scheduled Reports";
      } else {
        disabledAddNewReport = false;
        disabledAddNewReportButtonMessage = "";
      }
    }
  }

  let ModifiedBucketList = useMemo(
    () =>
      bucketList.result.length > 0
        ? [{ id: "initial", name: "Select Storage", disabled: true }].concat(
            unwrapperBucketListInReport(bucketList.result)
          )
        : [
            {
              id: "NA",
              name: "No storage available for your realm",
              disabled: true,
            },
          ],
    [bucketList.result]
  );

  const fetchData = async () => {
    const sharedReportId = localStorage.getItem("sharedReportId");
    if (!sharedReportId) return;

    const source = axios.CancelToken.source();
    const payload = {
      bookmarkId: sharedReportId,
      isShare: "true",
    };
    const fetchProps = { payload, cancelToken: source.token };

    setShowReportManager(false);

    try {
      const response = await getBookmark(fetchProps);
      const { data } = response.result;

      const reportPayload = data.profile.report.savedReportPayload;
      const scheduledPayload = data.profile.report.scheduledReportPayload;
      const isShareReportValidBasedOnUserAccessProps = {
        backendWs: reportPayload || scheduledPayload,
        user,
        allData,
      };
      const isShareReportValidBasedOnUserAccessResponse =
        isShareReportValidBasedOnUserAccess(
          isShareReportValidBasedOnUserAccessProps
        );

      if (isShareReportValidBasedOnUserAccessResponse.status !== "valid") {
        const message = makeAttributeMissingErrorMessage(
          isShareReportValidBasedOnUserAccessResponse,
          "Report"
        );
        setReportSheet({ status: "accessError", message });
        localStorage.removeItem("sharedReportId");
        return;
      }

      const reportType = reportPayload
        ? "saved"
        : scheduledPayload
        ? "scheduled"
        : "";
      if (sharedReportId) {
        setActiveReport({ type: reportType, id: sharedReportId });
      }

      setReportSheet({ status: "success", message: "" });

      const reportFormData =
        reportType === "saved"
          ? unwrapperSavedReport({
              payload: {
                orgViewReq: data.profile.orgViewReq,
                email: data.profile.sharedBy,
                recentDownloadRecord: reportPayload,
              },
              allData,
              user,
              activeReport: { type: "saved", id: sharedReportId },
            })
          : unwrapperScheduledReport({
              payload: scheduledPayload,
              allData,
              user,
              activeReport: { type: "scheduled", id: sharedReportId },
            });
      reportFormData.reportName = "Untitled Report";
      setSharedReportData({
        reportFormData,
        reportPayload:
          reportType === "saved" ? reportPayload : scheduledPayload,
      });
      setIsSharedReport(true);
      setShowReportManager(true);
    } catch (error) {
      console.error("Error fetching bookmark:", error);
      // Handle error state here if needed
    } finally {
      source.cancel();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleAccessCorruptError = () => {
    history.push("/analyze/dashboard");
    window.location.reload();
  };

  return (
    <>
      {reportSheet.status === "accessError" && (
        <LayoutTopSideBottom>
          <ErrorHandler
            message={reportSheet.message}
            reloadFlag={true}
            onReload={handleAccessCorruptError}
            reloadButtonProps={{
              flag: true,
              // variant: "contained",
              title: "Load Default profile",
            }}
          />
        </LayoutTopSideBottom>
      )}
      {user.screen.reportManager.isOpen &&
        reportSheet.status !== "accessError" && (
          <div
            className="report-manager-container-overlay"
            onClick={reportOutsideClickClose}
          >
            <div className="report-manager-container">
              <section className="title-row-container">
                <div className="left-panel">
                  <p className="title">{t("Reports")}</p>
                </div>
                <div className="right-panel">
                  <i
                    className="material-icons-round close-icon closeReports"
                    onClick={handleReportManagerClose}
                  >
                    close
                  </i>
                </div>
              </section>
              <section className="content-container">
                <div className="left-panel">
                  <div
                    className={`top-panel ${activeReportType} ${isDownloadAvailableClassName}`}
                  >
                    <div className="add-report-button-container">
                      <SigviewTooltip
                        title={disabledAddNewReportButtonMessage}
                        placement="bottom"
                      >
                        <Box>
                          <SigviewButton
                            variant="containedPrimary"
                            onClick={() => {
                              handleAddNewReport("Sidenav");
                            }}
                            title="Add New Report"
                            style={{
                              width: "210px",
                              btnHeight: "26px",
                              backgroundColor: "#f5f7ff",
                              color: "#267ee6",
                            }}
                            iconFlag={true}
                            iconProps={{
                              className: "material-icons",
                              iconName: "add",
                            }}
                            disabled={disabledAddNewReport}
                            customClassName="AddNewReport-GA"
                          />
                        </Box>
                      </SigviewTooltip>
                    </div>
                    <div className="report-type-nav-container">
                      <SigviewNav
                        data={reportTypesArr}
                        value={activeReportType}
                        style={{
                          fontSize: "11px",
                          textTransform: "uppercase",
                          padding: "0px 11px",
                        }}
                        onChange={(e, newValue) => {
                          handleReportNavClick(newValue);
                        }}
                        customClassName="ReportSwitchTab"
                      />
                      {/* <SigviewNavType2
                      data={reportTypesArr}
                      value={activeReportType}
                      onChange={(e, newValue) => {
                        handleReportNavClick(newValue);
                      }}
                    /> */}
                    </div>
                    <div className="reports-sidenav-container">
                      <ReportsSidenav
                        data={reportsSidenavData}
                        reportType={activeReportType}
                        user={user}
                        onToggle={updateParentScheduledReportsOnToggle}
                        onClick={handleReportRowClick}
                      />
                    </div>
                  </div>
                  {/* <div className="bottom-panel">
                  <div className="download-history-container">
                    <p className="download-history-title">Download History</p>
                    <div className="reports-sidenav-container">
                      <ReportsSidenav
                        data={reportsDownloadHistoryTransformed}
                        reportType="downloads"
                        onClick={handleReportRowClick}
                      />
                    </div>
                  </div>
                </div> */}

                  <div
                    className={`bottom-panel ${activeReportType} ${isDownloadAvailableClassName}`}
                  >
                    <div className="report-type-nav-container-history downloads">
                      <SigviewNav
                        style={{
                          fontSize: "11px",
                          textTransform: "uppercase",
                          padding: "0px 5px",
                        }}
                        data={downloadsNavArr}
                        value="downloads"
                      />
                    </div>
                    <div className="reports-sidenav-container">
                      <ReportsSidenav
                        data={reportsDownloadHistoryTransformed}
                        reportType="download"
                        user={user}
                        onToggle={() => {}}
                        onClick={handleReportRowClick}
                      />
                    </div>
                  </div>
                </div>

                {activeReport.type === "none" ? (
                  <div className="right-panel no-report-selected-container">
                    <section className="add-or-open-report-container">
                      <SigviewTooltip
                        title={disabledAddNewReportButtonMessage}
                        placement="bottom"
                      >
                        <Box>
                          <SigviewButton
                            variant="containedPrimary"
                            onClick={() => {
                              handleAddNewReport("Form");
                            }}
                            title="Add New Report"
                            style={{
                              width: "210px",
                              btnHeight: "26px",
                              backgroundColor: "#f5f7ff",
                              color: "#267ee6",
                            }}
                            iconFlag={true}
                            iconProps={{
                              className: "material-icons",
                              iconName: "add",
                            }}
                            disabled={disabledAddNewReport}
                            customClassName="AddNewReport-GA"
                          />
                        </Box>
                      </SigviewTooltip>
                      {/* <p
                    className="no-report-selected-text add-report-text"
                    onClick={() => {
                      handleAddNewReport("Form");
                    }}
                  >
                    <span className="material-icons add-report-icon">add</span>
                    <span>Add New Report</span>
                  </p> */}
                      <p className="no-report-selected-text">{t("Or")}</p>
                      <p className="no-report-selected-text">
                        {t("Select a saved report")}
                      </p>
                    </section>
                  </div>
                ) : (
                  <>
                    {showReportManager && (
                      <>
                        {activeReportFormData.valid ? (
                          <ReportManager
                            activeReportFormData={
                              isSharedReport
                                ? sharedReportData
                                : activeReportFormData
                            }
                            activeReport={activeReport}
                            setActiveReport={setActiveReport}
                            ReduxDispatcher={ReduxDispatcher}
                            user={user}
                            allData={allData}
                            globalFilters={globalFilters}
                            reloadData={reloadData}
                            tourRun={tourRun}
                            setTourRun={setTourRun}
                            uiFeatureList={user.uiFeatureList}
                            disabledAddNewReport={disabledAddNewReport}
                            bucketList={ModifiedBucketList}
                            setShowReportManager={setShowReportManager}
                            isSharedReport={isSharedReport}
                            setIsSharedReport={setIsSharedReport}
                          />
                        ) : (
                          <div className="right-panel no-report-selected-container">
                            {t("CORRUPT REPORT")}
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </section>
            </div>
          </div>
        )}
    </>
  );
}

ReportManagerContainer.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  globalFilters: state.globalFilters,
});

export default connect(mapStateToProps)(ReportManagerContainer);

const ReportRow = (props) => {
  const {
    id,
    title = "",
    subtitle = "",
    onClick = () => {},
    onToggle = () => {},
    isActive = false,
    reportType = "",
    payload,
    user,
    isCurrentlySelected,
  } = props;

  const [reportActive, setReportActive] = useState({
    status: "success",
    message: "",
    data: isActive,
  });

  const isCurrentlySelectedClass = isCurrentlySelected ? "active" : "";

  //Update local state everytime props change
  useEffect(() => {
    setReportActive({
      status: "success",
      message: "",
      data: isActive,
    });
  }, [isActive]);

  const onToggleSwitch = (event, checked) => {
    //To prevent the report from being clicked
    event.stopPropagation();
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "ReportManager",
      action: "ScheduledToggle",
      label: checked ? "On" : "Off",
    });
    //Set status to loading
    setReportActive((prevState) => ({ ...prevState, status: "loading" }));
    //Make a fetch call to update status
    const fetchPayload = {
      ...payload,
      reportStatus: checked ? "active" : "inactive",
      statusToggleTime: new Date().valueOf().toString(),
    };
    const fetchProps = {
      payload: fetchPayload,
    };
    const updateScheduledReportPromise = updateScheduledReport(fetchProps);
    updateScheduledReportPromise
      .then(() => {
        //Update local state
        setReportActive((prevState) => ({
          ...prevState,
          status: "success",
          data: checked,
        }));
        //Update parent scheduled reports
        onToggle(id, fetchPayload);
      })
      .catch((error) => {
        if (error.error !== config.hardCoded.queryCancelled) {
          setReportActive((prevState) => ({ ...prevState, status: "success" }));
        }
      });
  };

  const handleReportRowClick = () => {
    onClick({ type: reportType, id: id });
  };

  return (
    <div
      className={`report-row ${reportType} ${isCurrentlySelectedClass} Report${reportType}ClickFromSidenav-GA`}
      onClick={handleReportRowClick}
    >
      <section
        className={`report-row-left-panel Report${reportType}ClickFromSidenav-GA`}
      >
        <p
          className={`report-row-title Report${reportType}ClickFromSidenav-GA`}
          title={title}
        >
          {title}
        </p>
        <p
          className={`report-row-subtitle Report${reportType}ClickFromSidenav-GA`}
          title={subtitle}
        >
          {subtitle}
        </p>
      </section>
      <section className={`report-row-right-panel ${isCurrentlySelectedClass}`}>
        {reportActive.status === "loading" && <SigviewProgressBar />}
        {reportActive.status === "success" && ( //Not showing for the one that is active; user can change it from the form panel
          // !isCurrentlySelected &&
          <SigviewSwitch
            checked={reportActive.data}
            onChange={onToggleSwitch}
            customClassName="scheduleReportON-OFF-GA"
          />
        )}
      </section>
    </div>
  );
};

const ReportsSidenav = (props) => {
  const { t } = useTranslation();
  const {
    data = { status: "success", message: "", data: [] },
    reloadFlag = true,
    onReload = () => {},
    reportType = "",
    user,
    onToggle,
    onClick,
  } = props;

  //HARD CODED: To be removed when bulk filter hard coding is removed
  const noDataMessageMapping = {
    saved: "No saved reports to display",
    scheduled: "No scheduled reports to display",
    download: "No downloads",
  };
  const noDataMessage = noDataMessageMapping[reportType];

  return (
    <div className={`reports-sidenav ${data.status} ${reportType}`}>
      {data.status === "loading" && <Loader />}
      {data.status === "error" && (
        <ErrorHandler
          message={data.message}
          reloadFlag={reloadFlag}
          onReload={onReload}
        />
      )}
      {data.status === "success" && data.data.length === 0 && (
        <p className="no-data">{t(noDataMessage)}</p>
      )}
      {data.status === "success" && data.data.length !== 0 && (
        <>
          {data.data.map((row) => (
            <ReportRow
              key={row.id}
              {...row}
              reportType={reportType}
              user={user}
              onToggle={onToggle}
              onClick={onClick}
            />
          ))}
        </>
      )}
    </div>
  );
};
