// * Import required lib
import { v4 } from "uuid";
import lodashOrderBy from "lodash.orderby";
import { format as d3Format } from "d3";
import moment from "moment";
import _ from "underscore";
import isEqual from "lodash.isequal";
import orderBy from "lodash.orderby";

// * Import utils
import {
  unwrapperTimeFilters,
  unwrapperChartObject,
  wrapperOrgViewReq,
  wrapperChartObject,
} from "./chartObjectUtils";
// Import custom utils
import {
  transformFiltersUiToBackend,
  getValidDimensionsList,
} from "./filtersUtils";
import {
  appendMetricSym,
  appendMetricSymPercentageTotal,
  getDimensionObjByOriginalID,
  getMeasureObjByOriginalID,
  getMetricTypeForChartObject,
  getOriginalIdFromMeasureId,
} from "./plotlyUtils";
import { transformFiltersBackendToUi } from "./filtersUtils";
import { getDatePresetObj } from "./timeFiltersUtils";
import allDatePresets from "../../assets/data/allDatePresets.json";
import {
  getValidMetricGranDataBasedOnDimListAndAccessList,
  manipulateAvailableMetricGranDataBasedOnSelectedDatesAndLimitList,
  getValidMetricChartGranularityBasedOnValidMetricGranData,
} from "./granularityUtils";

import { config } from "../config/config";
import { getSigviewUserType } from "./utils";
import SIGVIEW_CONTANTS from "../constants/sigviewConstants";
import {
  selectMetricsInMsvTableAccessorsList,
  selectSelectedTableItemInChart,
} from "../redux/selectors/standaloneMsvSelectors";

const activeWorkspaceSample = { payload: {}, title: "", crudType: "create" };

// const newObject = {
//   id: uniqueId,
//   title: dimObject._title,
//   dimensionsList: dimObject,
//   metricsList: selectedMetric,
//   metricFilters: [],
//   dimensionFilters: [],
//   timeFilters: state.timeFilters.value,
//   orderById: state.orderBy.value,
//   orderBy: state.orderBy.value,
//   chartType: "table",
//   chartList: chartTitle,
//   valid: true,
//   progressiveDateFlag: true,
//   percentCalList: selected,
//   renderFlag: false,
// };

const sampleSelectedDimensionValue = {
  D003: {
    dimensionsList: [],
    metricsList: [],
    percentCalList: [],
    orderBy: "",
    orderById: "",
    orderByType: "",
  },
};
const sampleSelectedKpiValue = {
  M001: {
    dimensionsList: [],
    metricsList: [],
    percentCalList: [],
    orderBy: "",
    orderById: "",
    orderByType: "",
  },
};
const sampleDataQeValue = {
  D003: {
    status: "loading",
    message: "",
    result: [],
  },
  M001: {
    status: "loading",
    message: "",
    result: {
      value: 0,
      displayValue: "0",
    },
  },
  M001_deltaPercentage: {
    status: "loading",
    message: "",
    result: {
      value: 0,
      displayValue: "0",
    },
  },
  M001_trueDelta: {
    status: "loading",
    message: "",
    result: {
      value: 0,
      displayValue: "0",
    },
  },
  // This is to cater to compare charts as well
  M001_chart_start_epoch_end_epoch: {
    status: "loading",
    message: "",
    result: {
      dataFromQE: [],
      extraData: {},
    },
  },
  // M001_chart_start_epoch_end_epoch: {
  //   status: "loading",
  //   message: "",
  //   result: {
  //     dataFromQE: [],
  //     extraData: {},
  //   },
  // },
};

const initialWsFormScreenValue = {
  metricDrawer: false,
  dimensionDrawer: false,
  allWorkbooks: false,
  metricDrawerList: {},
  dimensionDrawerList: {},
};

// These are the keys which should be checked for in case we need to identify if update API needs to be called or not
const wsApiSelectionsKeys = [
  "dataLimit",
  "dimTablesGridViewType",
  "dimensionFilters",
  "globalSort",
  "layout",
  "metricChartGranularity",
  "metricChartType",
  "rollingDateType",
  "dimensionFilters",
  "selectedDimensions",
  "selectedKpis",
  "timeFilters",
];

const isWsFormSameAsOriginal = (wsForm, wsFormOriginal) => {
  const wsFormWithRequiredKeys = _.pick.apply(null, [
    wsForm,
    ...wsApiSelectionsKeys,
  ]);
  const wsFormOriginalWithRequiredKeys = _.pick.apply(null, [
    wsFormOriginal,
    ...wsApiSelectionsKeys,
  ]);
  const isEqualFlag = isEqual(
    wsFormWithRequiredKeys,
    wsFormOriginalWithRequiredKeys
  );
  return isEqualFlag;
};

const getInitialWsForm = (props) => {
  const {
    user = {},
    title = "Untitled Workspace - 1",
    allData = {},
    crudType = "create",
  } = props;
  let initialWsForm = {};
  if (crudType === "edit") {
    // // TODO
    // initialWsForm = unwrapperDatastory({
    //   backendWs: activeWorkspace.payload,
    //   user,
    //   allData,
    // });
  } else {
    initialWsForm = {
      id: {
        value: v4(),
        status: "valid",
        message: "",
        stepTag: "",
      },
      name: {
        value: title,
        status: "valid", //> C.valid
        message: "",
        stepTag: "details", //> C.stepTagTypes.details
      },
      rollingDateType: {
        value: "yes",
        status: "valid", //> C.valid,
        message: "",
        stepTag: "details", //> C.stepTagTypes.details
      },
      timeFilters: {
        value: user.timeFilters,
        message: "",
        status: "valid", //> C.valid
        stepTag: "details", //> C.stepTagTypes.details
      },
      metricChartGranularity: {
        value: "hour", // hour or day
        message: "",
        status: "valid", //> C.valid
        stepTag: "details", //> C.stepTagTypes.details
      },
      dimensionFilters: {
        value: [],
        message: "",
        status: "valid", //> C.valid
        stepTag: "filters", //> C.stepTagTypes.details
      },
      selectedDimensions: {
        value: {},
        status: "valid", //> C.valid
        message: "",
        stepTag: "dimensions", //> C.stepTagTypes.charts
      },
      dimTablesGridViewType: {
        value: "single", // single or double
        status: "valid", //> C.valid
        message: "",
        stepTag: "dimensions", //> C.stepTagTypes.charts
      },
      selectedKpis: {
        value: {},
        status: "valid", //> C.valid
        message: "",
        stepTag: "metrics", //> C.stepTagTypes.charts
      },
      metricChartType: {
        value: "line", // line or area or bar
        status: "valid", //> C.valid
        message: "",
        stepTag: "metrics", //> C.stepTagTypes.charts
      },
      dataQE: {
        value: {},
        status: "valid",
        message: "",
        stepTag: "",
      },
      globalSort: {
        value: true, // true or false
        status: "valid",
        message: "",
        stepTag: "",
      },
      selectedDimTableMetrics: {
        value: [],
        status: "valid",
        message: "",
        stepTag: "",
      },
      reloadEpochs: {
        value: {},
        status: "valid",
        message: "",
        stepTag: "",
      },
      type: { value: "create", status: "valid", message: "", stepTag: "" },
      actualPayload: {
        value: {},
        status: "valid",
        message: "",
        stepTag: "",
      },
      layout: {
        value: { kpis: [], dimensions: [] },
        status: "valid",
        message: "",
        stepTag: "",
      },
      orderById: {
        value: "",
        status: "invalid", //> C.valid
        message: "",
        stepTag: "dimensions", //> C.stepTagTypes.charts
      },
      orderBy: {
        value: "asc", // asc or desc
        status: "valid", //> C.valid
        message: "",
        stepTag: "dimensions", //> C.stepTagTypes.charts
      },
      orderByType: {
        value: "id_only", // id_only or delta_percentage or delta_abs
        status: "valid", //> C.valid
        message: "",
        stepTag: "dimensions", //> C.stepTagTypes.charts
      },
      msv: {
        value: { isOpen: false, metadata: {} },
        status: "valid",
        message: "",
        stepTag: "",
      },
      isItMsvForm: { value: false, status: "valid", message: "", stepTag: "" },
      dataLimit: {
        value: 50,
        status: "valid",
        message: "",
        stepTag: "",
      },
      // This is to control the screen like drawer state, etc
      screen: {
        value: initialWsFormScreenValue,
        status: "valid",
        message: "",
        stepTag: "",
      },
      autosaveApiStatus: {
        value: "success", // success/loading
        status: "valid",
        message: "",
        stepTag: "",
      },
      utils: {
        value: {
          uiLimitsList: user.uiLimitsList,
          uiFeatureList: user.uiFeatureList,
          plotlyDimensions: allData.plotlyDimensions,
          plotlyMetrics: allData.plotlyMetrics,
        },
        status: "valid",
        message: "",
        stepTag: "",
      },
      metricGranData: {
        value: config.hardCoded.defaultMetricGranData,
        status: "valid",
        message: "",
        stepTag: "",
      },
      metricChartHover: {
        hoverFlag: false,
        meticId: "",
        pointNumber: 0,
      },
    };
  }
  return initialWsForm;
};

const getDefaultWsForm = (props = {}) => {
  const { initialUiFeatureList, initialUiLimitsList } = props;
  const getInitialWsFormProps = {
    user: {
      timeFilters: SIGVIEW_CONTANTS.sampleValidTimeFilters,
      uiLimitsList: initialUiLimitsList,
      uiFeatureList: initialUiFeatureList,
    },
    allData: { plotlyMetrics: [], plotlyDimensions: [] },
    title: "",
    crudType: "create",
  };
  const defaultWsForm = getInitialWsForm(getInitialWsFormProps);
  return defaultWsForm;
};

const getDefaultWorksheetUi = (props = {}) => {
  const { user = {}, allData = {} } = props;
  const { timeFilters = {} } = user;

  const metrics = allData.plotlyMetrics.slice(0, 5).map((row, index) => ({
    id: row._id,
    order: index + 1,
    chartType: "line",
  }));
  const metricsForTable = metrics.map((row, index) => ({
    metricId: row.id,
    order: index,
  }));
  const sortListForTable = [{ id: metrics[0].id, desc: true }];
  const plotlyDimensionsWithoutDateTime = allData.plotlyDimensions.filter(
    (row) => row.dataType !== "dateTime"
  );
  const dimensions = plotlyDimensionsWithoutDateTime
    .slice(0, 5)
    .map((row, index) => ({
      id: row._id,
      order: index + 1,
      chartType: "table",
      size: {
        width: 49,
        height: 224,
      },
      metrics: metricsForTable,
      sortList: sortListForTable,
    }));
  const layout = { chart: metrics, table: dimensions };

  const dateRange = {
    startDate: timeFilters.selectedDatesQE.startDate.toString(),
    endDate: timeFilters.selectedDatesQE.endDate.toString(),
    comparisonStartDate:
      timeFilters.compareDates[0].compareSelectedDatesQESameDuration.startDate.toString(),
    comparisonEndDate:
      timeFilters.compareDates[0].compareSelectedDatesQESameDuration.endDate.toString(),
    dateRangeType: "Custom",
    progressiveDate: "true",
  };

  const recentDownload = {
    metrics: [metrics[0].id],
    dimensions: [dimensions[0].id],
  };

  const defaultWorksheetUi = {
    sheetId: v4(),
    sheetName: `Default Profile UI - ${moment(Date.now()).format(
      config.hardCoded.defaultProfileTimestampFormat
    )}`,
    isActive: true,
    worksheetObject: {
      dateRange: dateRange,
      granularity: "day",
      timeZone: {
        name: "UTC (+00:00)",
        location: "UTC",
      },
      limit: 50,
      multiSelectLimit: 1000,
      downloadLimit: 5000,
      comparisonMode: false,
      layout: layout,
      filters: [],
      globalSort: {
        status: false,
      },
      isTableExpanded: {
        status: false,
      },
      recentDownload: recentDownload,
    },
  };
  return defaultWorksheetUi;
};

const sampleBackendWs = {
  sheetId: "aaa_0.8700228313045164_1644518125028",
  sheetName: "aaa",
  isActive: true,
  worksheetObject: {
    dateRange: {
      startDate: "1644883200000",
      endDate: "1645398000000",
      comparisonStartDate: "1645056000000",
      comparisonEndDate: "1645225200000",
      dateRangeType: "Last 7 Days",
      progressiveDate: "true",
    },
    granularity: "day",
    timeZone: {
      name: "UTC (+00:00)",
      location: "UTC",
    },
    limit: 50,
    multiSelectLimit: 1000,
    downloadLimit: 5000,
    comparisonMode: false,
    layout: {
      table: [
        {
          id: "D020",
          order: 2,
          chartType: "table",
          size: {
            width: 50,
            height: 0,
          },
          metrics: [
            {
              metricId: "CM012",
              order: 1,
              isPercentCalOn: false,
              compareCol: "_deltaPercentage",
              isExpanded: false,
            },
            {
              metricId: "M002",
              order: 2,
              isPercentCalOn: false,
              compareCol: "_deltaPercentage",
              isExpanded: false,
            },
            {
              metricId: "M004",
              order: 3,
              isPercentCalOn: false,
              compareCol: "_deltaPercentage",
              isExpanded: false,
            },
            {
              metricId: "CM046",
              order: 4,
              isPercentCalOn: false,
              compareCol: "_deltaPercentage",
              isExpanded: false,
            },
            {
              metricId: "CM049",
              order: 5,
              isPercentCalOn: false,
              compareCol: "_deltaPercentage",
              isExpanded: false,
            },
            {
              metricId: "CM019",
              order: 6,
              isPercentCalOn: false,
              compareCol: "_deltaPercentage",
              isExpanded: false,
            },
          ],
          sortList: [
            {
              id: "CM012",
              desc: true,
            },
          ],
        },
        {
          id: "D050",
          order: 1,
          chartType: "table",
          size: {
            width: 50,
            height: 0,
          },
          metrics: [
            {
              metricId: "CM012",
              order: 1,
              isPercentCalOn: false,
              compareCol: "_deltaPercentage",
              isExpanded: false,
            },
            {
              metricId: "M002",
              order: 2,
              isPercentCalOn: false,
              compareCol: "_deltaPercentage",
              isExpanded: false,
            },
            {
              metricId: "M004",
              order: 3,
              isPercentCalOn: false,
              compareCol: "_deltaPercentage",
              isExpanded: false,
            },
            {
              metricId: "CM046",
              order: 4,
              isPercentCalOn: false,
              compareCol: "_deltaPercentage",
              isExpanded: false,
            },
            {
              metricId: "CM049",
              order: 5,
              isPercentCalOn: false,
              compareCol: "_deltaPercentage",
              isExpanded: false,
            },
            {
              metricId: "CM019",
              order: 6,
              isPercentCalOn: false,
              compareCol: "_deltaPercentage",
              isExpanded: false,
            },
          ],
          sortList: [
            {
              id: "M002",
              desc: true,
            },
          ],
        },
      ],
      chart: [
        {
          id: "CM019",
          order: 7,
          chartType: "line",
          size: {
            width: 0,
            height: 224,
          },
        },
        {
          id: "M001",
          order: 34,
          chartType: "line",
          size: {
            width: 0,
            height: 224,
          },
        },
      ],
    },
    filters: [],
    recentDownload: {
      metrics: ["M001"],
      dimensions: ["D002"],
    },
    secondaryComparisonDates: [],
    globalSort: {
      status: false,
    },
    isTableExpanded: {
      status: true,
    },
  },
};

const unwrapperWs = (props = {}) => {
  // * DEBUGGER
  // console.groupCollapsed("unwrapperWs");
  // console.log("props", props);
  // console.groupEnd();

  // * Define requried util functions for unwrapperDs

  const { backendWs = {}, user = {}, allData = {} } = props;
  const { worksheetObject = {}, sheetId = "", sheetName = "" } = backendWs;
  const {
    dateRange = {},
    granularity = "",
    timeZone = {},
    comparisonMode = false,
    layout = { table: [], chart: [] },
    filters = [],
    isTableExpanded = { status: false },
    globalSort = { status: false },
    secondaryComparisonDates = [],
    limit: dataLimitVal = 50,
  } = worksheetObject;
  const {
    dateRangeType = "Custom",
    startDate = "1633305600000",
    endDate = "1633910400000",
    progressiveDate = "true",
    comparisonStartDate = "1633305600000",
    comparisonEndDate = "1633305600000",
  } = dateRange;

  // TIME FILTERS
  // ! MOST IMPORTANT
  // ! Add one hour because angular time filters subtracts one hour while saving
  // ! And react time filters work correctly if one hour is added
  var endDateFinal = Number(endDate) + 1 * 60 * 60 * 1000;
  var comparisonEndDateFinal = Number(comparisonEndDate) + 1 * 60 * 60 * 1000;
  const secondaryComparisonDatesFinal = secondaryComparisonDates.map((row) => {
    var endDateFinal = Number(row.endDate) + 1 * 60 * 60 * 1000;
    return { startDate: row.startDate, endDate: endDateFinal };
  });
  const chartObjectForTimeFilters = {
    requestParam: {
      dateRangeType,
      dateRange: { startDate, endDate: endDateFinal.toString() },
      compareDateRange: {
        startDate: comparisonStartDate,
        endDate: comparisonEndDateFinal.toString(),
      },
      timeZone,
      progressiveDate,
      secondaryComparisonDates: secondaryComparisonDatesFinal,
    },
    metadata: { comparisonMode: comparisonMode },
  };
  const unwrapperTimeFiltersProps = {
    allData: allData,
    chartObject: chartObjectForTimeFilters,
    calendarDaysLimits: user.uiLimitsList.daysLimitCalendarReports,
    user: user,
  };
  var timeFiltersVal = unwrapperTimeFilters(unwrapperTimeFiltersProps);
  // Update TimeFilters compareType
  const compareDateTypeBackendMappingInverse =
    config.hardCoded.compareDateTypeBackendMappingInverse;
  var backendComparisonType =
    layout?.table[0]?.metrics[0]?.compareCol || "_trueDelta";
  var finalComparisonType =
    compareDateTypeBackendMappingInverse[backendComparisonType];
  timeFiltersVal = { ...timeFiltersVal, comparisonType: finalComparisonType };

  // DIMENSION FILTERS
  let dimensionFiltersVal = transformFiltersBackendToUi(
    filters,
    allData.plotlyDimensions
  );
  //** Backward Compatibility Code  Start Here**//
  const {
    globalFiltersFileUpload = false,
    globalFiltersStringMatch = false,
    globalFiltersTimestamp = false,
  } = user.uiFeatureList;

  if (
    !globalFiltersStringMatch &&
    !globalFiltersFileUpload &&
    !globalFiltersTimestamp &&
    dimensionFiltersVal.length > 0
  ) {
    var validityFlags = dimensionFiltersVal.map((row) => {
      const newRow = {
        ...row,
        advancedFilters: row.advancedFilters.filter(
          (item) =>
            ![
              "startsWith",
              "exactlyMatches",
              "endsWith",
              "containsString",
              "containsWholeWord",
              "from",
              "on",
              "between",
              "before",
            ].includes(item.type)
        ),
      };
      return newRow;
    });
  } else if (
    !globalFiltersStringMatch &&
    !globalFiltersFileUpload &&
    globalFiltersTimestamp &&
    dimensionFiltersVal.length > 0
  ) {
    var validityFlags = dimensionFiltersVal.map((row) => {
      const newRow = {
        ...row,
        advancedFilters: row.advancedFilters.filter(
          (item) =>
            ![
              "startsWith",
              "exactlyMatches",
              "endsWith",
              "containsString",
              "containsWholeWord",
            ].includes(item.type)
        ),
      };
      return newRow;
    });
  } else if (
    globalFiltersStringMatch &&
    !globalFiltersFileUpload &&
    !globalFiltersTimestamp &&
    dimensionFiltersVal.length > 0
  ) {
    var validityFlags = dimensionFiltersVal.map((row) => {
      const newRow = {
        ...row,
        advancedFilters: row.advancedFilters.filter(
          (item) =>
            !["exactlyMatches", "from", "on", "between", "before"].includes(
              item.type
            )
        ),
      };
      return newRow;
    });
  } else if (
    !globalFiltersStringMatch &&
    globalFiltersFileUpload &&
    !globalFiltersTimestamp &&
    dimensionFiltersVal.length > 0
  ) {
    var validityFlags = dimensionFiltersVal.map((row) => {
      const newRow = {
        ...row,
        advancedFilters: row.advancedFilters.filter(
          (item) =>
            ![
              "startsWith",
              "endsWith",
              "containsString",
              "containsWholeWord",
              "from",
              "on",
              "between",
              "before",
            ].includes(item.type)
        ),
      };
      return newRow;
    });
  } else if (
    globalFiltersFileUpload &&
    !globalFiltersStringMatch &&
    globalFiltersTimestamp &&
    dimensionFiltersVal.length > 0
  ) {
    var validityFlags = dimensionFiltersVal.map((row) => {
      const newRow = {
        ...row,
        advancedFilters: row.advancedFilters.filter(
          (item) =>
            ![
              "startsWith",
              "endsWith",
              "containsString",
              "containsWholeWord",
            ].includes(item.type)
        ),
      };
      return newRow;
    });
  } else if (
    !globalFiltersFileUpload &&
    globalFiltersStringMatch &&
    globalFiltersTimestamp &&
    dimensionFiltersVal.length > 0
  ) {
    var validityFlags = dimensionFiltersVal.map((row) => {
      const newRow = {
        ...row,
        advancedFilters: row.advancedFilters.filter(
          (item) => !["exactlyMatches"].includes(item.type)
        ),
      };
      return newRow;
    });
  } else if (
    globalFiltersFileUpload &&
    globalFiltersStringMatch &&
    !globalFiltersTimestamp &&
    dimensionFiltersVal.length > 0
  ) {
    var validityFlags = dimensionFiltersVal.map((row) => {
      const newRow = {
        ...row,
        advancedFilters: row.advancedFilters.filter(
          (item) => !["from", "on", "between", "before"].includes(item.type)
        ),
      };
      return newRow;
    });
  }
  if (validityFlags) {
    var finalValidateFilterArray = validityFlags.filter((row) => {
      if (
        (row.values.length !== 0 && row.advancedFilters.length === 0) ||
        (row.values.length === 0 && row.advancedFilters.length !== 0) ||
        (row.values.length !== 0 && row.advancedFilters.length !== 0)
      ) {
        return row;
      }
    });
    dimensionFiltersVal = finalValidateFilterArray;
  }
  //** Backward Compatibility Code Ends Here **//
  // MISC
  const dimTablesGridViewTypeVal = isTableExpanded.status ? "single" : "double";
  const metricChartType = layout?.chart[0]?.chartType || "line";
  var reloadEpochsVal = {};
  var selectedDimensionsVal = {};
  var selectedKpisVal = {};
  var dataQEVal = {};
  const orgViewReq = {
    organization: user?.reqMetadata?.organization,
    view: user?.reqMetadata?.view,
  };
  reloadEpochsVal["metrics"] = Date.now();
  reloadEpochsVal["allMetricCharts"] = Date.now();
  reloadEpochsVal["allMetrics"] = Date.now();

  // SELECTED DIMENSIONS
  layout.table.forEach((row) => {
    const uniqueId = row.id;
    const sortListObj = row.sortList[0];
    const cmPattern = /CM[0-9]{3}/g; // TODO: Take care of sorted on comparison column
    const metricPattern = /M[0-9]{3}/g; // TODO: Take care of sorted on comparison column
    const isSortedOnCm = cmPattern.test(sortListObj.id);
    const isSortedOnMetric = metricPattern.test(sortListObj.id);
    const isSortedOnDimension = !isSortedOnCm && !isSortedOnMetric;
    var orderById = sortListObj.id;
    if (isSortedOnDimension) orderById = uniqueId;
    const orderByObject = { id: orderById, desc: sortListObj.desc };
    const orderBy = isSortedOnDimension
      ? { dimOrdByList: [orderByObject] }
      : isSortedOnMetric
      ? { metricOrdByList: [orderByObject] }
      : { customMetricOrdByList: [orderByObject] };
    let yAxis = [],
      specialCalculation = [],
      approxCountDistinct = [],
      percentCalList = [],
      metricsOrder = {};
    row.metrics.forEach((kpi, index) => {
      const kpiId = kpi.metricId;
      const metricObj = getMeasureObjByOriginalID(kpiId, allData.plotlyMetrics);
      const metricType = getMetricTypeForChartObject(metricObj);
      if (metricType === "base_sum") {
        yAxis.push(kpiId);
      } else if (metricType === "custom") {
        specialCalculation.push(kpiId);
      } else if (metricType === "base_approxCountDistinct") {
        approxCountDistinct.push(kpiId);
      }

      // Add to percentCalList if isPercentCalOn is true
      if (kpi.isPercentCalOn) percentCalList.push(kpiId);

      // Update metricsOrder
      metricsOrder[kpiId] = index;
    });
    const requestParam = {
      xAxis: [uniqueId],
      yAxis, // ["M001", "M002"]
      specialCalculation,
      approxCountDistinct,
      orderBy,
      percentCalList,
      // For time filters
      ...chartObjectForTimeFilters.requestParam,
      filter: filters,
    };

    const dimObject = getDimensionObjByOriginalID(
      uniqueId,
      allData.plotlyDimensions
    );

    const payload = {
      _id: uniqueId,
      emailId: user?.reqMetadata?.email,
      orgViewReq: orgViewReq,
      chartObject: {
        metadata: {
          dataLimit: 50,
          chartType: "table",
          title: dimObject._title,
        },
        requestParam: requestParam,
      },
    };
    const unwrapperChartObjectProps = { payload, user, allData };
    var selectedDimension = unwrapperChartObject(unwrapperChartObjectProps);
    // Reorder metricsList based on metricsOrder
    var reorderedMetricsList = lodashOrderBy(
      selectedDimension.metricsList,
      (item) => metricsOrder[item._id],
      ["asc"]
    );
    selectedDimension["metricsList"] = reorderedMetricsList;
    // TODO 1: Integrate logic into main unwrapperChartObject
    // TODO 2: Cater to compare logic
    selectedDimension["orderByType"] = "id_only";
    if (
      !selectedDimension.orderById.startsWith("M") &&
      !selectedDimension.orderById.startsWith("CM")
    ) {
      selectedDimension["orderById"] = "_dimension";
    }

    // // Add more keys which are not added in unwrapperChartObject
    // selectedDimension = { ...selectedDimension, percentCalList };

    // Update required objects
    selectedDimensionsVal[uniqueId] = selectedDimension;
    reloadEpochsVal[uniqueId] = Date.now();
    dataQEVal[uniqueId] = {
      status: "loading",
      message: "",
      result: [],
    };
  });
  const selectedDimTableMetricsVal = selectedMetricsInDimensionDwawer(
    selectedDimensionsVal
  );
  const selectedDimTablePercentCalListVal =
    selectedPercentCalListInDimensionDwawer(selectedDimensionsVal);

  // SELECTED KPIS
  layout.chart.forEach((row, index) => {
    const uniqueId = row.id;
    const kpiMetadata = getMeasureObjByOriginalID(
      uniqueId,
      allData.plotlyMetrics
    );
    const selectedKpi = {
      id: uniqueId,
      title: kpiMetadata._title,
      chartType: row.chartType,
      metricFilters: [],
      dimensionFilters: dimensionFiltersVal,
      timeFilters: timeFiltersVal,
      metadata: kpiMetadata,
      metricsList: [kpiMetadata],
      dimensionsList: [],
      orderById: uniqueId,
      orderBy: "desc",
    };

    // Update required objects
    selectedKpisVal[uniqueId] = selectedKpi;
    reloadEpochsVal[uniqueId] = Date.now();
    dataQEVal[uniqueId] = {
      status: "loading",
      message: "",
      result: [],
    };

    // M001
    reloadEpochsVal[uniqueId] = Date.now();
    dataQEVal[uniqueId] = {
      status: "loading",
      message: "",
      result: {
        value: 0,
        displayValue: "0",
      },
    };

    // M001_deltaPercentage
    reloadEpochsVal[`${uniqueId}_deltaPercentage`] = Date.now();
    dataQEVal[`${uniqueId}_deltaPercentage`] = {
      status: "loading",
      message: "",
      result: {
        value: 0,
        displayValue: "0",
      },
    };

    // M001_trueDelta
    reloadEpochsVal[`${uniqueId}_trueDelta`] = Date.now();
    dataQEVal[`${uniqueId}_trueDelta`] = {
      status: "loading",
      message: "",
      result: {
        value: 0,
        displayValue: "0",
      },
    };

    // M001_percentageTotalValue
    reloadEpochsVal[`${uniqueId}_percentageTotalValue`] = Date.now();
    dataQEVal[`${uniqueId}_percentageTotalValue`] = {
      status: "loading",
      message: "",
      result: {
        value: 0,
        displayValue: "0",
      },
    };

    // This is to cater to primary calendar
    let startEpoch = timeFiltersVal.selectedDatesQE.startDate;
    let endEpoch = timeFiltersVal.selectedDatesQE.endDate;
    let normalCalendarKey = `${uniqueId}_chart_${startEpoch}_${endEpoch}`;
    reloadEpochsVal[normalCalendarKey] = Date.now();
    dataQEVal[normalCalendarKey] = {
      status: "loading",
      message: "",
      result: {
        dataFromQE: [],
        extraData: {},
      },
    };

    // This is to cater to secondary calendar (all date ranges)
    timeFiltersVal.compareDates.forEach((row) => {
      let startEpoch = row.compareSelectedDatesQESameDuration.startDate;
      let endEpoch = row.compareSelectedDatesQESameDuration.endDate;
      let compareCalendarKey = `${uniqueId}_chart_${startEpoch}_${endEpoch}`;
      reloadEpochsVal[compareCalendarKey] = Date.now();
      dataQEVal[compareCalendarKey] = {
        status: "loading",
        message: "",
        result: {
          dataFromQE: [],
          extraData: {},
        },
      };
    });
  });

  // ORDER BY
  // 1. Sorted on dimension
  // 2. Sorted on metric
  // 3. GlobalSort is off
  const orderById = selectedDimTableMetricsVal[0]._id;
  const orderBy = "desc";
  const orderByType = "id_only";

  // LAYOUT ORDER
  const kpisLayout = lodashOrderBy(layout.chart, (item) => item.order, ["asc"]);
  const dimensionsLayout = lodashOrderBy(layout.table, (item) => item.order, [
    "asc",
  ]);
  const layoutVal = { kpis: kpisLayout, dimensions: dimensionsLayout };
  var metricGranDataVal = getValidMetricGranDataBasedOnDimListAndAccessList({
    plotlyDimensions: allData.plotlyDimensions,
    uiFeatureList: user.uiFeatureList,
  });
  const selectedDates = {
    startDate: timeFiltersVal.selectedDates.startDate.epoch,
    endDate: timeFiltersVal.selectedDates.endDate.epoch,
  };
  metricGranDataVal =
    manipulateAvailableMetricGranDataBasedOnSelectedDatesAndLimitList({
      metricGranData: metricGranDataVal,
      uiLimitsList: user.uiLimitsList,
      selectedDates: selectedDates,
    });
  const metricChartGranularityVal =
    getValidMetricChartGranularityBasedOnValidMetricGranData({
      metricGranData: metricGranDataVal,
      metricChartGranularity: granularity,
    });

  const initialWsForm = {
    id: {
      value: sheetId,
      status: "valid",
      message: "",
      stepTag: "",
    },
    name: {
      value: sheetName,
      status: "valid", //> C.valid
      message: "",
      stepTag: "details", //> C.stepTagTypes.details
    },
    rollingDateType: {
      value: progressiveDate === "true" ? "yes" : "no",
      status: "valid", //> C.valid,
      message: "",
      stepTag: "details", //> C.stepTagTypes.details
    },
    timeFilters: {
      value: timeFiltersVal,
      message: "",
      status: "valid", //> C.valid
      stepTag: "details", //> C.stepTagTypes.details
    },
    metricChartGranularity: {
      value: metricChartGranularityVal, // hour or day
      message: "",
      status: "valid", //> C.valid
      stepTag: "details", //> C.stepTagTypes.details
    },
    dimensionFilters: {
      value: dimensionFiltersVal,
      message: "",
      status: "valid", //> C.valid
      stepTag: "filters", //> C.stepTagTypes.details
    },
    selectedDimensions: {
      value: selectedDimensionsVal,
      status: "valid", //> C.valid
      message: "",
      stepTag: "dimensions", //> C.stepTagTypes.charts
    },
    selectedDimTableMetrics: {
      value: selectedDimTableMetricsVal,
      status: "valid", //> C.valid
      message: "",
      stepTag: "dimensions", //> C.stepTagTypes.charts
    },
    selectedDimTablePercentCalList: {
      value: selectedDimTablePercentCalListVal,
      status: "valid", //> C.valid
      message: "",
      stepTag: "dimensions", //> C.stepTagTypes.charts
    },
    dimTablesGridViewType: {
      value: dimTablesGridViewTypeVal, // single or double
      status: "valid", //> C.valid
      message: "",
      stepTag: "dimensions", //> C.stepTagTypes.charts
    },
    selectedKpis: {
      value: selectedKpisVal,
      status: "valid", //> C.valid
      message: "",
      stepTag: "metrics", //> C.stepTagTypes.charts
    },
    metricChartType: {
      value: metricChartType, // line or area or bar
      status: "valid", //> C.valid
      message: "",
      stepTag: "metrics", //> C.stepTagTypes.charts
    },
    dataQE: {
      value: dataQEVal,
      status: "valid",
      message: "",
      stepTag: "",
    },
    globalSort: {
      value: globalSort.status, // true or false
      status: "valid",
      message: "",
      stepTag: "",
    },
    reloadEpochs: {
      value: reloadEpochsVal,
      status: "valid",
      message: "",
      stepTag: "",
    },
    type: { value: "update", status: "valid", message: "", stepTag: "" },
    actualPayload: {
      value: backendWs,
      status: "valid",
      message: "",
      stepTag: "",
    },
    layout: {
      value: layoutVal,
      status: "valid",
      message: "",
      stepTag: "",
    },
    // TODO: PENDING
    orderById: {
      value: orderById,
      status: "valid", //> C.valid
      message: "",
      stepTag: "dimensions", //> C.stepTagTypes.charts
    },
    orderBy: {
      value: orderBy, // asc or desc
      status: "valid", //> C.valid
      message: "",
      stepTag: "dimensions", //> C.stepTagTypes.charts
    },
    orderByType: {
      value: orderByType, // id_only or delta_percentage or delta_abs
      status: "valid", //> C.valid
      message: "",
      stepTag: "dimensions", //> C.stepTagTypes.charts
    },
    msv: {
      value: {
        isOpen: false,
        metadata: { workspaceSelections: {}, msvTableId: "" },
      },
      status: "valid",
      message: "",
      stepTag: "",
    },
    isItMsvForm: { value: false, status: "valid", message: "", stepTag: "" },
    dataLimit: {
      value: dataLimitVal,
      status: "valid",
      message: "",
      stepTag: "",
    },
    // This is to control the screen like drawer state, etc
    screen: {
      value: initialWsFormScreenValue,
      status: "valid",
      message: "",
      stepTag: "",
    },
    autosaveApiStatus: {
      value: "success", // success/loading
      status: "valid",
      message: "",
      stepTag: "",
    },
    utils: {
      value: {
        uiLimitsList: user.uiLimitsList,
        uiFeatureList: user.uiFeatureList,
        plotlyDimensions: allData.plotlyDimensions,
        plotlyMetrics: allData.plotlyMetrics,
      },
      status: "valid",
      message: "",
      stepTag: "",
    },
    metricGranData: {
      value: metricGranDataVal,
      status: "valid",
      message: "",
      stepTag: "",
    },
    metricChartHover: {
      hoverFlag: false,
      meticId: "",
      pointNumber: 0,
    },
  };

  return initialWsForm;
  // return getInitialWsForm({ user });
};

const wrapperWs = (props = {}) => {
  const { wsForm = {}, user = {}, allData = {}, workbookId = "" } = props;
  const {
    id = {},
    name = {},
    timeFilters = {},
    layout = {},
    metricChartGranularity = {},
    selectedKpis = {},
    selectedDimensions = {},
    dimensionFilters = {},
    rollingDateType = {},
    globalSort = {},
    dimTablesGridViewType = {},
    dataLimit = {},
    metricChartType = {},
  } = wsForm;

  // * DEBUGGER
  // console.groupCollapsed("wrapperWs");
  // console.log("props", props);
  // console.log("wsForm", wsForm);
  // console.groupEnd();

  // * Define required fields for WorksheeetObject
  const sheetId = id.value;
  const sheetName = name.value;
  const dateRangeType = getDatePresetObj(
    timeFilters.value.selectedDatePreset,
    allDatePresets,
    "id"
  ).name;
  let primaryComparisonDatesUi = timeFilters.value.compareDates.find(
    (row) => row.isSelected
  );
  if (!primaryComparisonDatesUi) {
    // TODO : Check why in unwrapper it's false for all rows
    primaryComparisonDatesUi = timeFilters.value.compareDates[0];
  }
  let primaryComparisonDatesUiId = primaryComparisonDatesUi.id;
  const secondaryComparisonDatesUi = timeFilters.value.compareDates.filter(
    (row) => row.id !== primaryComparisonDatesUiId
  );
  const comparisonStartDate =
    primaryComparisonDatesUi.compareSelectedDatesQESameDuration.startDate.toString();
  // ! MOST IMPORTANT
  // ! SUBTRACT one hour because angular time filters subtracts one hour while saving
  let comparisonEndDate =
    primaryComparisonDatesUi.compareSelectedDatesQESameDuration.endDate -
    1 * 60 * 60 * 1000;
  comparisonEndDate = comparisonEndDate.toString();
  comparisonEndDate = comparisonEndDate.toString();
  // ! MOST IMPORTANT
  // ! SUBTRACT one hour because angular time filters subtracts one hour while saving
  let endDate = timeFilters.value.selectedDatesQE.endDate - 1 * 60 * 60 * 1000;
  endDate = endDate.toString();
  const dateRange = {
    startDate: timeFilters.value.selectedDatesQE.startDate.toString(),
    endDate: endDate,
    comparisonStartDate,
    comparisonEndDate,
    dateRangeType: dateRangeType,
    progressiveDate: rollingDateType.value === "yes" ? "true" : "false",
    compareDateRangeType: "Custom", // TODO: Check if timeFilters compare in react automatically takes care of this value
  };
  const granularity = metricChartGranularity.value;
  const timeZone = {
    name: timeFilters.value.selectedTimezone.name,
    location: timeFilters.value.selectedTimezone.location,
  };
  const comparisonMode = timeFilters.value.isComparisonOn;
  const secondaryComparisonDates = secondaryComparisonDatesUi.map((row) => {
    // ! MOST IMPORTANT
    // ! SUBTRACT one hour because angular time filters subtracts one hour while saving
    let endDate =
      row.compareSelectedDatesQESameDuration.endDate - 1 * 60 * 60 * 1000;
    endDate = endDate.toString();
    return {
      startDate: row.compareSelectedDatesQESameDuration.startDate.toString(),
      endDate: endDate,
      dateRangeType: "Custom", // TODO: Check if timeFilters compare in react automatically takes care of this value
    };
  });
  const compareColForMetricsInDimTables =
    config.hardCoded.compareDateTypeBackendMapping[
      timeFilters.value.comparisonType
    ];

  const table = layout.value.dimensions.map((el, index) => {
    const uniqueId = el.id;
    const dimRow = selectedDimensions.value[uniqueId];
    const percentCalListIds = dimRow.percentCalList.map((row) => row._id);
    const metrics = dimRow.metricsList.map((row, index) => ({
      metricId: row._id,
      order: index,
      isPercentCalOn: percentCalListIds.includes(row._id),
      compareCol: compareColForMetricsInDimTables,
    }));
    let orderById =
      dimRow.orderById === "_dimension" ? dimRow.id : dimRow.orderById;
    if (dimRow.orderByType === "id_trueDelta") orderById += "_trueDelta";
    if (dimRow.orderByType === "id_deltaPercentage")
      orderById += "_deltaPercentage";
    const sortList = [{ id: orderById, desc: dimRow.orderBy === "desc" }];
    const newEl = {
      id: uniqueId,
      order: index,
      chartType: "table", // ! HARD CODED
      // TODO ! Hard coded
      size: {
        width: 50,
        height: 0,
      },
      metrics: metrics,
      sortList: sortList,
    };
    return newEl;
  });
  const chart = layout.value.kpis.map((row, index) => ({
    id: row.id,
    order: index,
    chartType: metricChartType.value,
  }));
  const newLayout = {
    table,
    chart,
  };
  const metrics = Object.keys(selectedKpis.value)[0]; // TODO
  const dimensions = Object.keys(selectedDimensions.value)[0]; // TODO
  const isTableExpanded = dimTablesGridViewType.value === "single";
  const orgViewReq = {
    organization: user?.reqMetadata?.organization,
    view: user?.reqMetadata?.view,
  };
  const email = user?.reqMetadata?.email;
  const worksheet = {
    sheetId,
    sheetName,
    isActive: true, // Since update being is done on this workspace, make it active
    worksheetObject: {
      dateRange,
      granularity,
      timeZone,
      limit: dataLimit.value,
      multiSelectLimit: 1000,
      downloadLimit: 5000,
      comparisonMode,
      layout: newLayout,
      filters: transformFiltersUiToBackend([...dimensionFilters.value]),
      // TODO
      recentDownload: {
        metrics: [], // TODO
        dimensions: [], // TODO
        orderBy: [], // TODO
      },
      secondaryComparisonDates,
      globalSort: {
        status: globalSort.value,
      },
      isTableExpanded: {
        status: isTableExpanded,
      },
    },
  };
  const finalPayload = {
    email,
    orgViewReq,
    workbookId,
    worksheet,
  };

  return finalPayload;
};

const plotlyDimensionsToDimensionDwawer = (data) => {
  //Function to filter only those dimensions whose dataType equal to string or timestamp
  const finalDimData = getValidDimensionsList(data);
  return finalDimData.map((item) => {
    return {
      id: item._id,
      title: item.dimTitle,
      disabled: !item.status,
    };
  });
};

const plotlyMetricsToDimensionDwawer = (data) => {
  return data.map((item) => {
    return {
      _id: item._id,
      _title: item._title,
      measureTitle: item._title,
    };
  });
};

const selectedDimensionsInDimensionDwawer = (data) => {
  let selectedDimension = [];
  for (const property in data) {
    selectedDimension.push(data[property]);
  }

  return selectedDimension.map((item) => {
    return {
      id: item.id,
    };
  });
};

const selectedMetricsInDimensionDwawer = (data) => {
  let unionMetrics = [];
  let unionMetricsIds = [];
  for (const property in data) {
    data[property].metricsList.forEach((item) => {
      if (!unionMetricsIds.includes(item._id)) {
        unionMetricsIds.push(item._id);
        unionMetrics.push(item);
      }
    });
  }
  return unionMetrics;
  // return plotlyMetricsToDimensionDwawer(unionMetrics);
};

const selectedPercentCalListInDimensionDwawer = (data) => {
  let unionMetrics = [];
  let unionMetricsIds = [];
  for (const property in data) {
    data[property].percentCalList.forEach((item) => {
      if (!unionMetricsIds.includes(item._id)) {
        unionMetricsIds.push(item._id);
        unionMetrics.push(item);
      }
    });
  }
  return unionMetrics;
  // return plotlyMetricsToDimensionDwawer(unionMetrics);
};

const wrapperDimDownloadRequest = (selections, user) => {
  const payload = wrapperChartObject({
    selections: { ...selections, dataLimit: 5000 },
    user,
  });

  let ordervalue = 0;
  let reqObj = {
    isComparisonOn: selections.timeFilters.isComparisonOn,
    comparisonDate: {
      startDate:
        selections.timeFilters.compareDates[0].compareSelectedDatesQE.startDate.toString(),
      endDate:
        selections.timeFilters.compareDates[0].compareSelectedDatesQE.endDate.toString(),
    },
    metricOrder: selections.metricsList.map((row) => {
      return {
        id: row._id,
        order: ordervalue++,
        metricType: row._id.includes("CM") ? "custom" : "basic",
      };
    }),

    downloadType: "DownloadRecords",
    payload: {
      metadata: payload.chartObject.metadata,
      requestParam: payload.chartObject.requestParam,
    },
    orgViewReq: wrapperOrgViewReq(user),
    feature: "snapshot",
  };

  return reqObj;
};

const wrapperMetricDownloadRequest = (props = {}) => {
  const { selections, user } = props;

  let payload = wrapperChartObject({
    selections: { ...selections, dataLimit: 5000 },
    user,
  });

  let modifyOrderByOnlyForChartInstantDownload = {
    ...payload,
    chartObject: {
      ...payload.chartObject,
      requestParam: {
        ...payload.chartObject.requestParam,
        orderBy: {
          dimOrdByList: [
            { id: payload.chartObject.requestParam.xAxis[0], desc: false },
          ],
        },
      },
    },
  };

  payload = modifyOrderByOnlyForChartInstantDownload;

  let ordervalue = 0;
  let reqObj = {
    isComparisonOn: selections.timeFilters.isComparisonOn,
    comparisonDate: {
      startDate:
        selections.timeFilters.compareDates[0].compareSelectedDatesQE.startDate.toString(),
      endDate:
        selections.timeFilters.compareDates[0].compareSelectedDatesQE.endDate.toString(),
    },
    metricOrder: selections.metricsList.map((row) => {
      return {
        id: row._id,
        order: ordervalue++,
        metricType: row._id.includes("CM") ? "custom" : "basic",
      };
    }),
    downloadType: "DownloadRecords",
    payload: { ...payload.chartObject },
    orgViewReq: wrapperOrgViewReq(user),
    feature: "snapshot",
  };

  return reqObj;
};

const getCommonMetricsFromAllDimensions = (selections) => {
  const allMetricsInSelectedDimensions = selections.map((row) => row.metrics);
  var commonMetricsInAllSelectedDimensions = [];
  if (allMetricsInSelectedDimensions.length > 0) {
    commonMetricsInAllSelectedDimensions =
      allMetricsInSelectedDimensions.reduce((a, b) =>
        a.filter((c) => b.includes(c))
      );
  }

  // This is the case when there is commonMetricsInAllSelectedDimensions is empty from the selected  dimension from the Dimension drawer this cause no api call
  if (commonMetricsInAllSelectedDimensions.length === 0) {
    commonMetricsInAllSelectedDimensions = [
      allMetricsInSelectedDimensions[0][0],
    ];
  }

  return commonMetricsInAllSelectedDimensions;
};

const makeUiRowDataFriendlyForWsDimTable = (props = {}) => {
  const { getDataResponse, columns } = props;

  // * DEBUGGER
  // console.groupCollapsed("makeUiRowDataFriendly");
  // console.log("getDataResponse", getDataResponse);
  // console.log("columns", columns);
  // console.groupEnd();

  const currentcoldata = columns;

  const newRowData = getDataResponse.map((dataArr, index) => {
    let metricData = {};
    for (let i = 1; i < currentcoldata.length; i++) {
      const temp = {
        [currentcoldata[i].accessor]: dataArr[currentcoldata[i].accessor],
      };
      metricData = { ...metricData, ...temp };
    }
    const rowData = {
      [currentcoldata[0].accessor]: dataArr[currentcoldata[0].accessor],
      ...metricData,
    };
    return rowData;
  });
  // console.log("makeUiRowDataFriendly", newRowData);
  return newRowData;
};

const getWsDimTableColumns = (
  dimList,
  metricList,
  timeFilters,
  percentCalList
) => {
  if (timeFilters.isComparisonOn) {
    let newDimObj = dimList.map((dimListObj) => {
      let tempObj = {
        id: dimListObj._id,
        dimId: dimListObj.dimID,
        name: "Name",
        orderBy: "desc",
        accessor: dimListObj.dimID,
        actualPayload: dimListObj,
        metricId: dimListObj._id,
      };

      let newMericObj = [];
      metricList.map((metricListObj) => {
        const normal = {
          id: metricListObj._id,
          name: metricListObj._title,
          orderBy: "desc",
          type: metricListObj._id.startsWith("M") ? "metric" : "customMetric",
          accessor: metricListObj._id.startsWith("M")
            ? metricListObj.measureID
            : metricListObj._id,
          actualPayload: metricListObj,
          metricId: metricListObj._id,
        };
        newMericObj.push(normal);

        if (percentCalList.length > 0) {
          const percentCalListMetric = percentCalList.some(
            (item) => item.measureID === metricListObj.measureID
          );
          if (percentCalListMetric) {
            const newCol = {
              id: metricListObj._id,
              name: "% of Total",
              orderBy: "desc",
              type: "percentTotal",
              accessor: `${
                metricListObj._id.startsWith("M")
                  ? metricListObj.measureID
                  : metricListObj._id
              }_percent`,
              actualPayload: metricListObj,
              metricId: `${metricListObj._id}_percent`,
            };
            newMericObj.push(newCol);
          }
        }
        if (timeFilters.comparisonType === "abs_change") {
          const trueDelta = {
            id: metricListObj._id,
            // name: `${metricListObj._title}_trueDelta`,
            name: "Change",
            orderBy: "desc",
            accessor: `${
              metricListObj._id.startsWith("M")
                ? metricListObj.measureID
                : metricListObj._id
            }_trueDelta`,
            actualPayload: metricListObj,
            metricId: `${metricListObj._id}_trueDelta`,
          };
          newMericObj.push(trueDelta);
        }
        if (timeFilters.comparisonType === "rel_change") {
          const deltaPercentage = {
            id: metricListObj._id,
            // name: `${metricListObj._title}_deltaPercentage`,
            name: "% Change",
            orderBy: "desc",
            accessor: `${
              metricListObj._id.startsWith("M")
                ? metricListObj.measureID
                : metricListObj._id
            }_deltaPercentage`,
            actualPayload: metricListObj,
            metricId: `${metricListObj._id}_deltaPercentage`,
          };
          newMericObj.push(deltaPercentage);
        }
        if (timeFilters.comparisonType === "both") {
          const deltaPercentage = {
            id: metricListObj._id,
            // name: `${metricListObj._title}_deltaPercentage`,
            name: "% Change",
            orderBy: "desc",
            accessor: `${
              metricListObj._id.startsWith("M")
                ? metricListObj.measureID
                : metricListObj._id
            }_deltaPercentage`,
            actualPayload: metricListObj,
            metricId: `${metricListObj._id}_deltaPercentage`,
          };
          newMericObj.push(deltaPercentage);
          const trueDelta = {
            id: metricListObj._id,
            // name: `${metricListObj._title}_trueDelta`,
            name: "Change",
            orderBy: "desc",
            accessor: `${
              metricListObj._id.startsWith("M")
                ? metricListObj.measureID
                : metricListObj._id
            }_trueDelta`,
            actualPayload: metricListObj,
            metricId: `${metricListObj._id}_trueDelta`,
          };
          newMericObj.push(trueDelta);
        }
      });

      newMericObj.unshift(tempObj);
      return newMericObj;
    });
    return newDimObj;
  }

  // without the isComaprision ON
  else {
    let newDimObj = dimList.map((dimListObj) => {
      let tempObj = {
        id: dimListObj._id,
        dimId: dimListObj.dimID,
        name: "Name",
        orderBy: "desc",
        accessor: dimListObj.dimID,
        actualPayload: dimListObj,
        metricId: dimListObj._id,
      };
      let newMericObj = [];
      metricList.map((metricListObj) => {
        const normal = {
          id: metricListObj._id,
          name: metricListObj._title,
          orderBy: "desc",
          type: metricListObj._id.startsWith("M") ? "metric" : "customMetric",
          accessor: metricListObj._id.startsWith("M")
            ? metricListObj.measureID
            : metricListObj._id,
          actualPayload: metricListObj,
          metricId: metricListObj._id,
        };
        newMericObj.push(normal);
        if (percentCalList.length > 0) {
          const percentCalListMetric = percentCalList.some(
            (item) => item.measureID === metricListObj.measureID
          );
          if (percentCalListMetric) {
            const newCol = {
              id: metricListObj._id,
              name: "% of Total",
              orderBy: "desc",
              type: "percentTotal",
              accessor: `${
                metricListObj._id.startsWith("M")
                  ? metricListObj.measureID
                  : metricListObj._id
              }_percent`,
              actualPayload: metricListObj,
              metricId: `${metricListObj._id}_percent`,
            };
            newMericObj.push(newCol);
          }
        }
      });
      newMericObj.unshift(tempObj);
      return newMericObj;
    });
    return newDimObj;
  }
};

const formatDimValueWsDimTable = (props = {}) => {
  const { row = {}, cell = {}, selections = {} } = props;

  // * DEBUGGER
  // console.groupCollapsed("formatDimValueWsDimTable");
  // console.log("props", props);
  // console.groupEnd();

  const accessor = cell.accessor;
  const attributeObj = cell.actualPayload;
  let actualValue = row[accessor]; // 2113452.1341212
  let displayValue = actualValue; // 2,113,452.13 OR $5,254 OR 23.21%
  let tooltipValue = actualValue; // 2,113,452.13 OR $5,254 OR 23.21%
  let changeType = "neutral"; // one of neutral/negChangeColor/posChangeColor
  let valueType = "normalMetric"; // one of timeDim/nonTimeDim/normalMetric/trueDelta/deltaPercentage/percentOfTotal/NA
  let isCompareCell = false;
  let compareIconName = ""; // one of empty_string/arrow_drop_up/arrow_drop_down
  let textColorKey = "secondaryColorLight"; // default color
  const minutesOffset = selections.timeFilters.selectedTimezone.minutesOffset;
  const isCellDimension = cell.id.startsWith("D");

  // If the cell is dimension
  if (isCellDimension) {
    switch (accessor) {
      // In case of time dimension, displayValue = tooltipValue = actualValue
      case "hour":
        displayValue = moment(Number(actualValue))
          .utcOffset(minutesOffset)
          .format("MM-DD HH:mm");
        tooltipValue = displayValue;
        valueType = "timeDim";
        break;
      case "day":
        displayValue = moment(Number(actualValue))
          .utcOffset(minutesOffset)
          .format("YYYY-MM-DD");
        tooltipValue = displayValue;
        valueType = "timeDim";
        break;
      case "month":
        displayValue = moment(Number(actualValue))
          .utcOffset(minutesOffset)
          .format("YYYY-MM");
        tooltipValue = displayValue;
        valueType = "timeDim";
        break;
      default:
        valueType = "nonTimeDim";
    }
  } else {
    // Else if the cell is a metric
    // In case of metrics, values depend on the type of accessor and value
    valueType = cell.accessor.includes("_trueDelta")
      ? "trueDelta"
      : cell.accessor.includes("_deltaPercentage")
      ? "deltaPercentage"
      : cell.accessor.includes("_percent")
      ? "percentOfTotal"
      : "normalMetric";
    switch (valueType) {
      case "normalMetric":
        displayValue =
          actualValue === "NA"
            ? actualValue
            : appendMetricSym(
                actualValue,
                attributeObj.dataUnit,
                true
              ).toUpperCase();
        tooltipValue =
          actualValue === "NA" ? actualValue : d3Format(",")(actualValue);
        break;
      case "trueDelta":
        displayValue =
          actualValue === "NA"
            ? actualValue
            : appendMetricSym(
                actualValue,
                attributeObj.dataUnit,
                true
              ).toUpperCase();
        tooltipValue =
          actualValue === "NA" ? actualValue : d3Format(",")(actualValue);
        changeType =
          parseFloat(actualValue) < 0 ? "negChangeColor" : "posChangeColor";
        isCompareCell = true;
        textColorKey = changeType;
        compareIconName =
          changeType === "negChangeColor" ? "arrow_drop_down" : "arrow_drop_up";
        break;
      case "deltaPercentage":
        displayValue =
          actualValue === "NA"
            ? actualValue
            : `${appendMetricSym(
                actualValue,
                {
                  dType: "String",
                  value: "String",
                },
                true
              ).toUpperCase()}%`;
        tooltipValue =
          actualValue === "NA"
            ? actualValue
            : `${d3Format(",.2f")(actualValue)}%`;
        changeType =
          parseFloat(actualValue) < 0 ? "negChangeColor" : "posChangeColor";
        isCompareCell = true;
        textColorKey = changeType;
        compareIconName =
          changeType === "negChangeColor" ? "arrow_drop_down" : "arrow_drop_up";
        break;
      case "percentOfTotal":
        displayValue =
          actualValue === "NA"
            ? actualValue
            : `${d3Format(",.2f")(actualValue)}%`;
        tooltipValue =
          actualValue === "NA"
            ? actualValue
            : `${d3Format(",.2f")(actualValue)}%`;
        break;
      default:
        displayValue =
          actualValue === "NA"
            ? actualValue
            : appendMetricSym(
                actualValue,
                attributeObj.dataUnit,
                true
              ).toUpperCase();
        tooltipValue =
          actualValue === "NA" ? actualValue : d3Format(",")(actualValue);
        break;
    }
  }

  const response = {
    actualValue: actualValue,
    displayValue: displayValue,
    tooltipValue: tooltipValue,
    changeType: changeType,
    valueType: valueType,
    isCompareCell: isCompareCell,
    compareIconName: compareIconName,
    textColorKey: textColorKey,
  };
  return response;
};

const getBookmarkDetails = (location) => {
  // * This function will work correctly only when the URLs are of one of the 2:
  // 1. /analyze/dashboard?id=<bookmardId>
  // 2. /home/dash?id=<bookmardId>
  // ! It will return a false positive for URLs like below
  // 1. /datastory?id=<bookmardId>
  // 2. /pivot?id=<bookmardId>
  // * HENCE, this function should only be called from those places where the route is a valid one
  // in components like AnalyzeDashboardContainer and AnalyzeDashboardAngularShareCompatible
  const bookmarkIdQueryString = location.search;

  // ! This feature is present in react-router v6 in a hook called useSearchParams
  // ! But we are using v5, hence this approach
  let paramsString = bookmarkIdQueryString.replace("?", "");
  let searchParams = new URLSearchParams(paramsString);
  const bookmarkId = searchParams.get("id");

  var isSharedUrlFlag = false;
  if (bookmarkIdQueryString) isSharedUrlFlag = true;
  return { isSharedUrlFlag, bookmarkId };
};

const wrapperUpdateWorksheetStatus = (props = {}) => {
  const { worksheet = {}, workbook = {} } = props;

  const updatedWorksheetList = workbook.worksheetList.map((row) => ({
    ...row,
    isActive: row.sheetId === worksheet.sheetId,
  }));
  const requestPayload = {
    ...workbook,
    isActive: true,
    worksheetList: updatedWorksheetList,
  };

  return requestPayload;
};

const makeMetricsDataConsistent = (props = {}) => {
  const { data: response = [], allData = [], isComparisonOn = false } = props;
  const { plotlyMetrics = [] } = allData;
  // * Function Usage and Need
  // getData call for metrics returns a structure like below
  // const sampleRes = {
  //   status: {
  //     statusCode: "200",
  //     statusMessage: "Success",
  //   },
  //   result: {
  //     data: [
  //       {
  //         AllRequests: "1483736374959",
  //         MarketImpressions: "7900944021",
  //         CM012: "29191910.766606",
  //         CM016: "1.796313293036303",
  //       },
  //     ],
  //   },
  // };
  // It has a combination of _id and measureID which makes it difficult in the UI
  // This functions makes the response uniform and converts array to object for easier access to values
  // Input:
  // response = [
  //   {
  //     AllRequests: "1483736374959",
  //     MarketImpressions: "7900944021",
  //     CM012: "29191910.766606",
  //     CM016: "1.796313293036303",
  //   },
  // ];
  // Output:
  // finalRes = {
  //   M001: {
  //     value: "1496709770448",
  //     displayValue: "1.5 t",
  //   },
  //   M004: {
  //     value: "7967963255",
  //     displayValue: "7.97 b",
  //   },
  //   CM012: {
  //     value: "29350469.43151",
  //     displayValue: "29.35 m",
  //   },
  //   CM016: {
  //     value: "1.7970708331606129",
  //     displayValue: "$1.8 ",
  //   },
  // };
  let finalRes = {};
  const responseFirstEle = response[0];
  let status = "success";
  Object.keys(responseFirstEle).forEach((key) => {
    try {
      // Run it only for keys that don't contain _deltaPercentage or _trueDelta
      const runFlag =
        !key.includes("_trueDelta") && !key.includes("_deltaPercentage");
      if (runFlag) {
        if (key.startsWith("CM")) {
          let metricObj = getMeasureObjByOriginalID(key, plotlyMetrics);
          let value = responseFirstEle[key];
          let displayValue = appendMetricSym(value, metricObj.dataUnit, true);
          finalRes[key] = { value, displayValue };
          if (isComparisonOn) {
            finalRes[`${key}_trueDelta`] = {
              value: responseFirstEle[`${key}_trueDelta`],
              displayValue: appendMetricSym(
                responseFirstEle[`${key}_trueDelta`],
                metricObj.dataUnit,
                true
              ),
            };
            finalRes[`${key}_deltaPercentage`] = {
              value: responseFirstEle[`${key}_deltaPercentage`],
              displayValue: `${Number(
                responseFirstEle[`${key}_deltaPercentage`]
              ).toFixed(2)}%`,
            };
          }
        } else {
          // get original id from measureId
          // get M001 from "AllRequests"
          let reqKey = getOriginalIdFromMeasureId(key, plotlyMetrics);
          let metricObj = getMeasureObjByOriginalID(reqKey, plotlyMetrics);
          let value = responseFirstEle[key];
          let displayValue = appendMetricSym(value, metricObj.dataUnit, true);
          finalRes[reqKey] = { value, displayValue };
          if (isComparisonOn) {
            finalRes[`${reqKey}_trueDelta`] = {
              value: responseFirstEle[`${key}_trueDelta`],
              displayValue: appendMetricSym(
                responseFirstEle[`${key}_trueDelta`],
                metricObj.dataUnit,
                true
              ),
            };
            finalRes[`${reqKey}_deltaPercentage`] = {
              value: responseFirstEle[`${key}_deltaPercentage`],
              displayValue: `${Number(
                responseFirstEle[`${key}_deltaPercentage`]
              ).toFixed(2)}%`,
            };
          }
        }
      }
    } catch (error) {
      console.error("UI ERROR");
      console.groupCollapsed("DETAILS");
      console.log(error);
      console.groupEnd();
      status = "error";
      finalRes[key] = { value: 0, displayValue: "0" };
    }
  });
  return { response: finalRes, status };
};

const makeMetricsTotalDataConsistent = (props = {}) => {
  const {
    data: response = [],
    allData = [],
    comparisionTotalValue = false,
  } = props;
  const { plotlyMetrics = [] } = allData;

  let finalRes = {};
  const responseFirstEle = response[0];
  let status = "success";
  Object.keys(responseFirstEle).forEach((key) => {
    try {
      // Run it only for keys that don't contain _deltaPercentage or _trueDelta
      const runFlag =
        !key.includes("_trueDelta") && !key.includes("_deltaPercentage");
      if (runFlag) {
        if (key.startsWith("CM")) {
          let metricObj = getMeasureObjByOriginalID(key, plotlyMetrics);
          let value = responseFirstEle[key];
          let displayValue = appendMetricSymPercentageTotal(
            value,
            metricObj.dataUnit,
            true
          );

          if (comparisionTotalValue) {
            let value = responseFirstEle[key];
            finalRes[`${key}_percentageTotalValue`] = { value, displayValue };
          }
        } else {
          // get original id from measureId
          // get M001 from "AllRequests"
          let reqKey = getOriginalIdFromMeasureId(key, plotlyMetrics);
          let metricObj = getMeasureObjByOriginalID(reqKey, plotlyMetrics);
          let value = responseFirstEle[key];
          let displayValue = appendMetricSymPercentageTotal(
            value,
            metricObj.dataUnit,
            true
          );

          if (comparisionTotalValue) {
            let value = responseFirstEle[key];
            finalRes[`${reqKey}_percentageTotalValue`] = {
              value,
              displayValue,
            };
          }
        }
      }
    } catch (error) {
      console.error("UI ERROR");
      console.groupCollapsed("DETAILS");
      console.log(error);
      console.groupEnd();
      status = "error";
      finalRes[key] = { value: 0, displayValue: "0" };
    }
  });
  return { response: finalRes, status };
};
const makeMetricsChartDataConsistent = (props = {}) => {
  const {
    data = [],
    metricsList = [],
    granDimObject = {},
    dateRange = {},
  } = props;

  try {
    const finalRes = {};
    for (const metric of metricsList) {
      const metricId = metric._id;
      const startEpoch = dateRange.startDate;
      const endEpoch = dateRange.endDate;
      const metricChartId = `${metricId}_chart_${startEpoch}_${endEpoch}`;
      const dimId = granDimObject.dimID;
      const measureId = metric.measureID;
      const dataFromQE = data.map((row) => ({
        [dimId]: row[dimId],
        [measureId]: row[measureId],
      }));
      finalRes[metricChartId] = {
        dataFromQE: dataFromQE,
        extraData: {},
      };
    }
    return {
      response: finalRes,
      status: "success",
    };
  } catch (err) {
    const finalRes = {};
    for (const metric of metricsList) {
      const metricId = metric._id;
      const startEpoch = dateRange.startDate;
      const endEpoch = dateRange.endDate;
      const metricChartId = `${metricId}_chart_${startEpoch}_${endEpoch}`;
      finalRes[metricChartId] = {
        dataFromQE: [],
        extraData: {},
      };
    }
    return { response: finalRes, status: "error" };
  }
};

const makeMetricsChartDataConsistentMultiFilter = (props = {}) => {
  const {
    data = [],
    metricsList = [],
    granDimObject = {},
    filterName = "",
  } = props;

  try {
    const finalRes = {};
    for (const metric of metricsList) {
      const metricId = metric._id;
      const metricChartId = `${metricId}_chart_${filterName}`;
      const dimId = granDimObject.dimID;
      const measureId = metric.measureID;
      const dataFromQE = data.map((row) => ({
        [dimId]: row[dimId],
        [measureId]: row[measureId],
      }));
      finalRes[metricChartId] = {
        dataFromQE: dataFromQE,
        extraData: {},
      };
    }
    return {
      response: finalRes,
      status: "success",
    };
  } catch (err) {
    const finalRes = {};
    for (const metric of metricsList) {
      const metricId = metric._id;
      const metricChartId = `${metricId}_chart_${filterName}`;
      finalRes[metricChartId] = {
        dataFromQE: [],
        extraData: {},
      };
    }
    return { response: finalRes, status: "error" };
  }
};

const getAddMetricDrawerInitialSelectionsForMsv = (wsForm) => {
  const activeMsvTableId = wsForm.activeMsvTableId.value;
  const activeMsvTableObject =
    wsForm.selectedDimensions.value[activeMsvTableId];
  const tableMetrics = activeMsvTableObject.metricsList.map((row) => row._id);
  const result = {
    chartMetrics: Object.keys(wsForm.selectedKpis.value),
    tableMetrics: tableMetrics,
  };
  return result;
};
const getAddMetricDrawerInitialSelections = (wsForm) => {
  const allMetricsListInSelectedDimensions = Object.values(
    wsForm.selectedDimensions.value
  ).map((selectedDimension) =>
    selectedDimension.metricsList.map((row) => row._id)
  );
  var commonMetricsInAllSelectedDimensions = [];
  if (allMetricsListInSelectedDimensions.length > 0) {
    commonMetricsInAllSelectedDimensions =
      allMetricsListInSelectedDimensions.reduce((a, b) =>
        a.filter((c) => b.includes(c))
      );
  }
  const result = {
    chartMetrics: Object.keys(wsForm.selectedKpis.value),
    tableMetrics: commonMetricsInAllSelectedDimensions,
  };
  return result;
};
const getUnionOfAllMetricsInDimensionTables = (wsForm) => {
  var commonMetrics = [];
  const selectedDimensionsVal = Object.values(wsForm.selectedDimensions.value);
  for (const dimRow of selectedDimensionsVal) {
    for (const metricRow of dimRow.metricsList) {
      const commonMetricsIds = commonMetrics.map((row) => row.id);
      // To not push duplicate ids
      if (!commonMetricsIds.includes(metricRow._id)) {
        const newMetricRow = {
          id: metricRow._id,
          name: metricRow._title,
          disabled: false,
        };
        commonMetrics.push(newMetricRow);
      }
    }
  }
  // Order by title
  commonMetrics = orderBy(commonMetrics, (row) => row.title, ["asc"]);
  return commonMetrics;
};

// Making it a separate function as it's used at 3 places
const dispatchErrorForMetricChartsOnApiFailure = (props = {}) => {
  const {
    metricsList,
    wsForm,
    updateStandaloneWsFormMetricsChartsData,
    ReduxDispatcher,
  } = props;
  const finalRes = {};
  for (const metric of metricsList) {
    const metricId = metric._id;
    const startEpoch = wsForm.timeFilters.value.selectedDatesQE.startDate;
    const endEpoch = wsForm.timeFilters.value.selectedDatesQE.endDate;
    const metricChartId = `${metricId}_chart_${startEpoch}_${endEpoch}`;
    finalRes[metricChartId] = {
      ...SIGVIEW_CONTANTS.defaultQeData.result,
    };
  }
  // Update wsForm.dataQE to show error
  var payload = {
    metricsChartsDataFromQE: finalRes,
    metricsChartsDataFromQEStatus: "error",
  };
  var action = updateStandaloneWsFormMetricsChartsData(payload);
  ReduxDispatcher(action);
};

const dispatchErrorForMetricChartsOnNoData = (props = {}) => {
  const {
    metricsList,
    wsForm,
    updateStandaloneWsFormMetricsChartsData,
    ReduxDispatcher,
  } = props;
  const finalRes = {};
  for (const metric of metricsList) {
    const metricId = metric._id;
    const startEpoch = wsForm.timeFilters.value.selectedDatesQE.startDate;
    const endEpoch = wsForm.timeFilters.value.selectedDatesQE.endDate;
    const metricChartId = `${metricId}_chart_${startEpoch}_${endEpoch}`;
    finalRes[metricChartId] = {
      ...SIGVIEW_CONTANTS.defaultQeData.result,
    };
  }
  // Update wsForm.dataQE to show error
  var payload = {
    metricsChartsDataFromQE: finalRes,
    metricsChartsDataFromQEStatus: "noData",
  };
  var action = updateStandaloneWsFormMetricsChartsData(payload);
  ReduxDispatcher(action);
};

const dispatchErrorForMsvMetricChartsOnApiFailure = (props = {}) => {
  const {
    metricsList,
    wsForm,
    updateStandaloneWsFormMetricsChartsData,
    ReduxDispatcher,
  } = props;
  const finalRes = {};
  const selectedTableItemInChart = wsForm.selectedTableItemInChart.value;
  for (const metric of metricsList) {
    const metricId = metric._id;
    for (const dimValue of selectedTableItemInChart) {
      const metricChartId = `${metricId}_chart_${dimValue}`;
      finalRes[metricChartId] = {
        ...SIGVIEW_CONTANTS.defaultQeData.result,
      };
    }
  }
  // Update wsForm.dataQE to show error
  var payload = {
    metricsChartsDataFromQE: finalRes,
    metricsChartsDataFromQEStatus: "error",
  };
  var action = updateStandaloneWsFormMetricsChartsData(payload);
  ReduxDispatcher(action);
};

const dispatchErrorForMsvMetricChartsOnNoData = (props = {}) => {
  const {
    metricsList,
    wsForm,
    updateStandaloneWsFormMetricsChartsData,
    ReduxDispatcher,
  } = props;
  const finalRes = {};
  const selectedTableItemInChart = wsForm.selectedTableItemInChart.value;
  for (const metric of metricsList) {
    const metricId = metric._id;
    for (const dimValue of selectedTableItemInChart) {
      const metricChartId = `${metricId}_chart_${dimValue}`;
      finalRes[metricChartId] = {
        ...SIGVIEW_CONTANTS.defaultQeData.result,
      };
    }
  }
  // Update wsForm.dataQE to show error
  var payload = {
    metricsChartsDataFromQE: finalRes,
    metricsChartsDataFromQEStatus: "noData",
  };
  var action = updateStandaloneWsFormMetricsChartsData(payload);
  ReduxDispatcher(action);
};

const dispatchErrorForMetricsOnApiFailure = (props = {}) => {
  const { metricsList, updateStandaloneWsFormMetricsData, ReduxDispatcher } =
    props;
  const finalRes = {};
  for (const metric of metricsList) {
    const metricId = metric._id;
    finalRes[metricId] = {
      ...SIGVIEW_CONTANTS.defaultKpiData.result,
    };
    finalRes[`${metricId}_deltaPercentage`] = {
      ...SIGVIEW_CONTANTS.defaultKpiData.result,
    };
    finalRes[`${metricId}_trueDelta`] = {
      ...SIGVIEW_CONTANTS.defaultKpiData.result,
    };
  }
  // Update wsForm.dataQE to show error
  var payload = {
    metricsDataFromQE: finalRes,
    metricsDataFromQEStatus: "error",
  };
  var action = updateStandaloneWsFormMetricsData(payload);
  ReduxDispatcher(action);
};

const dispatchErrorForMetricsOnNoData = (props = {}) => {
  const { metricsList, updateStandaloneWsFormMetricsData, ReduxDispatcher } =
    props;
  const finalRes = {};
  for (const metric of metricsList) {
    const metricId = metric._id;
    finalRes[metricId] = {
      ...SIGVIEW_CONTANTS.defaultKpiData.result,
    };
    finalRes[`${metricId}_deltaPercentage`] = {
      ...SIGVIEW_CONTANTS.defaultKpiData.result,
    };
    finalRes[`${metricId}_trueDelta`] = {
      ...SIGVIEW_CONTANTS.defaultKpiData.result,
    };
  }
  // Update wsForm.dataQE to show error
  var payload = {
    metricsDataFromQE: finalRes,
    metricsDataFromQEStatus: "noData",
  };
  var action = updateStandaloneWsFormMetricsData(payload);
  ReduxDispatcher(action);
};

const isWorkspaceCorrupt = (props = {}) => {
  const { backendWs = {}, user = {}, allData = {} } = props;
  const { worksheetObject = {} } = backendWs;
  const { layout = {}, comparisonMode = false, granularity } = worksheetObject;

  // * Define required initializers
  var corruptFlag = false;
  var issues = [];
  var isLayoutTableEmpty = false;
  var isLayoutChartEmpty = false;

  // * LIST OF RULES FOR WORKSPACE TO BE VALID
  // 1. layout cannot be empty
  // 2. layout.table cannot be empty
  // 3. layout.chart cannot be empty
  // 4. layout.table.metrics cannot be empty
  // 5. layout.table.sortList cannot be empty
  // ! 6. if comparisonMode is true compareDateRange has to be present
  // ! 7. recentDownload cannot be absent
  // ! 8. recentDownload.metrics to be an array
  // ! 9. recentDownload.dimensions to be an array
  // ! 10. Can recent Downloads be empty? It can be empty
  // 11. granularity cannot be undefined

  // 1. layout cannot be empty
  const layoutTableUndefined = layout.table === undefined;
  const layoutChartUndefined = layout.chart === undefined;
  if (layoutTableUndefined) {
    corruptFlag = true;
    issues.push("Layout table is undefined");
  }
  if (layoutChartUndefined) {
    corruptFlag = true;
    issues.push("Layout chart is undefined");
  }

  // 2. layout.table cannot be empty
  if (!layoutTableUndefined) {
    isLayoutTableEmpty = layout.table.length === 0;
    if (isLayoutTableEmpty) {
      corruptFlag = true;
      issues.push("Layout table is empty");
    }
  }

  // 3. layout.chart cannot be empty
  if (!layoutChartUndefined) {
    isLayoutChartEmpty = layout.chart.length === 0;
    if (isLayoutChartEmpty) {
      corruptFlag = true;
      issues.push("Layout chart is empty");
    }
  }

  // 4. layout.table.metrics cannot be empty
  // 5. layout.table.sortList cannot be empty
  if (!layoutTableUndefined && !isLayoutTableEmpty) {
    for (const dimRow of layout.table) {
      const isMetricsEmpty = dimRow.metrics.length === 0;
      const isSortListEmpty = dimRow.sortList.length === 0;

      if (isMetricsEmpty) {
        corruptFlag = true;
        issues.push(`Metrics list is empty in dimension ${dimRow.id}`);
      }
      if (isSortListEmpty) {
        corruptFlag = true;
        issues.push(`Sort list is empty in dimension ${dimRow.id}`);
      }
    }
  }

  // 6. if comparisonMode is true compareDateRange has to be present
  // if (comparisonMode) {
  //   const isCompareDateRangePresent =
  //     worksheetObject.compareDateRange !== undefined;
  //   if (!isCompareDateRangePresent) {
  //     corruptFlag = true;
  //     issues.push("Compare date range not present");
  //   }
  // }

  // // 7. recentDownload cannot be absent
  // const recentDownloadUndefined = worksheetObject.recentDownload === undefined;
  // if (recentDownloadUndefined) {
  //   corruptFlag = true;
  //   issues.push("Recent download not present");
  // }

  // // 8. recentDownload.metrics to be an array
  // if (!recentDownloadUndefined) {
  //   if (!Array.isArray(worksheetObject.recentDownload.metrics)) {
  //     corruptFlag = true;
  //     issues.push("Metrics in recent download is not a list");
  //   }
  // }

  // // 9. recentDownload.dimensions to be an array
  // if (!recentDownloadUndefined) {
  //   if (!Array.isArray(worksheetObject.recentDownload.dimensions)) {
  //     corruptFlag = true;
  //     issues.push("Dimensions in recent download is not a list");
  //   }
  // }

  // 1. layout cannot be empty
  const granularityUndefined = granularity === undefined;
  if (granularityUndefined) {
    corruptFlag = true;
    issues.push("Granularity is undefined");
  }

  const response = { flag: corruptFlag, issues: issues, message: "" };
  return response;
};

const isWorkspaceValidBasedOnUserAccess = (props = {}) => {
  // * Destructure props
  const { backendWs = {}, user = {}, allData = {} } = props;
  const { worksheetObject = {} } = backendWs;
  const { filters = [], recentDownload = {}, layout = {} } = worksheetObject;

  // * Response initializations
  let dimensionsList = [];
  let metricsList = [];
  let customMetricsList = [];
  let response = {
    status: "valid",
    message: "",
    metadata: { missingMetrics: [], missingDimensions: [] },
  };

  // * Dimension Filters
  for (const filterRow of filters) {
    dimensionsList.push(filterRow.id);
  }

  // * Recent Downloads
  // Metrics
  // for (const metricRowId of recentDownload.metrics) {
  //   metricsList.push(metricRowId);
  // }
  // // Dimensions
  // for (const dimRowId of recentDownload.dimensions) {
  //   dimensionsList.push(dimRowId);
  // }

  // * Layout

  // Chart
  for (const chartRow of layout.chart) {
    metricsList.push(chartRow.id);
  }
  // Table
  for (const dimRow of layout.table) {
    dimensionsList.push(dimRow.id);
    // Metrics of table
    for (const metricRow of dimRow.metrics) {
      metricsList.push(metricRow.metricId);
    }
    // Sort List of table
    for (const metricRow of dimRow.sortList) {
      if (metricRow.id.startsWith("M") || metricRow.id.startsWith("CM")) {
        metricsList.push(metricRow.id);
      } else {
        dimensionsList.push(dimRow.id);
      }
    }
  }

  // * Remove _trueDelta and _percentageDelta from all metrics before checking
  metricsList = metricsList.map((id) =>
    id.replace("_trueDelta", "").replace("_deltaPercentage", "")
  );

  // * Take unique values
  dimensionsList = [...new Set(dimensionsList)];
  metricsList = [...new Set(metricsList)];

  // * Checking if all attributes are present in the access list
  const allMetricIds = allData.plotlyMetrics.map((row) => row._id);
  const allDimensionIds = allData.plotlyDimensions.map((row) => row._id);
  const missingDimensionList = dimensionsList.filter(
    (id) => !allDimensionIds.includes(id)
  );
  const missingMetricsList = metricsList.filter(
    (id) => !allMetricIds.includes(id)
  );

  // * Final valid check
  const isInvalidFlag =
    missingDimensionList.length > 0 || missingMetricsList.length > 0;
  if (isInvalidFlag) {
    let missingMetrics = missingMetricsList.filter((id) => id.startsWith("M"));
    let missingCustomMetrics = missingMetricsList.filter((id) =>
      id.startsWith("CM")
    );
    response = {
      status: "invalid",
      message: "",
      metadata: {
        missingMetrics: missingMetrics,
        missingCustomMetrics: missingCustomMetrics,
        missingDimensions: missingDimensionList,
      },
    };
  }

  // * DEBUGGER
  // console.groupCollapsed("isWorkspaceValidBasedOnUserAccess");
  // console.log("dimensionsList", dimensionsList);
  // console.log("metricsList", metricsList);
  // console.log("customMetricsList", customMetricsList);
  // console.log("missingDimensionList", missingDimensionList);
  // console.log("missingMetricsList", missingMetricsList);
  // console.log("response", response);
  // console.groupEnd();

  return response;
};

const makeAttributeMissingErrorMessage = (
  reponseFromValidationFunction,
  type
) => {
  let title = type || "";
  let metadata = reponseFromValidationFunction.metadata;
  let missingDimensions = metadata.missingDimensions;
  let missingMetrics = metadata.missingMetrics;
  let missingCustomMetrics = metadata.missingCustomMetrics;
  const sigviewUserType = getSigviewUserType();
  let supportContact =
    "Please reach out to sigviewsupport@sigmoidanalytics.com";
  if (sigviewUserType === "sigview")
    supportContact = "Please reach out to sigviewsupport@sigmoidanalytics.com";
  if (sigviewUserType === "nonSigview")
    supportContact =
      "Please raise a ticket in Salesforce or reach out to support@openx.com";

  let message = `<span>It seems like the access to some attributes for this ${title} have been modified</span>`;
  message += `<br/><span>${supportContact}</span>`;
  if (missingDimensions.length > 0)
    message += `<br/><span style="font-size: 10px; color: #607282">Dimensions: ${missingDimensions.join(
      ", "
    )}</span>`;
  if (missingMetrics.length > 0)
    message += `<br/><span style="font-size: 10px; color: #607282">Metrics: ${missingMetrics.join(
      ", "
    )}</span>`;
  if (missingCustomMetrics.length > 0)
    message += `<br/><span style="font-size: 10px; color: #607282">Custom Metrics: ${missingCustomMetrics.join(
      ", "
    )}</span>`;
  return message;
};

const makeCorruptWorkspaceErrorMessage = (reponseFromCorruptFunction) => {
  let issues = reponseFromCorruptFunction.issues;
  const sigviewUserType = getSigviewUserType();
  let supportContact =
    "Please reach out to sigviewsupport@sigmoidanalytics.com";
  if (sigviewUserType === "sigview")
    supportContact = "Please reach out to sigviewsupport@sigmoidanalytics.com";
  if (sigviewUserType === "nonSigview")
    supportContact =
      "Please raise a ticket in Salesforce or reach out to support@openx.com";

  let message = `<span>It seems like there are certain issues in the workspace. ${supportContact}</span>`;
  for (const issue of issues) {
    message += `<br/><span style="font-size: 10px; color: #607282">${issue}</span>`;
  }
  // message += `<br/><span>Please contact your admin!</span>`;
  return message;
};

const isSameDimFilterApplied = (wsForm) => {
  const activeMsvTableFilters = wsForm.dimensionFilters.value.filter(
    (el) => el.id === wsForm.activeMsvTableId.value
  );
  if (activeMsvTableFilters.length > 0) {
    return true;
  } else {
    return false;
  }
};

const areMultiItemsSelectedInMsv = (state) =>
  state.selectedTableItemInChart.value.length > 0;

const getLoadingStatusForWsMetricChart = (wsForm, metricId) => {
  const dataQEAll = wsForm.dataQE.value;
  var allStatusForCurrMetric = [];
  for (const [key, value] of Object.entries(dataQEAll)) {
    if (key.startsWith(`${metricId}_chart_`)) {
      allStatusForCurrMetric.push(value.status);
    }
  }
  let partialLoading = allStatusForCurrMetric.some((el) => el === "loading");
  let partialSuccess = allStatusForCurrMetric.some((el) => el === "success");
  let fullLoading = allStatusForCurrMetric.every((el) => el === "loading");
  let fullSuccess = allStatusForCurrMetric.every((el) => el === "success");
  let anyError = allStatusForCurrMetric.some((el) => el === "error");

  return { partialLoading, partialSuccess, fullLoading, fullSuccess, anyError };
};

const makeDataFromQeForMsvMultiSelectApiCall = (props = {}) => {
  var { data1, data2, filterDimObject, msvForm } = props;

  // * Define required variables
  var accessor = filterDimObject["dimID"]; // ! hard coded
  const selectedTableItemInChart = selectSelectedTableItemInChart({
    standaloneMsv: msvForm,
  });
  const selectedMetricsInMsvTableAccessorsList =
    selectMetricsInMsvTableAccessorsList({
      standaloneMsv: msvForm,
    });

  // Add rows for selectedTableItemInChart hard coding value to NA if that row is not returned (EGDE CASE in notes)
  for (const selectedMsvDimValue of selectedTableItemInChart) {
    // Eg: Android, iOS in OS Dimension
    var isDataRowPresentFlag = false;
    for (const dataRow of data2) {
      if (dataRow[accessor] === selectedMsvDimValue) {
        isDataRowPresentFlag = true;
      }
    }

    // Add sample rows with values as NA if row not present in data
    if (!isDataRowPresentFlag) {
      let newNaRow = {
        [accessor]: selectedMsvDimValue,
      };
      for (const metricAccessor of selectedMetricsInMsvTableAccessorsList) {
        newNaRow[metricAccessor] = "NA";
      }
      data2.push(newNaRow);
    }
  }

  // remove rows from data1 which are present in data2
  var idsPresentInData2 = data2.map((row) => row[accessor]);
  data1 = data1.filter((row) => !idsPresentInData2.includes(row[accessor]));
  var dataFromQEFinal = [...data2, ...data1];

  return dataFromQEFinal;
};

const standardizeReponseFromGetDataPromiseAllSelected = (settledPromises) => {
  var statusArr = [];
  var result = [];
  var message = "";
  for (const settledPromise of settledPromises) {
    var responseItem = {};
    if (settledPromise.status === "fulfilled") {
      responseItem = settledPromise.value;
      statusArr.push("dataAvailable");
    } else {
      const isNoDataAvailableCase =
        settledPromise.reason.error === config.hardCoded.noDataAvailableMessage;
      if (isNoDataAvailableCase) {
        statusArr.push("noDataAvailable");
        responseItem = {
          status: {
            statusCode: "304",
            statusMessage: "NoData",
          },
          result: {
            data: [],
          },
        };
      } else {
        responseItem = {
          status: {
            statusCode: "500",
            statusMessage: "Error",
          },
          result: {
            data: [],
          },
        };
        statusArr.push("apiError");
        message = settledPromise.reason.error;
      }
    }
    result.push(responseItem);
  }
  const status = statusArr.some((status) => status === "apiError")
    ? "apiError"
    : statusArr.some((status) => status === "dataAvailable")
    ? "dataAvailable"
    : "noDataAvailable";
  const response = { status: status, message, result: result };
  return response;
};
const getValidDates = () => {
  let now = moment().format("MMMM D, h:mm A");
  let todayStartHour = moment().startOf("day");
  let yesterdayStartHour = moment().startOf("day").subtract(24, "hours");
  let nowTime = moment();
  let dashboardLastSaved;
  if (nowTime.diff(todayStartHour, "days") === 0) {
    return (dashboardLastSaved =
      "Dashboard Last Saved: Today, " + nowTime.format("h:mm A"));
  } else if (nowTime.diff(yesterdayStartHour, "days") === 1) {
    return (dashboardLastSaved =
      "Dashboard Last Saved: Yesterday, " + nowTime.format("h:mm A"));
  } else {
    return (dashboardLastSaved = "Dashboard Last Saved: " + now);
  }
};

export {
  getInitialWsForm,
  unwrapperWs,
  plotlyDimensionsToDimensionDwawer,
  plotlyMetricsToDimensionDwawer,
  wrapperWs,
  wrapperDimDownloadRequest,
  wrapperMetricDownloadRequest,
  getCommonMetricsFromAllDimensions,
  makeUiRowDataFriendlyForWsDimTable,
  getWsDimTableColumns,
  formatDimValueWsDimTable,
  initialWsFormScreenValue,
  getBookmarkDetails,
  isWsFormSameAsOriginal,
  wrapperUpdateWorksheetStatus,
  makeMetricsDataConsistent,
  makeMetricsChartDataConsistentMultiFilter,
  makeMetricsChartDataConsistent,
  getAddMetricDrawerInitialSelections,
  getUnionOfAllMetricsInDimensionTables,
  dispatchErrorForMetricChartsOnApiFailure,
  dispatchErrorForMetricsOnApiFailure,
  isWorkspaceValidBasedOnUserAccess,
  makeAttributeMissingErrorMessage,
  isWorkspaceCorrupt,
  makeCorruptWorkspaceErrorMessage,
  getDefaultWorksheetUi,
  isSameDimFilterApplied,
  getAddMetricDrawerInitialSelectionsForMsv,
  areMultiItemsSelectedInMsv,
  getLoadingStatusForWsMetricChart,
  dispatchErrorForMsvMetricChartsOnApiFailure,
  makeDataFromQeForMsvMultiSelectApiCall,
  getDefaultWsForm,
  standardizeReponseFromGetDataPromiseAllSelected,
  dispatchErrorForMetricChartsOnNoData,
  dispatchErrorForMsvMetricChartsOnNoData,
  dispatchErrorForMetricsOnNoData,
  getValidDates,
  makeMetricsTotalDataConsistent,
};
