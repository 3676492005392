// Import required libraries
import moment from "moment";

import { makeStyles } from "@material-ui/core";
import isEqual from "lodash.isequal";
import groupBy from "lodash.groupby";
import { Parser } from "json2csv";
import * as XLSX from "xlsx";

// Import utils
import { unwrapperChartObject } from "../utils/chartObjectUtils";
import { formatTimeFilters, formatCompareTimeFilters } from "../utils/dsUtils";
import {
  getDimensionObjByOriginalID,
  getMeasureObjByOriginalID,
} from "./plotlyUtils";

const initialUserSelection = {
  id: "",
  title: "new title",
  dimensionsList: [],
  metricsList: [],
  advanceSort: [{ metric: "", dimension: "", orderBy: "desc" }],
  timeFilters: {
    isLoading: false,
    selectedTimezone: {
      name: "UTC (+00:00)",
      location: "UTC",
      value: {
        hours: 9,
        minutes: 0,
      },
      minutesOffset: 0,
    },
    validDates: {
      startDate: {
        epoch: 1609459200000,
        timezone: "UTC (+00:00)",
        nativeDateObj: "2020-12-31T18:30:00.000Z",
        formattedDate: "Fri January 01, 2021 00:00:00 +0000",
      },
      endDate: {
        epoch: 1643839200000,
        timezone: "UTC (+00:00)",
        nativeDateObj: "2022-02-02T16:30:00.000Z",
        formattedDate: "Wed February 02, 2022 22:00:00 +0000",
      },
    },
    selectedDates: {
      startDate: {
        timezone: {
          name: "UTC (+00:00)",
          location: "UTC",
          value: {
            hours: 9,
            minutes: 0,
          },
          minutesOffset: 0,
        },
        epoch: 1643673600000,
        nativeDateObj: "2022-01-31T18:30:00.000Z",
        formattedDate: "Tue February 01, 2022 00:00:00 +0000",
      },
      endDate: {
        timezone: {
          name: "UTC (+00:00)",
          location: "UTC",
          value: {
            hours: 9,
            minutes: 0,
          },
          minutesOffset: 0,
        },
        epoch: 1643839200000,
        nativeDateObj: "2022-02-02T16:30:00.000Z",
        formattedDate: "Wed February 02, 2022 22:00:00 +0000",
      },
      valid: true,
    },
    validDatePresets: [
      {
        id: "custom",
        name: "Custom",
        available: true,
        priority: 13,
      },
      {
        id: "yesterday",
        name: "Yesterday",
        available: true,
        priority: 2,
      },
      {
        id: "this_week",
        name: "This Week",
        available: true,
        priority: 3,
      },
      {
        id: "last_week",
        name: "Last Week",
        available: true,
        priority: 4,
      },
      {
        id: "last_7_days",
        name: "Last 7 Days",
        available: true,
        priority: 5,
      },
      {
        id: "last_14_days",
        name: "Last 14 Days",
        available: true,
        priority: 6,
      },
      {
        id: "last_30_days",
        name: "Last 30 Days",
        available: true,
        priority: 7,
      },
      {
        id: "last_60_days",
        name: "Last 60 Days",
        available: true,
        priority: 8,
      },
      {
        id: "this_month",
        name: "This Month",
        available: true,
        priority: 9,
      },
      {
        id: "last_month",
        name: "Last Month",
        available: true,
        priority: 10,
      },
      {
        id: "this_quarter",
        name: "This Quarter",
        available: true,
        priority: 11,
      },
      {
        id: "year_till_date",
        name: "Year Till Date",
        available: true,
        priority: 12,
      },
    ],
    selectedDatePreset: "custom",
    selectedDatesQE: {
      startDate: 1643673600000,
      endDate: 1643842800000,
    },
    compareDates: [
      {
        id: "73e3a2d2-f679-4e17-8a7a-d217dcb1a1c9",
        compareSelectedDates: {
          startDate: {
            epoch: 1643500800000,
            timezone: {
              name: "UTC (+00:00)",
              location: "UTC",
              value: {
                hours: 9,
                minutes: 0,
              },
              minutesOffset: 0,
            },
            nativeDateObj: "2022-01-29T18:30:00.000Z",
            formattedDate: "Sun January 30, 2022 00:00:00 +0000",
          },
          endDate: {
            epoch: 1643673599999,
            timezone: {
              name: "UTC (+00:00)",
              location: "UTC",
              value: {
                hours: 9,
                minutes: 0,
              },
              minutesOffset: 0,
            },
            nativeDateObj: "2022-01-31T18:29:59.999Z",
            formattedDate: "Mon January 31, 2022 23:59:59 +0000",
          },
          valid: true,
        },
        compareSelectedDatesSameDuration: {
          startDate: {
            epoch: 1643500800000,
            timezone: {
              name: "UTC (+00:00)",
              location: "UTC",
              value: {
                hours: 9,
                minutes: 0,
              },
              minutesOffset: 0,
            },
            nativeDateObj: "2022-01-29T18:30:00.000Z",
            formattedDate: "Sun January 30, 2022 00:00:00 +0000",
          },
          endDate: {
            epoch: 1643666400000,
            timezone: {
              name: "UTC (+00:00)",
              location: "UTC",
              value: {
                hours: 9,
                minutes: 0,
              },
              minutesOffset: 0,
            },
            nativeDateObj: "2022-01-31T16:30:00.000Z",
            formattedDate: "Mon January 31, 2022 22:00:00 +0000",
          },
          valid: true,
        },
        compareValidDates: {
          startDate: {
            epoch: 1609459200000,
            timezone: "UTC (+00:00)",
            nativeDateObj: "2020-12-31T18:30:00.000Z",
            formattedDate: "Fri January 01, 2021 00:00:00 +0000",
          },
          endDate: {
            epoch: 1643759999999,
            timezone: "UTC (+00:00)",
            nativeDateObj: "2022-02-01T18:29:59.999Z",
            formattedDate: "Tue February 01, 2022 23:59:59 +0000",
          },
        },
        compareSelectedDatesQE: {
          startDate: 1643500800000,
          endDate: 1643673600000,
        },
        compareSelectedDatesQESameDuration: {
          startDate: 1643500800000,
          endDate: 1643670000000,
        },
        compareValidDatePresets: [
          {
            id: "custom",
            name: "Custom",
            available: true,
            priority: 1,
          },
          {
            id: "previous_week",
            name: "Previous Week",
            available: true,
            priority: 3,
          },
          {
            id: "previous_month",
            name: "Previous Month",
            available: true,
            priority: 4,
          },
        ],
        compareSelectedDatePreset: "custom",
        isSelected: false,
      },
    ],
    isComparisonOn: false,
    format: "ddd MMMM DD, YYYY HH:mm:ss ZZ",
  },
  elementType: "create",
  // For the rerender flag of the table
  // 1 renderFlag is flase
  // 2 On clicking on apply button renderFlag is true
  // 3 After changing any metric/dimension renderFlag is false
  // 4 repeate the step 2
  renderFlag: false,
  transpose: false,
  download: false,
  dimensionFilters: [],
  metricFilters: [],
};

const getValidAdvanceSort = (newState) => {
  // TODO : Add logic to make valid advance sort
  // * DEBUGGER
  // console.groupCollapsed("getValidAdvanceSort");
  // console.log("newState", newState);
  // console.log("newstate.dimensionsList", newState.dimensionsList);
  // console.log("newState.metricsList", newState.metricsList);
  // console.log("newState.advanceSort", newState.advanceSort);
  // console.groupEnd();

  let newAdvanceSort = newState.advanceSort;

  const isPresent = (props = {}) => {
    const { id, data, runFlag } = props;
    if (runFlag === "dimensionList") {
      for (let item of data) {
        if (item._id === id) {
          return true;
        }
      }
      return false;
    }
    if (runFlag === "metricsList") {
      for (let item of data) {
        if (item._id === id) {
          return true;
        }
      }
      return false;
    }
    if (runFlag === "advanceSortList") {
      for (let item of data) {
        if (item.dimId === id) {
          return true;
        }
      }
      return false;
    }
  };

  // REMOVE From the advance sort - if present in advancesort and not in dimensionlist
  newAdvanceSort = newAdvanceSort.filter((el) =>
    isPresent({
      id: el.dimId,
      data: newState.dimensionsList,
      runFlag: "dimensionList",
    })
  );

  // ADD in Advance sort- if not present in advancesort and present in dimensionList
  newState.dimensionsList.forEach((el) => {
    const isPresentInAdvanceSort = isPresent({
      id: el._id,
      data: newState.advanceSort,
      runFlag: "advanceSortList",
    });
    if (!isPresentInAdvanceSort) {
      const sortedOn =
        newState.metricsList.length > 0 ? newState.metricsList[0]._id : el._id;
      const sortType = newState.metricsList.length > 0 ? "metric" : "dimension";
      const newAdvanceSortObj = {
        dimId: el._id,
        sortedOn,
        sortType,
        desc: true,
      };
      newAdvanceSort = [...newAdvanceSort, newAdvanceSortObj];
    }
  });

  // validate metric id in each advance sort row
  newAdvanceSort = newAdvanceSort.map((row) => {
    let currSortedOnId = row.sortedOn;
    // Remove deltaPercentage and trueDelta to enable valid checking of metric existence
    currSortedOnId = currSortedOnId.replace("_deltaPercentage", "");
    currSortedOnId = currSortedOnId.replace("_trueDelta", "");
    const isSortedTypeMetric = row.sortType === "metric";
    const isMetricPresentInMetricList = isPresent({
      id: currSortedOnId,
      data: newState.metricsList,
      runFlag: "metricsList",
    });
    if (!isSortedTypeMetric) {
      return row;
    } else {
      if (!isMetricPresentInMetricList) {
        const sortedOn =
          newState.metricsList.length > 0
            ? newState.metricsList[0]._id
            : row.dimId;
        const sortType =
          newState.metricsList.length > 0 ? "metric" : "dimension";
        const newRow = {
          dimId: row.dimId,
          sortedOn,
          sortType,
          desc: true,
        };
        return newRow;
      } else if (
        !newState.timeFilters.isComparisonOn &&
        row.sortedOn.includes("_")
      ) {
        const newRow = {
          dimId: row.dimId,
          sortedOn:
            newState.metricsList.length > 0
              ? newState.metricsList[0]._id
              : row.dimId,
          sortType: newState.metricsList.length > 0 ? "metric" : "dimension",
          desc: true,
        };
        return newRow;
      } else {
        return row;
      }
    }
  });

  return newAdvanceSort;
};

const getComparisonHeaderColumns = (actualColumns) => {
  let headerActualColumns = [];
  actualColumns.forEach((row, index) => {
    if (index === 0) {
      headerActualColumns.push(row);
    } else {
      for (const [key, value] of Object.entries(row)) {
        let newRow = { ...value };
        if (key === "deltaPercentage") {
          newRow = { ...newRow, id: `${newRow.id}_${key}`, name: "% Change" };
        } else if (key === "trueDelta") {
          newRow = { ...newRow, id: `${newRow.id}_${key}`, name: "Change" };
        }
        headerActualColumns.push(newRow);
      }
    }
  });
  return headerActualColumns;
};

const getTransposeTableHeaderColumns = (actualColumns) => {
  let row1Columns = actualColumns.filter((row) => row.rowId === "row1");
  let row1ColumnsMod = [];
  row1Columns.forEach((row) => {
    if (row.childCount === 0) {
      row1ColumnsMod.push({ ...row, rowSpan: 2, colSpan: 1 });
    } else {
      row1ColumnsMod.push({ ...row, rowSpan: 2, colSpan: 1 });
      row1ColumnsMod.push({ ...row, rowSpan: 1, colSpan: row.childCount });
    }
  });
  const row2Columns = actualColumns.filter((row) => row.rowId === "row2");
  return { row1Columns: row1ColumnsMod, row2Columns };
};

const getTransposeColumn = (dimList, metricList, responseApi, selections) => {
  // * DEBUGGER
  // console.groupCollapsed("getTransposeColumn");
  // console.log("responseApi", responseApi);
  // console.log("selections", selections);
  // console.log("dimList", dimList);
  // console.log("metricList", metricList);
  // console.log(
  //   "timefilters",
  //   selections.timeFilters.selectedTimezone.minutesOffset
  // );
  // console.groupEnd();
  // ! column requirement for the hour/month/day
  const dayArray = responseApi.map((el) => {
    if (el.hour) {
      return moment(Number(el.hour))
        .utcOffset(selections.timeFilters.selectedTimezone.minutesOffset)
        .format("MM-DD HH:mm");
    } else if (el.day) {
      return moment(Number(el.day))
        .utcOffset(selections.timeFilters.selectedTimezone.minutesOffset)
        .format("YYYY-MM-DD");
    } else if (el.month) {
      return moment(Number(el.month))
        .utcOffset(selections.timeFilters.selectedTimezone.minutesOffset)
        .format("YYYY-MM");
    }
    return el;
  });
  const finalDayArray = dayArray.sort();

  let newDimObj = dimList.map((dimListObj) => {
    let dimObj = {
      id: dimListObj._id,
      dimId: dimListObj.dimID,
      name: "Dimension",
      orderBy: "desc",
      accessor: dimListObj.dimID,
      actualPayload: dimListObj,
      rowId: "row1",
      childCount: 0,
    };
    let newMericObj = [dimObj];
    metricList.map((metricListObj) => {
      const columnDayArray = finalDayArray.map((dayel) => ({
        id: metricListObj._id,
        // name: `${metricListObj._title}_${dayel}`,
        name: dayel,
        orderBy: "desc",
        accessor: `${metricListObj._title}_${dayel}`,
        actualPayload: metricListObj,
        rowId: "row2",
        childCount: 0,
      }));
      const normalMetric = {
        id: metricListObj._id,
        name: metricListObj._title,
        orderBy: "desc",
        accessor: metricListObj._id.startsWith("M")
          ? metricListObj.measureID
          : metricListObj._id,
        actualPayload: metricListObj,
        rowId: "row1",
        childCount: columnDayArray.length,
      };
      columnDayArray.unshift(normalMetric);
      return (newMericObj = [...newMericObj, ...columnDayArray]);
      // console.log("columnDayArray", columnDayArray);
    });
    // console.log("newMericObj", newMericObj);
    return newMericObj;
  });
  return newDimObj;
};

const makeAdditionalTimeFilters = (props = {}) => {
  const {
    columns = [],
    row = {},
    timeFilters = {},
    considerRowFlag = true,
  } = props;
  var timeFilterFlag = false;
  const rowLevel = row.level;
  const allDateRanges = [];
  const selectedTimezone = timeFilters.selectedTimezone;

  const getMinDurationDateRange = (allDateRanges) => {
    if (allDateRanges.length === 0) return { startEpoch: 0, endEpoch: 0 };
    let minDurationDateRange = allDateRanges[0];
    let minDuration = allDateRanges[0].endEpoch - allDateRanges[0].startEpoch;
    for (const dateRange of allDateRanges) {
      const currDuration = dateRange.endEpoch - dateRange.startEpoch;
      if (currDuration < minDuration) {
        minDurationDateRange = { ...dateRange };
      }
    }
    return minDurationDateRange;
  };

  // This shouldn't be in case of show more
  if (considerRowFlag) {
    const currCol = columns[row.level][0];
    if (currCol.actualPayload.dataType === "dateTime") {
      timeFilterFlag = true;
      const type = currCol.actualPayload.dimID;
      const value = parseInt(row[currCol.accessor]);
      let startEpoch = 0;
      let endEpoch = 0;
      switch (type) {
        case "hour":
          console.log("HOUR");
          startEpoch = moment(value)
            .utcOffset(selectedTimezone.minutesOffset)
            .startOf("hour")
            .valueOf();
          endEpoch =
            moment(value)
              .utcOffset(selectedTimezone.minutesOffset)
              .endOf("hour")
              .valueOf() + 1;
          break;

        case "day":
          console.log("DAY");
          startEpoch = moment(value)
            .utcOffset(selectedTimezone.minutesOffset)
            .startOf("day")
            .valueOf();
          endEpoch =
            moment(value)
              .utcOffset(selectedTimezone.minutesOffset)
              .endOf("day")
              .valueOf() + 1;
          break;

        case "month":
          console.log("MONTH");
          startEpoch = moment(value)
            .utcOffset(selectedTimezone.minutesOffset)
            .startOf("month")
            .valueOf();
          endEpoch =
            moment(value)
              .utcOffset(selectedTimezone.minutesOffset)
              .endOf("month")
              .valueOf() + 1;
          break;
      }
      endEpoch = Math.min(timeFilters.selectedDatesQE.endDate, endEpoch);
      const currDateRange = { startEpoch, endEpoch };
      allDateRanges.push(currDateRange);
    }
  }

  for (let i = 0; i < rowLevel; i++) {
    const rowDimensionFilter = {
      id: columns[i][0].id,
      values: [row.parentInfo[i + 1]],
      metadata: {
        ...columns[i][0].actualPayload,
      },
      filterType: "include",
      advancedFilters: [],
      valid: true,
    };
    if (rowDimensionFilter.metadata.dataType === "dateTime") {
      timeFilterFlag = true;
      const type = rowDimensionFilter.metadata.dimID;
      const value = parseInt(rowDimensionFilter.values[0]);
      let startEpoch = 0;
      let endEpoch = 0;
      switch (type) {
        case "hour":
          startEpoch = moment(value)
            .utcOffset(selectedTimezone.minutesOffset)
            .startOf("hour")
            .valueOf();
          endEpoch =
            moment(value)
              .utcOffset(selectedTimezone.minutesOffset)
              .endOf("hour")
              .valueOf() + 1;
          break;

        case "day":
          startEpoch = moment(value)
            .utcOffset(selectedTimezone.minutesOffset)
            .startOf("day")
            .valueOf();
          endEpoch =
            moment(value)
              .utcOffset(selectedTimezone.minutesOffset)
              .endOf("day")
              .valueOf() + 1;
          break;

        case "month":
          startEpoch = moment(value)
            .utcOffset(selectedTimezone.minutesOffset)
            .startOf("month")
            .valueOf();
          endEpoch =
            moment(value)
              .utcOffset(selectedTimezone.minutesOffset)
              .endOf("month")
              .valueOf() + 1;
          break;
      }
      endEpoch = Math.min(timeFilters.selectedDatesQE.endDate, endEpoch);
      const currDateRange = { startEpoch, endEpoch };
      allDateRanges.push(currDateRange);
    }
  }
  const dateRange = getMinDurationDateRange(allDateRanges);

  // * DEBUGGER
  console.groupCollapsed("makeAdditionalTimeFilters");
  console.log("row", row);
  console.log("dateRange", dateRange);
  console.log("allDateRanges", allDateRanges);
  console.groupEnd();

  return {
    timeFilterFlag,
    dateRange: {
      startDate: dateRange.startEpoch.toString(),
      endDate: dateRange.endEpoch.toString(),
    },
  };
};

// Func to check in transpose if there is dateTime then send it to last of dimList
const getValidDimensionSort = (newState) => {
  let newDimensionList = newState.dimensionsList;
  newDimensionList.forEach((el, index) => {
    if (el.dataType === "dateTime") {
      const findEl = newDimensionList.splice(index, 1);
      newDimensionList.push(...findEl);
    }
  });
  return newDimensionList;
};
// Func to check in the diemension list there is dayTime is present
const getValidCompareDimension = (newState) => {
  let newDimensionList = newState.dimensionsList;
  let validDimension = false;
  newDimensionList.find((el) => {
    if (el.dataType === "dateTime") {
      validDimension = true;
    }
    return validDimension;
  });
  return validDimension;
};

const unwrapperPivots = (props = {}) => {
  const { data = [], searchField = "", allData = {}, user = {} } = props;
  let filteredData = data.filter(
    (row) => row.chartObject.metadata.chartType === "pivotx"
  );
  // Filter based on search value
  filteredData = filteredData.filter((row) =>
    row.chartObject.metadata.title
      .toLowerCase()
      .includes(searchField.toLowerCase())
  );
  // Conversion so that it can be dev to unwrapperChartObjec func
  filteredData = filteredData.map((row) => ({
    id: row._id,
    name: row.chartObject.metadata.title,
    payload: row,
    chartType: row.chartObject.metadata.chartType,
  }));
  // Adding chartObjectUI using uwrapperChartObject to filter out corrupt objects
  filteredData = filteredData.map((row) => {
    var selections = {
      ...unwrapperChartObject({
        allData: allData,
        user: user,
        payload: { ...row.payload },
      }),
      transpose: false,
    };
    const validatedAdvanceSort = getValidAdvanceSort(selections);
    selections = { ...selections, advanceSort: validatedAdvanceSort };
    const newRow = {
      ...row,
      chartObjectUI: selections,
    };
    return newRow;
  });
  // Filter out corrupt objects
  filteredData = filteredData.filter((row) => row.chartObjectUI.valid);
  // ! HARD CODED: Chart list ordering not present in the backend
  // ! Hence, doing it here
  filteredData = [...filteredData].reverse();
  return filteredData;
};

// * Define required utilities
const unwrapperDataAdvanceSort = (props = {}) => {
  const { dimensionsList = [], metricsList = [], timeFilters = {} } = props;
  const isComparisonOn = timeFilters.isComparisonOn;
  let dimensions = dimensionsList.map((row) => ({
    id: row._id,
    name: row._title,
    disabled: false,
  }));
  let metrics = [];
  metricsList.forEach((row) => {
    const newRow = {
      id: row._id,
      name: row._title,
      disabled: false,
    };
    const newDeltaPercentageRow = {
      id: `${row._id}_deltaPercentage`,
      name: `${row._title} Delta(%)`,
      disabled: false,
    };
    const newTrueDeltaRow = {
      id: `${row._id}_trueDelta`,
      name: `${row._title} Delta`,
      disabled: false,
    };
    metrics = [...metrics, newRow];
    if (isComparisonOn) {
      metrics = [...metrics, newTrueDeltaRow, newDeltaPercentageRow];
    }
  });
  let orderOnList = {};
  for (const dim of dimensionsList) {
    const currDimObj = {
      id: dim._id,
      name: dim._title,
      disabled: false,
    };
    const arr = [currDimObj, ...metrics];
    orderOnList[dim._id] = arr;
  }
  let data = { dimensions, orderOnList, metrics };
  return data;
};
const wrapperSelectionsAdvanceSort = (selections) => {
  const advanceSort = [];
  Object.entries(selections).forEach((item) => {
    const obj = {
      dimId: item[0],
      sortedOn: item[1].orderOn,
      // sortType: "metric",
      sortType: item[1].orderOn.startsWith("D") ? "dimension" : "metric",
      desc: item[1].orderType === "desc" ? true : false,
    };
    advanceSort.push(obj);
  });
  return advanceSort;
};
const unwrapperSelectionsAdvanceSort = (advanceSort) => {
  const selections = {};
  advanceSort.forEach((el) => {
    selections[el.dimId] = {
      orderOn: el.sortedOn,
      orderType: el.desc === true ? "desc" : "asc",
    };
  });
  return selections;
};
const makeOrderByText = (advanceSort, allData) => {
  let textArr = [];
  let textArrWithoutDim = [];
  for (const row of advanceSort) {
    const dimTitle = getDimensionObjByOriginalID(
      row.dimId,
      allData.plotlyDimensions
    )._title;
    let orderByTitle = "";
    if (row.sortType === "dimension") {
      orderByTitle = getDimensionObjByOriginalID(
        row.sortedOn,
        allData.plotlyDimensions
      )._title;
    } else {
      if (row.sortedOn.includes("deltaPercentage")) {
        orderByTitle =
          getMeasureObjByOriginalID(
            row.sortedOn.replace("_deltaPercentage", ""),
            allData.plotlyMetrics
          )._title + " Delta(%)";
      } else if (row.sortedOn.includes("trueDelta")) {
        orderByTitle =
          getMeasureObjByOriginalID(
            row.sortedOn.replace("_trueDelta", ""),
            allData.plotlyMetrics
          )._title + " Delta";
      } else {
        orderByTitle = getMeasureObjByOriginalID(
          row.sortedOn,
          allData.plotlyMetrics
        )._title;
      }
    }
    const orderBy = row.desc ? "Descending" : "Ascending";
    const rowTextWithoutDim = `${orderByTitle} - ${orderBy}`;
    const rowText = `${dimTitle}: ${rowTextWithoutDim}`;
    textArr.push(rowText);
    textArrWithoutDim.push(rowTextWithoutDim);
  }
  const uniqueTextArrWithoutDim = [...new Set(textArrWithoutDim)];
  let orderByText = "";
  if (uniqueTextArrWithoutDim.length === 1) {
    orderByText = uniqueTextArrWithoutDim.join("");
  } else {
    orderByText = textArr.join("; ");
  }
  return orderByText;
};

// Download Utils
const makeIncludeFiltersText = (filters) => {
  const includeFilters = filters.filter((row) => row.filterType === "include");
  let textArr = [];
  for (const row of includeFilters) {
    let valuesTextArr = [...row.values];
    const groupedAdFiltersData = groupBy(
      row.advancedFilters,
      (row) => row.type
    );
    for (const [key, val] of Object.entries(groupedAdFiltersData)) {
      if (key !== "exactlyMatches") {
        const valTextArr = val.map((v) => v.value);
        const adFilterText = `${key.toUpperCase()}(${valTextArr.join(",")})`;
        valuesTextArr.push(adFilterText);
      }
    }
    const finalRowText = `${row.metadata._title}[${valuesTextArr.join(";")}]`;
    if (valuesTextArr.length > 0) {
      textArr.push(finalRowText);
    }
  }
  const includeFiltersText = textArr.join(";");
  return `${includeFiltersText}`;
};
const makeIncludeBulkFiltersText = (filters) => {
  const includeFilters = filters.filter((row) => row.filterType === "include");
  let textArr = [];
  for (const row of includeFilters) {
    const groupedAdFiltersData = groupBy(
      row.advancedFilters,
      (row) => row.type
    );
    for (const [key, val] of Object.entries(groupedAdFiltersData)) {
      if (key === "exactlyMatches") {
        const finalRowText = `${row.metadata._title}[${val[0].extraData.fileName}]`;
        textArr.push(finalRowText);
      }
    }
  }
  const includeBulkFiltersText = textArr.join(";");
  return `${includeBulkFiltersText}`;
};
const makeExcludeFiltersText = (filters) => {
  const excludeFilters = filters.filter((row) => row.filterType === "exclude");
  let textArr = [];
  for (const row of excludeFilters) {
    let valuesTextArr = [...row.values];
    const groupedAdFiltersData = groupBy(
      row.advancedFilters,
      (row) => row.type
    );
    for (const [key, val] of Object.entries(groupedAdFiltersData)) {
      if (key !== "exactlyMatches") {
        const valTextArr = val.map((v) => v.value);
        const adFilterText = `${key.toUpperCase()}(${valTextArr.join(",")})`;
        valuesTextArr.push(adFilterText);
      }
    }
    const finalRowText = `${row.metadata._title}[${valuesTextArr.join(";")}]`;
    if (valuesTextArr.length > 0) {
      textArr.push(finalRowText);
    }
  }
  const exlcudeFiltersText = textArr.join(";");
  return `${exlcudeFiltersText}`;
};
const makeExcludeBulkFiltersText = (filters) => {
  const excludeFilters = filters.filter((row) => row.filterType === "exclude");
  let textArr = [];
  for (const row of excludeFilters) {
    const groupedAdFiltersData = groupBy(
      row.advancedFilters,
      (row) => row.type
    );
    for (const [key, val] of Object.entries(groupedAdFiltersData)) {
      if (key === "exactlyMatches") {
        const finalRowText = `${row.metadata._title}[${val[0].extraData.fileName}]`;
        textArr.push(finalRowText);
      }
    }
  }
  const excludeBulkFiltersText = textArr.join(";");
  return `${excludeBulkFiltersText}`;
};
const makeTableHeader = (columns) => {
  let textArr = columns.map((row) => row.name);
  let text = textArr.join(",");
  return text;
};
const downloadCsvFile = (csvData = "", fileName = "csvFile") => {
  var blob = new Blob([csvData]);
  const url = window.URL.createObjectURL(blob, {
    type: "text/csv",
  });
  const hiddenElement = document.createElement("a");
  hiddenElement.href = url;
  hiddenElement.setAttribute("download", `${fileName}.csv`);
  document.body.appendChild(hiddenElement);
  hiddenElement.click();

  // Clean up and remove the hiddenElement
  hiddenElement.parentNode.removeChild(hiddenElement);
};
const downloadExcelFile = (data = "", fileName = "excelFile") => {
  const arrayOfArrayCsv = data.split("\n").map(
    (row) =>
      row
        .split(",")
        .map((item) => item.replace(/^"{1}/g, "").replace(/"{1}$/g, "")) // this is to remove the additional double quotes that get added by json2csv
  );
  const wb = XLSX.utils.book_new();
  const newWs = XLSX.utils.aoa_to_sheet(arrayOfArrayCsv);
  XLSX.utils.book_append_sheet(wb, newWs);
  XLSX.writeFile(wb, `${fileName}.xlsx`, { type: "utf-8" });
};

const downloadPivotTableData = (props = {}) => {
  const {
    data = [],
    selections = {},
    fileType = "csv",
    fileName = "Pivot Report",
    allData = {},
  } = props;
  const {
    metricsList = [],
    dimensionsList = [],
    dimensionFilters = [],
    advanceSort = [],
  } = selections;
  const getColumnsForPivotTableDownload = (dimList, metricList) => {
    let newDimList = dimList.map((dimListObj) => ({
      id: dimListObj._id,
      dimId: dimListObj.dimID,
      name: dimListObj._title,
      accessor: dimListObj.dimID,
      actualPayload: dimListObj,
    }));
    let newMetricList = metricList.map((metricListObj) => ({
      id: metricListObj._id,
      name: metricListObj._title,
      accessor: metricListObj._id.startsWith("M")
        ? metricListObj.measureID
        : metricListObj._id,
      actualPayload: metricListObj,
    }));
    let finalColumns = {
      dimensionsList: newDimList,
      metricsList: newMetricList,
      allColumns: [...newDimList, ...newMetricList],
    };
    return finalColumns;
  };
  const flattenPivotData = (props = {}) => {
    const { data = [], columns = {} } = props;
    let finalData = [];
    const addChildrenRows = (props = {}) => {
      let { data = [], finalData = [], level = 0 } = props;
      for (const row of data) {
        const newRow = {};
        // Add Dimension Cells
        columns.dimensionsList.map((col, index) => {
          const accessor = col.accessor;
          let value = row[accessor] || "-";
          if (index < level) {
            value = row.parentInfo[index + 1];
          }
          // Convert epoch to correct format for day/hour/month
          const formatDimValueProps = {
            value,
            accessor,
            selections,
          };
          const dimDisplayValue = formatDimValue(formatDimValueProps);
          newRow[accessor] = value === "-" ? value : dimDisplayValue;
        });
        // Add Metrics Cells
        columns.metricsList.map((col) => {
          const accessor = col.accessor;
          let value = row[accessor] || "-";
          if (!isNaN(Number(value))) {
            value = Number(value).toFixed(2);
          }
          newRow[accessor] = value;
        });
        finalData.push(newRow);
        if (row.children.length > 0) {
          const addChildrenRowsProps = {
            data: row.children,
            finalData: finalData,
            level: row.level + 1,
          };
          finalData = addChildrenRows(addChildrenRowsProps);
        }
      }
      return finalData;
    };
    for (const row of data) {
      const newRow = {};
      columns.dimensionsList.map((col) => {
        const accessor = col.accessor;
        const value = row[accessor] || "-";
        // Convert epoch to correct format for day/hour/month
        const formatDimValueProps = {
          value,
          accessor,
          selections,
        };
        const dimDisplayValue = formatDimValue(formatDimValueProps);
        newRow[accessor] = value === "-" ? value : dimDisplayValue;
      });
      columns.metricsList.map((col) => {
        const accessor = col.accessor;
        let value = row[accessor] || "-";
        if (!isNaN(Number(value))) {
          value = Number(value).toFixed(2);
        }
        newRow[accessor] = value;
      });
      finalData.push(newRow);
      if (row.children.length > 0) {
        const addChildrenRowsProps = {
          data: row.children,
          finalData: finalData,
          level: row.level + 1,
        };
        finalData = addChildrenRows(addChildrenRowsProps);
      }
    }
    return finalData;
  };

  const columnsForPivotTableDownload = getColumnsForPivotTableDownload(
    dimensionsList,
    metricsList
  );
  const flattenPivotDataProps = {
    data,
    columns: columnsForPivotTableDownload,
  };
  const flattenedPivotData = flattenPivotData(flattenPivotDataProps);
  const formattedDateRange = formatTimeFilters(
    selections.timeFilters,
    "MM/DD/YY HH:mm:ss"
  );
  // Period Text
  const periodText = `Period: ${formattedDateRange}`;
  const includeFiltersText = makeIncludeFiltersText(dimensionFilters);
  const excludeFiltersText = makeExcludeFiltersText(dimensionFilters);
  const includeBulkFiltersText = makeIncludeBulkFiltersText(dimensionFilters);
  const excludeBulkFiltersText = makeExcludeBulkFiltersText(dimensionFilters);
  const orderByText = `Order By: ${makeOrderByText(advanceSort, allData)}`;
  const tableHeader = makeTableHeader(columnsForPivotTableDownload.allColumns);

  const parser = new Parser({ header: false });
  const tableDataAsCsvString = parser.parse(flattenedPivotData);
  let csvData = "";
  csvData += `${periodText}\n`;
  if (includeFiltersText.length !== 0)
    csvData += `Include Filters: ${includeFiltersText}\n`;
  if (excludeFiltersText.length !== 0)
    csvData += `Exclude Filters: ${excludeFiltersText}\n`;
  if (includeBulkFiltersText.length !== 0)
    csvData += `Include Bulk Filters: ${includeBulkFiltersText}\n`;
  if (excludeBulkFiltersText.length !== 0)
    csvData += `Exclude Bulk Filters: ${excludeBulkFiltersText}\n`;
  csvData += `${orderByText}\n\n`;
  csvData += `${tableHeader}\n`;
  csvData += `${tableDataAsCsvString}\n`;

  // * DEBUGGER
  // console.groupCollapsed("flattenPivotData");
  // console.log("columnsForPivotTableDownload", columnsForPivotTableDownload);
  // console.log("dimensionsList", dimensionsList);
  // console.log("metricsList", metricsList);
  // console.log("data", data);
  // console.log("flattenedPivotData", flattenedPivotData);
  // console.log("periodText", periodText);
  // console.log("includeFiltersText", includeFiltersText);
  // console.log("excludeFiltersText", excludeFiltersText);
  // console.log("includeBulkFiltersText", includeBulkFiltersText);
  // console.log("excludeBulkFiltersText", excludeBulkFiltersText);
  // console.log("orderByText", orderByText);
  // console.groupEnd();

  if (fileType === "csv") {
    downloadCsvFile(csvData, fileName);
  } else if (fileType === "xlsx") {
    downloadExcelFile(csvData, fileName);
  }
};
const downloadPivotComparisonTableData = (props = {}) => {
  const {
    data = [],
    selections = {},
    fileType = "csv",
    fileName = "Pivot Report",
    allData = {},
  } = props;
  const {
    metricsList = [],
    dimensionsList = [],
    dimensionFilters = [],
    advanceSort = [],
  } = selections;
  const getColumnsForPivotTableDownload = (dimList, metricList) => {
    let newDimList = dimList.map((dimListObj) => ({
      id: dimListObj._id,
      dimId: dimListObj.dimID,
      name: dimListObj._title,
      accessor: dimListObj.dimID,
      actualPayload: dimListObj,
    }));
    let newMetricList = [];
    for (const metricListObj of metricList) {
      let normalObj = {
        id: metricListObj._id,
        name: metricListObj._title,
        accessor: metricListObj._id.startsWith("M")
          ? metricListObj.measureID
          : metricListObj._id,
        actualPayload: metricListObj,
      };
      let deltaPercetageObj = {
        id: metricListObj._id,
        name: "% Change",
        accessor: metricListObj._id.startsWith("M")
          ? `${metricListObj.measureID}_deltaPercentage`
          : `${metricListObj._id}_deltaPercentage`,
        actualPayload: metricListObj,
      };
      let trueDeltaObj = {
        id: metricListObj._id,
        name: "Change",
        accessor: metricListObj._id.startsWith("M")
          ? `${metricListObj.measureID}_trueDelta`
          : `${metricListObj._id}_trueDelta`,
        actualPayload: metricListObj,
      };
      newMetricList = [
        ...newMetricList,
        normalObj,
        deltaPercetageObj,
        trueDeltaObj,
      ];
    }

    let finalColumns = {
      dimensionsList: newDimList,
      metricsList: newMetricList,
      allColumns: [...newDimList, ...newMetricList],
    };
    return finalColumns;
  };
  const flattenPivotData = (props = {}) => {
    const { data = [], columns = {} } = props;
    let finalData = [];
    const addChildrenRows = (props = {}) => {
      let { data = [], finalData = [], level = 0 } = props;
      for (const row of data) {
        const newRow = {};
        // Add Dimension Cells
        columns.dimensionsList.map((col, index) => {
          const accessor = col.accessor;
          let value = row[accessor] || "-";
          if (index < level) {
            value = row.parentInfo[index + 1];
          }
          newRow[accessor] = value;
        });
        // Add Metrics Cells
        columns.metricsList.map((col) => {
          const accessor = col.accessor;
          let value = row[accessor] || "-";
          let isValNaN = isNaN(Number(value));
          if (!isValNaN) {
            value = Number(value).toFixed(2);
          }
          if (!isValNaN && accessor.includes("_deltaPercentage")) {
            value = `${value}%`;
          }
          newRow[accessor] = value;
        });
        finalData.push(newRow);
        if (row.children.length > 0) {
          const addChildrenRowsProps = {
            data: row.children,
            finalData: finalData,
            level: row.level + 1,
          };
          finalData = addChildrenRows(addChildrenRowsProps);
        }
      }
      return finalData;
    };
    for (const row of data) {
      const newRow = {};
      columns.dimensionsList.map((col) => {
        const accessor = col.accessor;
        const value = row[accessor] || "-";
        newRow[accessor] = value;
      });
      columns.metricsList.map((col) => {
        const accessor = col.accessor;
        let value = row[accessor] || "-";
        let isValNaN = isNaN(Number(value));
        if (!isValNaN) {
          value = Number(value).toFixed(2);
        }
        if (!isValNaN && accessor.includes("_deltaPercentage")) {
          value = `${value}%`;
        }
        newRow[accessor] = value;
      });
      finalData.push(newRow);
      if (row.children.length > 0) {
        const addChildrenRowsProps = {
          data: row.children,
          finalData: finalData,
          level: row.level + 1,
        };
        finalData = addChildrenRows(addChildrenRowsProps);
      }
    }
    return finalData;
  };

  const columnsForPivotTableDownload = getColumnsForPivotTableDownload(
    dimensionsList,
    metricsList
  );
  const flattenPivotDataProps = {
    data,
    columns: columnsForPivotTableDownload,
  };
  const flattenedPivotData = flattenPivotData(flattenPivotDataProps);
  const formattedDateRange = formatTimeFilters(
    selections.timeFilters,
    "MM/DD/YY HH:mm:ss"
  );
  const formattedCompareDateRange = formatCompareTimeFilters(
    selections.timeFilters,
    "MM/DD/YY HH:mm:ss"
  );
  // Period Text
  const periodText = `Period: ${formattedDateRange}`;
  const comparePeriodText = `Comparison Period: ${formattedCompareDateRange}`;
  const includeFiltersText = makeIncludeFiltersText(dimensionFilters);
  const excludeFiltersText = makeExcludeFiltersText(dimensionFilters);
  const includeBulkFiltersText = makeIncludeBulkFiltersText(dimensionFilters);
  const excludeBulkFiltersText = makeExcludeBulkFiltersText(dimensionFilters);
  const orderByText = `Order By: ${makeOrderByText(advanceSort, allData)}`;
  const tableHeader = makeTableHeader(columnsForPivotTableDownload.allColumns);

  const parser = new Parser({ header: false });
  const tableDataAsCsvString = parser.parse(flattenedPivotData);
  let csvData = "";
  csvData += `${periodText}\n`;
  csvData += `${comparePeriodText}\n`;
  if (includeFiltersText.length !== 0)
    csvData += `Include Filters: ${includeFiltersText}\n`;
  if (excludeFiltersText.length !== 0)
    csvData += `Exclude Filters: ${excludeFiltersText}\n`;
  if (includeBulkFiltersText.length !== 0)
    csvData += `Include Bulk Filters: ${includeBulkFiltersText}\n`;
  if (excludeBulkFiltersText.length !== 0)
    csvData += `Exclude Bulk Filters: ${excludeBulkFiltersText}\n`;
  csvData += `${orderByText}\n\n`;
  csvData += `${tableHeader}\n`;
  csvData += `${tableDataAsCsvString}\n`;

  // * DEBUGGER
  // console.groupCollapsed("flattenPivotData");
  // console.log("columnsForPivotTableDownload", columnsForPivotTableDownload);
  // console.log("dimensionsList", dimensionsList);
  // console.log("metricsList", metricsList);
  // console.log("data", data);
  // console.log("flattenedPivotData", flattenedPivotData);
  // console.log("periodText", periodText);
  // console.log("includeFiltersText", includeFiltersText);
  // console.log("excludeFiltersText", excludeFiltersText);
  // console.log("includeBulkFiltersText", includeBulkFiltersText);
  // console.log("excludeBulkFiltersText", excludeBulkFiltersText);
  // console.log("orderByText", orderByText);
  // console.groupEnd();

  if (fileType === "csv") {
    downloadCsvFile(csvData, fileName);
  } else if (fileType === "xlsx") {
    downloadExcelFile(csvData, fileName);
  }
};

// ! common functions - of all the tables
// * Making styles - Common style of all Pivot Table
const makeSigviewStyles = (...args) => {
  const [themeColors] = args;
  const useStyles = makeStyles(() => ({
    mainContainer: {
      padding: "0px 22px",
      height: "30px",
      display: "flex",
      alignItems: "center",
    },
    table: {
      width: "0px !important",
    },
    tableMain: {
      // margin: " 50px 0px 20px 20px",
    },
    tableHead: {
      width: "100%",
    },
    tableHeadTitle: {
      // width: `calc(100% - 50px)`,
      boxSizing: "border-box",
    },
    tableHeadIcon: {
      // width: "10px !important",
    },
    tableBody: {
      color: "red",
    },
    tableRow: {
      color: "green",
      padding: "20px",
    },

    tableRowHeader: {
      paddingTop: "500px !important",
    },

    tableCellHeader: {
      // color: themeColors["secondaryColor"],
      backgroundColor: themeColors["secondaryColorLightest"],
      borderRightStyle: "solid",
      borderRightColor: "#dfdfdf",
      display: "tableRowGroup",
      fontFamily: "Fira Sans",
      fontWeight: "500 !important",
      fontSize: "12px !important",
      padding: "5px 11px 5px 11px",
      // maxWidth: "70px !important",
      // minWidth: "70px !important",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    totaltableCellHeader: {
      backgroundColor: "#ddeeff !important",
      borderRightStyle: "solid",
      borderRightColor: "#dfdfdf",
      fontFamily: "Fira Sans",
      fontWeight: "400",
      fontSize: "11px !important",
      padding: "5px 11px 5px 11px",
      textAlign: "center",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    tableCellHeaderRow2: {
      // color: themeColors["secondaryColor"],
      backgroundColor: themeColors["headerBgColor"],
      borderRightStyle: "solid",
      borderRightColor: "#dfdfdf",
      display: "tableRowGroup",
      fontFamily: "Fira Sans",
      fontWeight: "400 !important",
      fontSize: "12px !important",
      minWidth: "90px", // Date wrap in table CSS
      padding: "3px !important",
      textAlign: "center !important",
    },

    sticky: {
      position: "sticky",
      left: 0,
    },

    tableCell: {
      borderRightStyle: "solid",
      borderRightColor: "#dfdfdf",
      fontFamily: "Fira Sans",
      fontSize: "11px !important",
      textAlign: "center",
      padding: "3px 11px 3px 11px",
      whiteSpace: "nowrap",
    },
    tableData: {},
    elementActive: {
      // color: themeColors["primaryColor"],
      cursor: "pointer",
      paddingLeft: "3px",
      paddingRight: "3px",
    },
    elementDisabled: {
      // color: themeColors["secondaryColor"],
      paddingLeft: "3px",
      paddingRight: "3px",
    },
    tableColumns: {
      border: "10px solid white",
      background: "grey",
    },
    totalRow: {
      backgroundColor: "#C4C9CC !important",
    },
    negChange: {
      color: themeColors["negChangeColor"],
    },
    posChange: {
      color: themeColors["posChangeColor"],
    },
  }));
  return useStyles;
};

const formatDimValue = (props = {}) => {
  const { value = "", accessor = "", selections = {} } = props;
  let displayVal = value;
  const minutesOffset = selections.timeFilters.selectedTimezone.minutesOffset;
  switch (accessor) {
    case "hour":
      displayVal = moment(Number(value))
        .utcOffset(minutesOffset)
        .format("MM-DD HH:mm");
      break;
    case "day":
      displayVal = moment(Number(value))
        .utcOffset(minutesOffset)
        .format("YYYY-MM-DD");
      break;
    case "month":
      displayVal = moment(Number(value))
        .utcOffset(minutesOffset)
        .format("YYYY-MM");
      break;
    default:
      displayVal = value;
  }
  return displayVal;
};

const getSortDetails = (row, advanceSort) => {
  const allSortedOnIds = advanceSort.map((row) => row.sortedOn);
  const allOrderBy = advanceSort.map((row) => (row.desc ? "desc" : "asc"));
  const uniqueSortedOnIds = [...new Set(allSortedOnIds)];
  const uniqueOrderBy = [...new Set(allOrderBy)];
  let isAllDimSortedOnThisMetric = false;
  let orderBy = "asc";
  if (uniqueSortedOnIds.length === 1 && uniqueOrderBy.length === 1) {
    if (uniqueSortedOnIds[0] === row.id) {
      isAllDimSortedOnThisMetric = true;
      orderBy = uniqueOrderBy[0];
    }
  }
  // return orderByText;
  const sortDetails = { isAllDimSortedOnThisMetric, orderBy };
  return sortDetails;
};

const extractOrderFromAdvanceSort = (props = {}) => {
  const { advanceSort = [], dimension = {}, allData = {} } = props;
  const dimObjInAdvanceSort = advanceSort.find(
    (row) => row.dimId === dimension._id
  );
  if (dimObjInAdvanceSort) {
    const sortType = dimObjInAdvanceSort.sortType;
    const orderBy = dimObjInAdvanceSort.desc ? "desc" : "asc";
    let orderById = "";
    if (sortType === "metric") {
      if (dimObjInAdvanceSort.sortedOn.includes("deltaPercentage")) {
        const measureObj = getMeasureObjByOriginalID(
          dimObjInAdvanceSort.sortedOn.replace("_deltaPercentage", ""),
          allData.plotlyMetrics
        );
        orderById = measureObj?._id + "_deltaPercentage";
      } else if (dimObjInAdvanceSort.sortedOn.includes("trueDelta")) {
        const measureObj = getMeasureObjByOriginalID(
          dimObjInAdvanceSort.sortedOn.replace("_trueDelta", ""),
          allData.plotlyMetrics
        );
        orderById = measureObj?._id + "_trueDelta";
      } else {
        const measureObj = getMeasureObjByOriginalID(
          dimObjInAdvanceSort.sortedOn,
          allData.plotlyMetrics
        );
        orderById = measureObj?._id;
      }
    } else if (sortType === "dimension") {
      const dimensionObj = getDimensionObjByOriginalID(
        dimObjInAdvanceSort.sortedOn,
        allData.plotlyDimensions
      );
      orderById = dimensionObj?._id;
    }
    return {
      orderBy,
      orderById: orderById,
    };
  } else {
    console.error("extractOrderFromAdvanceSort failed");
    console.groupCollapsed("Details below");
    console.log("advanceSort", advanceSort);
    console.log("dimension", dimension);
    console.groupEnd();
    return { orderBy: "", orderById: "" };
  }
};

const compareRowUiDataFriendly = (rowUiDataProps = {}) => {
  const {
    getDataResponse,
    columnData,
    columnIndex,
    dataLimit,
    newDimensionInfo = "",
    dimensionInfo = [],
  } = rowUiDataProps;

  //   // * DEBUGGER
  //   console.groupCollapsed("rowUiDataFriendly");
  //   console.log("columnIndex", columnIndex);
  //   console.log("getDataResponse", getDataResponse);
  //   console.log("newDimensionInfo", newDimensionInfo);
  //   console.log("dimensionInfo", dimensionInfo);
  //   console.groupEnd();

  const currentcoldata = columnData[columnIndex];
  const newRowData = getDataResponse.map((dataArr, index) => {
    let metricData = {};
    for (let i = 1; i < currentcoldata.length; i++) {
      const temp = {
        [currentcoldata[i].normal.accessor]:
          dataArr[currentcoldata[i].normal.accessor],
        [currentcoldata[i].deltaPercentage.accessor]:
          dataArr[currentcoldata[i].deltaPercentage.accessor],
        [currentcoldata[i].trueDelta.accessor]:
          dataArr[currentcoldata[i].trueDelta.accessor],
      };
      metricData = { ...metricData, ...temp };
    }
    const rowData = {
      [currentcoldata[0].accessor]: dataArr[currentcoldata[0].accessor],
      ...metricData,
      isLastChild: false, // for showing plus sign
      isExpanded: false, // plus or minus icon flag
      isChildrenLoading: false, //is there a loading sign or not
      isClickButton: columnData.length > columnIndex + 1 ? true : false,
      isShowMore:
        getDataResponse.length === dataLimit &&
        index === getDataResponse.length - 1
          ? true
          : false,
      level: columnIndex,
      dataLimit: dataLimit,
      parentInfo: [...dimensionInfo, newDimensionInfo],
      children: [],
    };
    return rowData;
  });
  return newRowData;
};

// Function to make the row ui friendly
const rowUiDataFriendly = (rowUiDataProps = {}) => {
  const {
    getDataResponse,
    columnData,
    columnIndex,
    dataLimit,
    newDimensionInfo = "",
    dimensionInfo = [],
    apiResponseLength,
    errMessage = "",
    actualParent = {},
    clearChilren = "false",
  } = rowUiDataProps;

  // * DEBUGGER
  // console.groupCollapsed("rowUiDataFriendly");
  // console.log("apiResponseLength", apiResponseLength);
  // console.log("dataLimit", dataLimit);
  // console.log("getDataResponse.length ", getDataResponse.length - 1);
  // console.log("dimensionInfo", dimensionInfo);
  // console.log("clearChilren", clearChilren);
  // console.groupEnd();

  const currentcoldata = columnData?.[columnIndex];
  if (getDataResponse.length === 0 && clearChilren === "false") {
    const rowData = {
      isLastChild: false, // for showing plus sign
      isExpanded: false, // plus or minus icon flag
      isChildrenLoading: false, //is there a loading sign or not
      isClickButton: columnData.length > columnIndex + 1 ? true : false,
      isShowMore: false,
      level: columnIndex,
      dataLimit: dataLimit,
      parentInfo: [...dimensionInfo, newDimensionInfo],
      children: [],
      isError: true, // true or false on the basics of API
      errMessage,
      actualParent,
    };
    return [rowData];
  } else {
    const newRowData = getDataResponse.map((dataArr, index) => {
      let metricData = {};
      for (let i = 1; i < currentcoldata.length; i++) {
        const temp = {
          [currentcoldata[i].accessor]: dataArr[currentcoldata[i].accessor],
        };
        metricData = { ...metricData, ...temp };
      }
      const rowData = {
        [currentcoldata[0].accessor]: dataArr[currentcoldata[0].accessor],
        ...metricData,
        isLastChild: false, // for showing plus sign
        isExpanded: false, // plus or minus icon flag
        isChildrenLoading: false, //is there a loading sign or not
        isClickButton: columnData.length > columnIndex + 1 ? true : false,
        isShowMore:
          apiResponseLength === dataLimit &&
          index === getDataResponse.length - 1
            ? true
            : false,
        level: columnIndex,
        dataLimit: dataLimit,
        parentInfo: [...dimensionInfo, newDimensionInfo],
        children: [],
        isError: false, // true or false on the basics of API
      };
      return rowData;
    });
    return newRowData;
  }
};

// Function to make the Total Row ui friendly
const totalRowUiDataFriendly = (rowUiDataProps = {}) => {
  const { getDataResponse, columnData, columnIndex } = rowUiDataProps;
  const currentcoldata = columnData[columnIndex];
  const newRowData = getDataResponse.map((dataArr, index) => {
    let metricData = { [currentcoldata[0].accessor]: "Total" };
    for (let i = 1; i < currentcoldata.length; i++) {
      const temp = {
        [currentcoldata[i].accessor]: dataArr[currentcoldata[i].accessor],
      };
      metricData = { ...metricData, ...temp };
    }
    const rowData = {
      [currentcoldata[0].accessor]: dataArr[currentcoldata[0].accessor],
      ...metricData,
      isLastChild: false, // for showing plus sign
      isExpanded: false, // plus or minus icon flag
      isChildrenLoading: false, //is there a loading sign or not
      isClickButton: false,
      isShowMore: false,
      level: columnIndex,
      children: [],
      isError: false,
    };
    return rowData;
  });
  return newRowData;
};

// Func - for making ui friendly dimension filters
const makeUiFriendlyDimensionFilters = (columns, row) => {
  // var additionalDimensionFilters = [
  //   { id: "D035", values: ["541052569"] },
  //   { id: "D020", values: ["India"] },
  //   { id: "D050", values: ["Display"] },
  // ];

  const rowLevel = row.level;
  // * DEBUGGER
  // console.groupCollapsed("makeUiFriendlyDimensionFilters");
  // console.log("columns", columns);
  // console.log("row", row);
  // console.groupEnd();

  const additionalDimensionFilters = [];
  for (let i = 0; i < rowLevel; i++) {
    const newDimensionFilters = {
      id: columns[i][0].id,
      values: [row.parentInfo[i + 1]],
      metadata: {
        ...columns[i][0].actualPayload,
      },
      filterType: "include",
      advancedFilters: [],
      valid: true,
    };
    additionalDimensionFilters.push(newDimensionFilters);
  }
  return additionalDimensionFilters;
};

// Helper Func - updateChildrenShowMoreLoading
const updateChildrenShowMoreRecursively = (
  updateChildrenShowMoreRecursivelyProps = {}
) => {
  const { id, key, children, value, row, selections } =
    updateChildrenShowMoreRecursivelyProps;
  // console.groupCollapsed("updateChildrenShowMoreRecursively");
  // console.log("children", children);
  // console.log("value", value);
  // console.log("id", id);
  // console.log("key", key);
  // console.log("row.parentInfo", row.parentInfo);
  // console.groupEnd();
  const updatedChildren = children.map((el) => {
    const elMod = {
      ...el,
      isChildrenLoading:
        el[key] === id && isEqual(el.parentInfo, row.parentInfo)
          ? value
          : el.isChildrenLoading,
      dataLimit:
        el[key] === id && isEqual(el.parentInfo, row.parentInfo)
          ? el.dataLimit + selections.dataLimit
          : el.dataLimit,
      isShowMore:
        el[key] === id && isEqual(el.parentInfo, row.parentInfo)
          ? false
          : el.isShowMore,
      children: updateChildrenShowMoreRecursively({
        id,
        key,
        children: el.children,
        value,
        row,
        selections,
      }),
    };
    return elMod;
  });
  return updatedChildren;
};

// Func - for showing/removing the loader in the showMore row
const updateChildrenShowMoreLoading = (updateChildrenLoadingProps = {}) => {
  const {
    columns = [],
    data = [],
    row = {},
    rowLevel = "",
    value = true,
    selections,
  } = updateChildrenLoadingProps;

  const id = row[columns[rowLevel][0].accessor];
  const key = columns[rowLevel][0].accessor;

  // * DEBUGGER
  // console.groupCollapsed("updateChildrenLoading");
  // console.log("updateChildrenLoadingProps", updateChildrenLoadingProps);
  // console.log("columns", columns);
  // console.log("pivotData", data);
  // console.log("row", row);
  // console.log("rowLevel", rowLevel);
  // console.log("id", id);
  // console.log("key", key);
  // console.groupEnd();
  const updateChildrenShowMoreRecursivelyProps = {
    id,
    key,
    children: data,
    value,
    row,
    selections,
  };

  const updatedData = updateChildrenShowMoreRecursively(
    updateChildrenShowMoreRecursivelyProps
  );
  return updatedData;
};

// Helper Func - updateChildrenData
const updateChildrenDataRecursively = (
  updateChildrenDataRecursivelyProps = {}
) => {
  const {
    id,
    key,
    children,
    value,
    rowLevel,
    dataLimit,
    isExpandedValue,
    errMessage,
    actualParent,
    clearChilren,
    columns,
    row,
    isComparisonTable,
  } = updateChildrenDataRecursivelyProps;

  // * DEBUGGER
  // console.groupCollapsed("updateChildrenDataRecursively");
  // console.log("id", id);
  // console.log("key", key);
  // console.log("rowLevel", rowLevel);
  // console.log("parentInfo.length", parentInfo.length);
  // console.log("parentInfoIndex", parentInfoIndex);
  // console.log("parentId", columns[rowLevel][0].accessor);
  // console.log("row", row);
  // console.groupEnd();

  const updatedChildren = children.map((el) => {
    // console.log("el", el);
    const elMod = {
      ...el,
      isChildrenLoading:
        el[key] === id && isEqual(el.parentInfo, row.parentInfo)
          ? false
          : el.isChildrenLoading,
      isExpanded:
        el[key] === id && isEqual(el.parentInfo, row.parentInfo)
          ? isExpandedValue
          : el.isExpanded,

      children:
        el[key] === id && isEqual(el.parentInfo, row.parentInfo)
          ? isComparisonTable
            ? compareRowUiDataFriendly({
                getDataResponse: value,
                columnData: columns,
                columnIndex: rowLevel + 1,
                dataLimit,
                newDimensionInfo: id,
                dimensionInfo: el.parentInfo,
                apiResponseLength: value.length,
                errMessage,
                actualParent,
                clearChilren,
                columns,
                row,
              })
            : rowUiDataFriendly({
                getDataResponse: value,
                columnData: columns,
                columnIndex: rowLevel + 1,
                dataLimit,
                newDimensionInfo: id,
                dimensionInfo: el.parentInfo,
                apiResponseLength: value.length,
                errMessage,
                actualParent,
                clearChilren,
                columns,
                row,
              })
          : updateChildrenDataRecursively({
              id,
              key,
              children: el.children,
              value,
              rowLevel,
              dataLimit,
              isExpandedValue,
              errMessage,
              actualParent,
              clearChilren,
              columns,
              row,
            }),
    };
    // console.log("elMod", elMod);
    return elMod;
  });
  return updatedChildren;
};

// Func - for updating the childrenData with new api data
const updateChildrenData = (updateChildrenDataProps = {}) => {
  const {
    apiResponse,
    columns,
    data,
    row,
    dataLimit,
    isExpandedValue,
    clearChilren,
    isComparisonTable,
  } = updateChildrenDataProps;

  const rowLevel = row.level;
  const id = row[columns[rowLevel][0].accessor];
  const key = columns[rowLevel][0].accessor;

  // * DEBUGGER
  // console.groupCollapsed("updateChildrenData");
  // console.log("id", id);
  // console.log("data", data);
  // console.log("key", key);
  // console.log("apiResponse", apiResponse);
  // console.log("apiResponse", apiResponse.length);
  // console.log("rowLevel", rowLevel);
  // console.log("dataLimit", dataLimit);
  // console.log("row", row);
  // console.log("parentId", columns[rowLevel][0].accessor);
  // console.groupEnd();
  const updateChildrenDataRecursivelyProps = {
    id,
    key,
    children: data,
    value: apiResponse,
    rowLevel,
    dataLimit,
    isExpandedValue,
    clearChilren,
    columns,
    row,
    isComparisonTable,
  };
  let newChildrenData = updateChildrenDataRecursively(
    updateChildrenDataRecursivelyProps
  );
  return newChildrenData;
};

// Helper Func - updateChildrenLoading
const updateChildrenLoadingRecursively = (
  updateChildrenLoadingRecursivelyProps = {}
) => {
  const { id, key, children, value, row } =
    updateChildrenLoadingRecursivelyProps;
  const updatedChildren = children.map((el) => {
    const elMod = {
      ...el,
      isChildrenLoading:
        el[key] === id && isEqual(el.parentInfo, row.parentInfo)
          ? value
          : el.isChildrenLoading,
      children: updateChildrenLoadingRecursively({
        id,
        key,
        children: el.children,
        value,
        row,
      }),
    };
    return elMod;
  });
  return updatedChildren;
};
// Func - for showing/removing the loader in the row
const updateChildrenLoading = (updateChildrenLoadingProps = {}) => {
  const {
    columns = [],
    data = [],
    row = {},
    rowLevel = "",
    value = true,
  } = updateChildrenLoadingProps;

  const id = row[columns[rowLevel][0].accessor];
  const key = columns[rowLevel][0].accessor;
  const updateChildrenLoadingRecursivelyProps = {
    id,
    key,
    children: data,
    value,
    row,
  };

  const updatedData = updateChildrenLoadingRecursively(
    updateChildrenLoadingRecursivelyProps
  );
  return updatedData;
};

// Function to make the dynamic column using the dimensionList & metricaList
const getColumn = (dimList, metricList) => {
  let newDimObj = dimList.map((dimListObj) => {
    let tempObj = {
      id: dimListObj._id,
      dimId: dimListObj.dimID,
      name: "Dimension",
      orderBy: "desc",
      accessor: dimListObj.dimID,
      actualPayload: dimListObj,
    };
    let newMericObj = metricList.map((metricListObj) => ({
      id: metricListObj._id,
      name: metricListObj._title,
      orderBy: "desc",
      accessor: metricListObj._id.startsWith("M")
        ? metricListObj.measureID
        : metricListObj._id,
      actualPayload: metricListObj,
    }));
    newMericObj.unshift(tempObj);
    return newMericObj;
  });
  return newDimObj;
};

// Helper Function of updateRowData
const updateRowDataRecursively = (updateRowDataRecursivelyProps = {}) => {
  const {
    showMoreId,
    key,
    data,
    apiResponse,
    rowLevel,
    dataLimit,
    row,
    previousDataLimit,
    showMoreLevel,
    columns,
    isComparisonTable,
  } = updateRowDataRecursivelyProps;
  // * DEBUGGER
  // console.groupCollapsed("updateRowDataRecursively");
  // console.log("showMoreId", showMoreId);
  // console.log("data", data);
  // console.log("key", key);
  // console.log("apiResponse", apiResponse);
  // console.log("apiResponse", apiResponse.length);
  // console.log("previousDataLimit", previousDataLimit);
  // console.log("rowLevel", rowLevel);
  // console.log("dataLimit", dataLimit);
  // console.log("row", row);
  // console.groupEnd();

  if (dataLimit > apiResponse.length) {
    return data;
  } else {
    if (showMoreLevel === 0) {
      const updatedChildren = isComparisonTable
        ? compareRowUiDataFriendly({
            getDataResponse: apiResponse.slice(previousDataLimit),
            columnData: columns,
            // columnIndex: rowLevel + 1,
            columnIndex: rowLevel === 0 ? 0 : row.level,
            dataLimit,
            newDimensionInfo: showMoreId,
            dimensionInfo: "",
            apiResponseLength: apiResponse.length,
          })
        : rowUiDataFriendly({
            getDataResponse: apiResponse.slice(previousDataLimit),
            columnData: columns,
            // columnIndex: rowLevel + 1,
            columnIndex: rowLevel === 0 ? 0 : row.level,
            dataLimit,
            newDimensionInfo: showMoreId,
            dimensionInfo: "",
            apiResponseLength: apiResponse.length,
          });
      return [...data, ...updatedChildren];
    } else {
      const updatedChildren = data.map((el) => {
        const elMod = {
          ...el,
          children:
            el[key] === showMoreId
              ? isComparisonTable
                ? [
                    ...el.children,
                    ...compareRowUiDataFriendly({
                      getDataResponse: apiResponse.slice(previousDataLimit),
                      columnData: columns,
                      // columnIndex: rowLevel + 1,
                      columnIndex: rowLevel === 0 ? 0 : row.level,
                      dataLimit,
                      newDimensionInfo: showMoreId,
                      dimensionInfo: el.parentInfo,
                      apiResponseLength: apiResponse.length,
                    }),
                  ]
                : [
                    ...el.children,
                    ...rowUiDataFriendly({
                      getDataResponse: apiResponse.slice(previousDataLimit),
                      columnData: columns,
                      // columnIndex: rowLevel + 1,
                      columnIndex: rowLevel === 0 ? 0 : row.level,
                      dataLimit,
                      newDimensionInfo: showMoreId,
                      dimensionInfo: el.parentInfo,
                      apiResponseLength: apiResponse.length,
                    }),
                  ]
              : updateRowDataRecursively({
                  showMoreId,
                  key,
                  data: el.children,
                  apiResponse,
                  rowLevel,
                  dataLimit,
                  row,
                  previousDataLimit,
                }),
        };
        return elMod;
      });

      return updatedChildren;
    }
  }
};

// Function to update the new Row Data eg - update show more with new row data
const updateRowData = (updateRowDataProps = {}) => {
  const {
    apiResponse,
    columns,
    data,
    row,
    dataLimit,
    previousDataLimit,
    isComparisonTable,
  } = updateRowDataProps;
  const rowLevel = row.level - 1 < 0 ? row.level - 1 : row.level;
  // const key = columns[rowLevel][0].accessor;
  const key =
    rowLevel < 0 ? columns[0][0].accessor : columns[row.level - 1][0].accessor;
  // const showMoreId = row.parentInfo[row.level];
  const showMoreId = rowLevel < 0 ? row[key] : row.parentInfo[row.level];
  const showMoreLevel = rowLevel < 0 ? 0 : row.parentInfo[row.level];

  // * DEBUGGER
  // console.groupCollapsed("updateRowData");
  // console.log("row.level", row.level);
  // console.log("showMoreId", row.parentInfo);
  // console.groupEnd();

  const updateRowDataRecursivelyProps = {
    showMoreId,
    key,
    data,
    apiResponse,
    rowLevel,
    dataLimit,
    row,
    previousDataLimit,
    showMoreLevel,
    columns,
    isComparisonTable,
  };

  let newChildrenData = updateRowDataRecursively(updateRowDataRecursivelyProps);
  return newChildrenData;
};

// Function to make Row level Filter for Transpose table
const makeRowLevelFilterForTranspose = (
  columns,
  currentRowFilter,
  rowLevel
) => {
  // const rowLevel = row.level;
  const additionalDimensionFilters = [];
  const newDimensionFilters = {
    id: columns[rowLevel][0].id,
    values: [...currentRowFilter],
    metadata: {
      ...columns[rowLevel][0].actualPayload,
    },
    filterType: "include",
    advancedFilters: [],
    valid: true,
  };
  additionalDimensionFilters.push(newDimensionFilters);
  return additionalDimensionFilters;
};
// Funciotn to make the tranpose Toal Row Ui Data Friendly for Transpose table
const transposeTotalRowUiDataFriendly = (
  transposeRowUiDataFriendlyProps = {}
) => {
  const { getDataResponse, pivotData, selections } =
    transposeRowUiDataFriendlyProps;

  // * DEBUGGER
  // console.log("key", key);
  // console.groupCollapsed("TOTAL FUNCTIONS TRANSPOSE");
  // console.log("columnIndex", columnIndex);
  // console.log("getDataResponse", getDataResponse);
  // console.log("columnData", columnData);
  // console.log("dataLimit", dataLimit);
  // console.log("pivotData", pivotData);
  // console.log("currentcoldata", currentcoldata);
  // console.groupEnd();

  var newData = [];
  getDataResponse.map((getResponseEl, index) => {
    // ! month walla khali hai
    const newRowData = selections.metricsList.map((el) => {
      let key;
      if (getResponseEl.hour) {
        key = `${el.measureTitle}_${moment(Number(getResponseEl.hour))
          .utcOffset(selections.timeFilters.selectedTimezone.minutesOffset)
          .format("MM-DD HH:mm")}`;
      } else if (getResponseEl.day) {
        key = `${el.measureTitle}_${moment(Number(getResponseEl.day))
          .utcOffset(selections.timeFilters.selectedTimezone.minutesOffset)
          .format("YYYY-MM-DD")}`;
      } else if (getResponseEl.month) {
      }
      const obj = {
        [key]: getResponseEl[el.measureID],
      };
      return obj;
    });

    const finalData = pivotData.result.map((el, index) => {
      if (index === 0) {
        // ! hack to spred the
        const obj = Object.assign(el, ...newRowData);
        const elMod = {
          ...el,
          ...obj,
        };
        return elMod;
      } else return el;
    });
    newData = finalData;
  });
  return newData;
};

// Helper Func for the transposeRowUiDataFriendly
const updateTransposeRowDataRecursevily = (
  children,
  newRowData,
  key,
  newData
) => {
  const updatedChildren = children.map((el) => {
    if (newData[key] === el[key]) {
      // ! hack to spred the
      const obj = Object.assign(el, ...newRowData);
      const elMod = {
        ...el,
        ...obj,
      };
      return elMod;
    }
    return el;
  });
  return updatedChildren;
};
//Func to make transpose data into ui Friendly data eg - update tranpose show more with new data
const transposeRowUiDataFriendly = (transposeRowUiDataFriendlyProps = {}) => {
  const { getDataResponse, columnData, columnIndex, pivotData, selections } =
    transposeRowUiDataFriendlyProps;

  const currentcoldata = columnData[columnIndex][0].accessor;

  const key = currentcoldata;
  // * DEBUGGER
  // console.groupCollapsed("transposeRowUiDataFriendly");
  // console.log("columnIndex", columnIndex);
  // console.log("getDataResponse", getDataResponse);
  // console.log("columnData", columnData);
  // console.log("dataLimit", dataLimit);
  // console.log("pivotData", pivotData);
  // console.log("currentcoldata", currentcoldata);
  // console.log("key", key);
  // console.groupEnd();
  var final = [];
  const updatedRow = getDataResponse.map((getResponseEl, index) => {
    const id = getResponseEl[key];
    const children = pivotData.result;
    // ! month walla khali hai
    const newRowData = selections.metricsList.map((el) => {
      let key;
      if (getResponseEl.hour) {
        key = `${el.measureTitle}_${moment(Number(getResponseEl.hour))
          .utcOffset(selections.timeFilters.selectedTimezone.minutesOffset)
          .format("MM-DD HH:mm")}`;
      } else if (getResponseEl.day) {
        key = `${el.measureTitle}_${moment(Number(getResponseEl.day))
          .utcOffset(selections.timeFilters.selectedTimezone.minutesOffset)
          .format("YYYY-MM-DD")}`;
      } else if (getResponseEl.month) {
        key = `${el.measureTitle}_${moment(Number(getResponseEl.month))
          .utcOffset(selections.timeFilters.selectedTimezone.minutesOffset)
          .format("YYYY-MM")}`;
      }
      const obj = {
        [key]: getResponseEl[el.measureID],
      };
      return obj;
    });
    const updatedChildren = updateTransposeRowDataRecursevily(
      children,
      newRowData,
      key,
      getResponseEl
    );
    // console.log("show More", updatedChildren);
    final = [...updatedChildren];
  });
  return final;
};

// Helper Fucn for updateChildrenDataTranpose
const updateChildrenDataTranposeRecursively = (
  id,
  pivotarr,
  newData,
  currentcoldata
) => {
  const updatedChildren = pivotarr.map((el) => {
    // console.log("currentcoldata", currentcoldata);
    // console.log("el", el);
    // console.log("id", id);
    // console.log("el.currentcoldata", el[currentcoldata]);
    if (el[currentcoldata] === id) {
      const obj = Object.assign(el, ...newData);
      const elMod = {
        ...el,
        ...obj,
      };
      return elMod;
    }

    return el;
  });
  return updatedChildren;
};
// Func update the children Data on showMore && +icon click for tranpose table
const updateChildrenDataTranpose = (
  id,
  pivotarr,
  newRowData,
  currentcoldata
) => {
  // * DEBUGGER
  // console.groupCollapsed("updateChildrenDataTranpose");
  // console.log("id", id);
  // console.log("pivotarr", pivotarr);
  // console.log("newRowData", newRowData);
  // console.groupEnd();
  const updatedChildren = updateChildrenDataTranposeRecursively(
    id,
    pivotarr,
    newRowData,
    currentcoldata
  );
  // console.log("updatedChildren", updatedChildren);
  return updatedChildren;
};

// * Validating missing attributes for SharePivot
const isSharePivotValidBasedOnUserAccess = (props = {}) => {
  // * Destructure props
  const { backendWs = {}, user = {}, allData = {} } = props;
  const { metadata = {}, requestParam = {} } = backendWs;

  // * Response initializations
  let dimensionsList = [];
  let metricsList = [];
  let customMetricsList = [];
  // * Dimensions
  dimensionsList = [...requestParam.xAxis]; // Use spread to avoid mutation
  // * Dimension Filters
  dimensionsList = dimensionsList.concat(requestParam.filter.map((x) => x.id));
  // * Metrics and Custom Metrics
  metricsList = [...requestParam.yAxis];
  customMetricsList = [...requestParam.specialCalculation];
  metricsList = [...metricsList, ...customMetricsList];

  let response = {
    status: "valid",
    message: "",
    metadata: { missingMetrics: [], missingDimensions: [] },
  };

  // * Take unique values
  dimensionsList = [...new Set(dimensionsList)];
  metricsList = [...new Set(metricsList)];

  // * Checking if all attributes are present in the access list
  const allMetricIds = allData.plotlyMetrics.map((row) => row._id);
  const allDimensionIds = allData.plotlyDimensions.map((row) => row._id);

  const missingDimensionList = dimensionsList.filter(
    (id) => !allDimensionIds.includes(id)
  );
  const missingMetricsList = metricsList.filter(
    (id) => !allMetricIds.includes(id)
  );

  // * Final valid check
  const isInvalidFlag =
    missingDimensionList.length > 0 || missingMetricsList.length > 0;
  if (isInvalidFlag) {
    const missingMetrics = missingMetricsList.filter((id) =>
      id.startsWith("M")
    );
    const missingCustomMetrics = missingMetricsList.filter((id) =>
      id.startsWith("CM")
    );

    response = {
      status: "invalid",
      message: "",
      metadata: {
        missingMetrics: missingMetrics,
        missingCustomMetrics: missingCustomMetrics,
        missingDimensions: missingDimensionList,
      },
    };
  }
  // * DEBUGGER
  // console.groupCollapsed("isWorkspaceValidBasedOnUserAccess");
  // console.log("dimensionsList", dimensionsList);
  // console.log("metricsList", metricsList);
  // console.log("customMetricsList", customMetricsList);
  // console.log("missingDimensionList", missingDimensionList);
  // console.log("missingMetricsList", missingMetricsList);
  // console.log("response", response);
  // console.groupEnd();
  return response;
};

export {
  initialUserSelection,
  getValidAdvanceSort,
  formatDimValue,
  getSortDetails,
  extractOrderFromAdvanceSort,
  getComparisonHeaderColumns,
  getTransposeTableHeaderColumns,
  getTransposeColumn,
  makeAdditionalTimeFilters,
  getValidDimensionSort,
  getValidCompareDimension,
  rowUiDataFriendly,
  totalRowUiDataFriendly,
  getColumn,
  updateRowData,
  makeRowLevelFilterForTranspose,
  transposeTotalRowUiDataFriendly,
  transposeRowUiDataFriendly,
  updateChildrenDataTranpose,
  makeSigviewStyles,
  makeUiFriendlyDimensionFilters,
  updateChildrenShowMoreLoading,
  updateChildrenData,
  updateChildrenDataRecursively,
  updateChildrenLoading,
  unwrapperPivots,
  unwrapperDataAdvanceSort,
  wrapperSelectionsAdvanceSort,
  unwrapperSelectionsAdvanceSort,
  makeOrderByText,
  downloadPivotComparisonTableData,
  downloadPivotTableData,
  isSharePivotValidBasedOnUserAccess,
};
